import styled from "./Review.module.scss";
import doctorImage from "../../../../../assets/images/doc-image.png";
import stethoscope from "../../../../../assets/icons/stethoscope-2.svg";
import pc from "../../../../../assets/icons/monitor-mobbile.svg";
import videoTime from "../../../../../assets/icons/video-time.svg";
import calendar from "../../../../../assets/icons/calendar.svg";
import clock from "../../../../../assets/icons/clock.svg";
import location from "../../../../../assets/icons/location.svg";
import { useState } from "react";
import { appointmentTypes, packets } from "../Service/Service";
import { scheduleAppointment } from "../../../../../models/AppointmentModels";
import { useOutletContext } from "react-router-dom";
import { DoctorProfileImagesRoot, MainURL } from "../../../../../API/URLs";
import { display } from "@mui/system";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const Review = () => {
  const [
    appointment,
    setAppointment,
    serviceTypeData,
    servicesQuery,
    doctorInfo,
    setDoctorInfo,
  ]: [
    scheduleAppointment | null,
    React.Dispatch<React.SetStateAction<scheduleAppointment>>,
    any,
    any,
    any,
    any
  ] = useOutletContext();

  const dateString = appointment?.chosenDate ?? "";
  const [year, month, day] = dateString.split("-").map(Number);

  const date = new Date(year, month - 1, day);
  const properties = [
    {
      header: "Packet",
      icon: stethoscope,
      value: doctorInfo?.packetName,
    },
    {
      header: "Type",
      icon: pc,
      value: doctorInfo?.typeName,
    },
    {
      header: "Price and Duration",
      icon: videoTime,
      value: `$${doctorInfo.packetPrice} - ${doctorInfo.packetDuration} Minutes`,
    },
    {
      header: "Date and Time",
      icon: calendar,
      secondIcon: clock,
      value: `${dateString.substring(0, dateString.indexOf("/"))} ${
        monthNames[date.getMonth()]
      } ${date.getFullYear()}
       `,
      secondValue: `${doctorInfo.appoinmentTime.substring(0, 5)}`,
    },
    {
      header: "Location",
      icon: location,
      value: "Azerbaijan Baku",
    },
  ];

  return (
    <>
      <div className={styled.review}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1>Review</h1>
          </div>
          <div className={styled.information}>
            <section className={styled.doctorInfo}>
              <div className={styled.image}>
                <img src={doctorInfo.doctorImage} />
              </div>
              <div className={styled.fullname}>
                <h1>
                  {doctorInfo?.doctorFirstName} {doctorInfo?.doctorLastName}
                </h1>
                <p>{doctorInfo?.categoryName}</p>
              </div>
            </section>
            <section className={styled.properties}>
              {properties.map((property) => {
                return (
                  <div className={styled.box}>
                    <div className={styled.header}>
                      <h1>{property.header}</h1>
                    </div>
                    {!property.secondIcon ? (
                      <div className={styled.packetName}>
                        <div
                          className={styled.icon}
                          style={{
                            backgroundImage: `url(${property.icon})`,
                            width: "20px",
                            height: "20px",
                          }}
                        ></div>
                        <div className={styled.value}>
                          <h1>{property.value} </h1>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styled.packetName}
                        style={{ flexDirection: "column" }}
                      >
                        <section
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div
                            className={styled.icon}
                            style={{
                              backgroundImage: `url(${property.icon})`,
                              width: "20px",
                              height: "20px",
                            }}
                          ></div>
                          <div className={styled.value}>
                            <h1>{property.value} </h1>
                          </div>
                        </section>

                        <section
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div
                            className={styled.icon}
                            style={{
                              backgroundImage: `url(${property.secondIcon})`,

                              width: "20px",
                              height: "20px",
                            }}
                          ></div>
                          <div className={styled.value}>
                            <h1>{property.secondValue} </h1>
                          </div>
                        </section>
                      </div>
                    )}
                  </div>
                );
              })}
            </section>
          </div>
          <div className={styled.feedback}>
            <section className={styled.radio}>
              <div className={styled.radioArea}>
                {/* <input
                  type="radio"
                  checked={appointment?.permission}
                  onClick={() =>
                    setAppointment((state) => ({
                      ...state,
                      permission: !state.permission,
                    }))
                  }
                /> */}
              </div>
              <div>
                <h1>
                  I give permission that my doctor can have access to my health
                  information
                </h1>
              </div>
            </section>
            <section className={styled.reason}>
              <div>
                <h1>Reason</h1>
              </div>
              <div className={styled.inputArea}>
                <textarea
                  placeholder="Please briefly explain your health problem in here.*"
                  onChange={(e) =>
                    setAppointment((state) => ({
                      ...state,
                      painDescription: e.target.value,
                    }))
                  }
                  value={appointment?.painDescription ?? ""}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
