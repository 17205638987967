import styled from "./SignIn.module.scss";
import { SignInNavigation } from "../../../components/Auth/SignIn/SignInNavigation/SignInNavigation";
import { Outlet } from "react-router-dom";

export const SignIn = () => {
  return (
    <div className={styled.sign__in}>
      <div className={styled.container}>
        <div className={styled.signin__wrapper}>
          <div className={styled.signin__wrapper__heading}>
            Sign In to{" "}
            <span className={styled.wrapper__heading__span}>Doczy</span>
          </div>
          <SignInNavigation />
          <Outlet />
          {/* <SignUpPatientForm /> */}
        </div>
      </div>
    </div>
  );
};
