import { FistColumnPatients } from "./FirstColumn/FirstColumn";
import styled from "./Patients.module.scss";
import { SecondColumnPatientsPage } from "./SecondColumn/SecondColumn";
export const Patients = () => {
  return (
    <section className={styled.patients}>
      <FistColumnPatients />
      <SecondColumnPatientsPage />
    </section>
  );
};
