import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Box, borderRadius, color, height, style, width } from "@mui/system";

import {
  Checkbox,
  Grid,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from "@mui/material";
import {
  Clear,
  RemoveRedEye,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useService } from "../../../../hooks";
import { useMutation } from "react-query";
import { signInModel, tokenResponse } from "../../../../models/AuthModels";
import { tokenContext } from "../../../../Contexts/TokenContext";
import { SignInDoctorSchema } from "../../../../validations/FormSchemas";

import { jwtDecode, JwtPayload } from "jwt-decode";
interface CustomJwtPayload extends JwtPayload {
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"?:
    | string
    | string[];
  // Add other custom claim properties here if needed
}

const AppTextField = (props: any) => {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";

  const handleClear = () => {
    helpers.setValue(""); // Clear the field value
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={meta.touched && !!meta.error}
      style={{
        width: "100%",
        borderRadius: "12px", // apply borderRadius directly
        marginBottom: "8px",
      }}
      // onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {field.value && ( // Only show clear icon when field has value
              <IconButton onClick={handleClear} edge="end">
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

const AppTextFieldPassword = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={meta.touched && !!meta.error}
      style={{
        width: "100%",
      }}
      // onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? "text" : "password"}
    />
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  // gap: 20px;
`;

const StyledFieldButtonWrapper = styled.div`
  width: 454px
  flex: 1 1 calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled.button`
  width: 100%;
  cursor: pointer;
  width: 117px;
  height: 40px;
  border: none;
  margin-bottom: 20px;
  border-radius: 100px;
  gap: 8px;
  background-color: #00696c;
  color: #fff;
  padding: 10px 32px 10px 32px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  transition: all 0.2s linear;
  &:hover {
    background-color: #cce8e8;
    color: black;
  }
`;

const SignInDoctorForm: React.FC = () => {
  const { setAccessToken, setRefreshToken, setExpiration } =
    useContext(tokenContext);
  const { authServices } = useService();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const spanStyle: React.CSSProperties = {
    textDecoration: "none",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#00696C",
    marginLeft: "8px",
    cursor: "pointer",
  };

  const [signInBody, setSignInBody] = useState<signInModel>({
    email: "",
    password: "",
    rememberMe: false,
  });
  const mutate = useMutation(
    (values: { email: string; password: string; rememberMe: boolean }) => {
      // Destructure values to get email, password, and rememberMe
      const { email, password, rememberMe } = values;

      // Call authServices.signIn with the required parameters
      return authServices.signIn(
        {
          email,
          password,
          rememberMe,
        },
        undefined
      );
    },
    {
      // onSuccess: ({ data: { tokenResponse } }) => {
      //   const response: tokenResponse = tokenResponse;
      //   localStorage.setItem("accessToken", response.accessToken);
      //   localStorage.setItem("refreshToken", response.refreshToken);
      //   localStorage.setItem("expiration", response.expiration);
      //   setAccessToken(response.accessToken);
      //   setRefreshToken(response.refreshToken);
      //   setExpiration(response.expiration);

      //   if (response.accessToken) {
      //     const decodedToken = jwtDecode<CustomJwtPayload>(response.accessToken);
      //     console.log("Decoded Token:", decodedToken); // Log decoded token to console
      //     const tokenRoleProperty = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

      //     const route = sessionStorage.getItem("route");
      //     switch (decodedToken[tokenRoleProperty]) {
      //       case "Patient":
      //         // Prevent sign-in if the user is a Doctor and attempted to sign in from the patient form
      //         alert("You cannot sign in as a Patient from the doctor sign-in form.");
      //         localStorage.removeItem("accessToken");
      //         localStorage.removeItem("refreshToken");
      //         localStorage.removeItem("expiration");
      //         setAccessToken(""); // Clear token from context
      //         setRefreshToken("");
      //         setExpiration("");
      //         // const route = sessionStorage.getItem("route");
      //         if (route) {
      //           sessionStorage.removeItem("route");
      //           navigate(route);
      //         }
      //         break;
      //       default:
      //         console.log("Unknown role, navigating to a default route");
      //         // const route = sessionStorage.getItem("route");
      //         if (route) {
      //           sessionStorage.clear();
      //           return navigate(route);
      //         } else {
      //           navigate("/DoctorPage/Dashboard");
      //         }
      //     }
      //   }
      // },
      onSuccess: ({ data: { tokenResponse } }) => {
        const response: tokenResponse = tokenResponse;
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("expiration", response.expiration);
        setAccessToken(response.accessToken);
        setRefreshToken(response.refreshToken);
        setExpiration(response.expiration);
        if (response.accessToken) {
          const decodedToken = jwtDecode<CustomJwtPayload>(
            response.accessToken
          );
          console.log("Decoded Token:", decodedToken); // Log decoded token to console
          const tokenRoleProperty =
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

          if (typeof decodedToken[tokenRoleProperty] === "string") {
            // If the role property is a string, split it by commas
            const roles = decodedToken[tokenRoleProperty]
              .split(",")
              .map((role) => role.trim());
            console.log("Roles:", roles); // Log roles to console

            // Check if any of the roles match the ones you want to render HeaderAuthorized for
            const route = sessionStorage.getItem("route");
            if (roles.includes("Admin") || roles.includes("Patient")) {
              alert(
                "You cannot sign in as a Patient from the doctor sign-in form."
              );
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("expiration");
              setAccessToken(""); // Clear token from context
              setRefreshToken("");
              setExpiration("");
              // const route = sessionStorage.getItem("route");

              // if (route) {
              //   sessionStorage.removeItem("route");
              //   navigate(route);
              // }
            } else if (roles.includes("Doctor")) {
              const route = sessionStorage.getItem("route");
              if (route) {
                sessionStorage.clear();
                return navigate(route);
              } else {
                navigate("/DoctorPage/Dashboard");
              }
            }
            // else {
            //   console.log("Rendering Default Header");
            //   setHeaderComponent(<Header />);
            // }
          } else if (Array.isArray(decodedToken[tokenRoleProperty])) {
            // If the role property is an array, check each role individually
            const roles = decodedToken[tokenRoleProperty].map((role) =>
              role.trim()
            );
            console.log("Roles:", roles); // Log roles to console

            const route = sessionStorage.getItem("route");
            if (roles.includes("Admin") || roles.includes("Patient")) {
              alert(
                "You cannot sign in as a Patient from the doctor sign-in form."
              );
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("expiration");
              setAccessToken(""); // Clear token from context
              setRefreshToken("");
              setExpiration("");
              // const route = sessionStorage.getItem("route");

              // if (route) {
              //   sessionStorage.removeItem("route");
              //   navigate(route);
              // }
            } else if (roles.includes("Doctor")) {
              const route = sessionStorage.getItem("route");
              if (route) {
                sessionStorage.clear();
                return navigate(route);
              } else {
                navigate("/DoctorPage/Dashboard");
              }
            }
            //  else {
            //   console.log("Rendering Default Header");
            //   setHeaderComponent(<Header />);
            // }
          }
          // else {
          //   // If the role property is not a string or an array, render the default header
          //   console.log("Role property is not a string or an array, rendering Default Header");
          //   setHeaderComponent(<Header />);
          // }
        }
        // else {
        //   // If no token found, render the default header
        //   console.log("No token found, rendering Default Header");
        //   setHeaderComponent(<Header />);
        // }
      },
      // onSuccess: ({ data: { tokenResponse } }) => {
      //   const response: tokenResponse = tokenResponse;
      //   localStorage.setItem("accessToken", response.accessToken);
      //   localStorage.setItem("refreshToken", response.refreshToken);
      //   localStorage.setItem("expiration", response.expiration);
      //   setAccessToken(response.accessToken);
      //   setRefreshToken(response.refreshToken);
      //   setExpiration(response.expiration);
      //   const route = sessionStorage.getItem("route");
      //   if (route) {
      //     sessionStorage.clear();
      //     return navigate(route);
      //   } else {
      //     navigate("/DoctorPage/Dashboard");
      //   }
      // },
    }
  );

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setSignInBody((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleSignIn = () => {
  //   mutate.mutate();
  // };

  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={signInBody}
        validationSchema={SignInDoctorSchema}
        // onSubmit={(values) => {
        //   console.log(values);
        // }}
        onSubmit={(values) => mutate.mutate(values)}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <StyledForm noValidate autoComplete="on">
            <Grid container>
              <Grid item xs={12}>
                <AppTextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  autoComplete="email"
                  value={values.email}
                  // setFieldTouched={"email"} // Set the touch status
                  // onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextFieldPassword
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  autoComplete="current-password"
                  value={values.password}
                  // onChange={handleInputChange}
                />
              </Grid>
              <Grid
                sx={{
                  // mb: { xs: 5, xl: 2 },
                  marginBottom: "16px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  control={
                    <Field as={Checkbox} type="checkbox" name="rememberMe" />
                  }
                  label="Remember me"
                />
              </Grid>
            </Grid>

            <StyledFieldButtonWrapper>
              {/* <StyledButton type="submit" onClick={handleSignIn}> */}
              {/* <StyledButton onClick={() => handleSignIn()} type="submit"> */}
              <StyledButton type="submit">Sign In</StyledButton>
            </StyledFieldButtonWrapper>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <a style={spanStyle} href="../../Auth/ForgotPassword">
                I forgot my password
              </a>
            </Box>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  gap: "8px",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "000",
                }}
              >
                No account?
                <a style={spanStyle} href="../../Auth/SignUp/Doctor">
                  Create one.
                </a>
              </Box>
            </Box>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default SignInDoctorForm;
