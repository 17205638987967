import { useContext, useEffect } from "react";
import "./App.css";

// index.js

import "./App.css";

import { MainRoutes } from "./routing/mainRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MainURLApi } from "./API/URLs";
import { tokenContext } from "./Contexts/TokenContext";
import { ToastContainer } from "react-toastify";
import { SnackbarProvider } from "notistack";

const queryClient = new QueryClient();

function App() {
  const { expiration, setAccessToken, setExpiration, setRefreshToken } =
    useContext(tokenContext);
  const navigate = useNavigate();

  useEffect(() => {
    const currentDate = new Date();
    const tokenExpiration = new Date(expiration ?? "");
    async function checkToken() {
      if (currentDate > tokenExpiration) {
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          const refreshTokenResponse = await axios.post(
            `${MainURLApi}/Users/refresh-token-login?refreshToken=${refreshToken}`
          );
          localStorage.clear();

          localStorage.setItem(
            "accessToken",
            refreshTokenResponse.data.accessToken
          );
          localStorage.setItem(
            "refreshToken",
            refreshTokenResponse.data.refreshToken
          );
          localStorage.setItem(
            "expiration",
            refreshTokenResponse.data.expiration
          );
          setAccessToken(refreshTokenResponse.data.accessToken);
          setRefreshToken(refreshTokenResponse.data.refreshToken);
          setExpiration(refreshTokenResponse.data.expiration);
        } catch (error) {
          setAccessToken("");
          setRefreshToken("");
          setExpiration("");
          localStorage.clear();
        }
      }
    }

    checkToken();
  });

  return (
    <div className="App">
      {/* <QueryClientProvider client={queryClient}>
        <ToastContainer
          position="top-right" // Position can be customized
          autoClose={5000} // Auto-close time
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          pauseOnFocusLoss
          newestOnTop={false}
        />
        <MainRoutes />
      </QueryClientProvider> */}
       <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={3} //
          dense
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={2500}
        >
          <MainRoutes />
        </SnackbarProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
