import DocPatientVirtaul from "../../../../assets/images/doc-patient-virtual.png";
import Passcode from "../../../../assets/images/passcode.png";
import Calendar from "../../../../assets/images/calendar.png";
import Upload from "../../../../assets/images/upload.png";
import Medical from "../../../../assets/images/medical.png";

function HowWeWork() {
  return (
    <section className="how__we__work">
      <div className="container">
        <div className="how__we__work__title">How We Work?</div>
        <div className="how__we__work__content">
          <div className="content__instructions">
            <div className="instructions__inner">
              <div className="instruction__item">
                <img src={Passcode} alt="" />
                <span className="instruction__item__text">
                  Register for free and create your profile, and start using
                  Doczy
                </span>
              </div>
              <div className="instruction__item">
                <img src={Calendar} alt="" />
                <span className="instruction__item__text">
                  Get appointment for Medical service you need; online or
                  in-person appointment
                </span>
              </div>
              <div className="instruction__item">
                <img src={Upload} alt="" />
                <span className="instruction__item__text">
                  Store your Medical history and reach them whenever you want
                  from everywhere
                </span>
              </div>
              <div className="instruction__item">
                <img src={Medical} alt="" />
                <span className="instruction__item__text">
                  Start managing your health with Doczy
                </span>
              </div>
            </div>
          </div>
          <div className="content__image__holder">
            <img src={DocPatientVirtaul} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWeWork;
