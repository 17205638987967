import { Autocomplete, Modal, TextField } from "@mui/material";
import styled from "./AddNewEdycationModal.module.scss";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { createEducation } from "../../../../../../models/EducationModels";
import { useMutation, useQuery } from "react-query";
import { useService } from "../../../../../../hooks";
import { tokenContext } from "../../../../../../Contexts/TokenContext";
import { jwtDecode } from "jwt-decode";
import { decodedToken } from "../../../../../../models/TokenContextModels";
import { tokenIdProperty } from "../../../../../Auth/AuthProvider/AuthProvider";
import {
  getAllFieldsOfStudyQueryKey,
  getUniversitiesQueryKey,
  getUniversityDegreeQueryKey,
} from "../../../../../../API/QueryKeys";
import { getAllUniversities } from "../../../../../../models/UniversityModels";
import { getAllUniversityDegrees } from "../../../../../../models/UniversityDegreeModels";
import { getAllFieldsOfStudy } from "../../../../../../models/FieldOfStudyModels";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: "8px",
  padding: "12px 12px 16px 24px",
  pt: 2,
  px: 4,
  pb: 3,
  background: "#E9EFEF",
  borderRadius: "28px",
};
export const AddNewEducationModal: React.FC<{
  closeModal: (name: string) => void;
  addNewEducation: boolean;
}> = ({ closeModal, addNewEducation }) => {
  const { accessToken } = useContext(tokenContext);
  const decodedToken: decodedToken | null = accessToken
    ? jwtDecode(accessToken)
    : null;
  const userId = decodedToken ? decodedToken[tokenIdProperty] : "";
  const {
    educationServices,
    universityServices,
    universityDegreeServices,
    fieldOfStudyServices,
  } = useService();
  const [universities, setUniversities] = useState<getAllUniversities[] | null>(
    null
  );
  const [fieldsOfStudy, setFieldsOfStudy] = useState<
    getAllFieldsOfStudy[] | null
  >(null);
  const [universityDegrees, setUniversityDegrees] = useState<
    getAllUniversityDegrees[] | null
  >(null);
  const [createBody, setCreateBody] = useState<createEducation>({
    doctorId: "",
    univercityId: "",
    univercityDegreeId: "",
    fieldOfStudyId: "",
    startDate: "",
    endDate: "",
  });
  const mutate = useMutation(() =>
    educationServices.createEducation(createBody, accessToken)
  );
  const universityDegreeQuery = useQuery(
    [getUniversityDegreeQueryKey],
    () => universityDegreeServices.getAllUniversityDegree(accessToken),
    { onSuccess: (response) => setUniversityDegrees(response.data) }
  );
  const fieldOfStudyQuery = useQuery(
    [getAllFieldsOfStudyQueryKey],
    () => fieldOfStudyServices.getAllFieldsOfStudy(accessToken),
    { onSuccess: (response) => setFieldsOfStudy(response.data) }
  );

  const universityQuery = useQuery(
    [getUniversitiesQueryKey],
    () => universityServices.getUniversities(accessToken),
    {
      onSuccess: (response) => {
        setUniversities(response.data);
      },
    }
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value, name } = event.target;
    setCreateBody((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handleCreateEducation = () => {
    mutate.mutate();
  };
  useEffect(() => {
    console.log(createBody, "create");
  }, [createBody]);
  if (
    universityQuery.isLoading ||
    !universities ||
    !universityDegrees ||
    universityDegreeQuery.isLoading ||
    fieldOfStudyQuery.isLoading ||
    !fieldsOfStudy
  ) {
    return (
      <Modal
        open={addNewEducation}
        onClose={() => {
          closeModal("addNewEducation");
          setCreateBody({
            doctorId: "",
            univercityId: "",
            univercityDegreeId: "",
            fieldOfStudyId: "",
            startDate: "",
            endDate: "",
          });
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 560 }}>
          <h1>Is Loading</h1>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={addNewEducation}
      onClose={() => {
        closeModal("addNewEducation");
        setCreateBody({
          doctorId: "",
          univercityId: "",
          univercityDegreeId: "",
          fieldOfStudyId: "",
          startDate: "",
          endDate: "",
        });
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 560 }}>
        <section className={styled.header}>
          <h1>Add new education</h1>
          <button
            onClick={() => {
              closeModal("addNewExperience");
              setCreateBody({
                doctorId: "",
                univercityId: "",
                univercityDegreeId: "",
                fieldOfStudyId: "",
                startDate: "",
                endDate: "",
              });
            }}
          >
            <CloseIcon />
          </button>
        </section>
        <section className={styled.inputs}>
          <form>
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={universities || []}
                getOptionLabel={(option: getAllUniversities) => option.name}
                onChange={(
                  event: SyntheticEvent,
                  newValue: getAllUniversities | null
                ) =>
                  setCreateBody((state) => ({
                    ...state,
                    univercityId: newValue ? newValue?.id : "",
                  }))
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField required {...params} label="University" />
                )}
              />
            </div>
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={universityDegrees || []}
                getOptionLabel={(option: getAllUniversityDegrees) =>
                  option.name
                }
                onChange={(
                  event: SyntheticEvent,
                  newValue: getAllUniversityDegrees | null
                ) =>
                  setCreateBody((state) => ({
                    ...state,
                    univercityDegreeId: newValue ? newValue?.id : "",
                  }))
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField required {...params} label="University Degree" />
                )}
              />
            </div>
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fieldsOfStudy || []}
                getOptionLabel={(option: getAllFieldsOfStudy) => option.name}
                onChange={(
                  event: SyntheticEvent,
                  newValue: getAllFieldsOfStudy | null
                ) =>
                  setCreateBody((state) => ({
                    ...state,
                    fieldOfStudyId: newValue ? newValue?.id : "",
                  }))
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField required {...params} label="Field of Study" />
                )}
              />
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    label="Start Date"
                    onChange={(value: string | null) =>
                      setCreateBody((state) => ({
                        ...state,
                        startDate: value
                          ? dayjs(value).format("YYYY-MM-DD")
                          : "",
                      }))
                    }
                  />
                  <DatePicker
                    label="End Date"
                    onChange={(value: string | null) =>
                      setCreateBody((state) => ({
                        ...state,
                        endDate: value ? dayjs(value).format("YYYY-MM-DD") : "",
                      }))
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </form>
        </section>
        <section className={styled.actions}>
          <div className={styled.container}>
            <button
              className={styled.close}
              onClick={() => closeModal("addNewEducation")}
            >
              Close
            </button>
            <button
              className={styled.finish}
              onClick={() => {
                handleCreateEducation();
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
