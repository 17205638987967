import { enqueueSnackbar } from "notistack";
import { MainURL, ServiceTypeIconsRoot } from "../../../../../API/URLs";
import styled from "./AppointmentsList.module.scss";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NoUpcomingAppointmentsGif from "../../../../../assets/icons/doczy-no-appointments-scheduled.svg";

import React, { useState } from 'react';

export const PatientPageAppointmentsList: React.FC<{
  appointmentQuery: any;
}> = ({ appointmentQuery }) => {
  function getDate() {
    const today = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const month = monthNames[today.getMonth()]; // Get the month name
    const year = today.getFullYear();
    const date = today.getDate();
  
    return `${date} ${month} ${year}`; // Format as "5 May 2024"
  }
  

  return (
    <section className={styled.appointmentsList}>
      <div className={styled.container}>
        <section className={styled.header}>
          <div className={styled.leftSide}>
            <h1>Appointments</h1>
          </div>
          <div className={styled.rightSide}>
            <button>
              Today <ExpandMore />
            </button>
          </div>
        </section>
        <section className={styled.appointments}>
        {appointmentQuery.data?.data.length === 0 ? (
            <div className={styled.appointment}>
              <div className={styled.noresults}>
                <img src={NoUpcomingAppointmentsGif} alt="No Results" />
                <p>Today ({getDate()}), you have no scheduled appointments.</p>
              </div>
            </div>
          ) : (
            appointmentQuery.data?.data.map((appointment: any) => (
              <div className={styled.appointment} key={appointment.id}>
                <section className={styled.information}>
                  <div className={styled.nameSurname}>
                    <h1>
                      {appointment.firstName} {appointment.lastName}
                    </h1>
                  </div>
                  <div className={styled.description}>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div className={styled.aboutService}>
                    <div>
                      <img
                        src={`${MainURL}/${ServiceTypeIconsRoot}/${appointment.serviceTypeIconUrl}`}
                      />
                    </div>
                    <p>{appointment.serviceTypeName}</p>
                    <p>17:45-18:15</p>
                    <p>30 minutes</p>
                  </div>
                </section>
                <section className={styled.action}>
                  {/* <button> */}
                  <button
                    onClick={() => {
                      // Navigate to the appointment's meeting link
                      if (appointment.meetLink) {
                        window.location.href = appointment.meetLink;
                      } else {
                        // console.error("Meet link is not defined");
                        enqueueSnackbar("You can join meeting only 15 minutes left.", { variant: 'info' }); // Display error snackbar
                      }
                    }}
                  >
                    Go to appointment screen
                  </button>
                </section>
              </div>
            ))
          )}
        </section>
      </div>
    </section>
  );
};