import { useContext, useEffect, useState } from "react";
import DoctorsList from "../../components/User/DoctorsPage/DoctorsList/DoctorsList";
import { ScheduleAnAppointmentLayout } from "../../layouts/Global/ScheduleAnAppointment/ScheduleAnAppointmentLayout";
import styled from "./DoctorsPage.module.scss";
import loadingIcon from "../../assets/images/loadingAnimation.gif";

import { useQuery } from "react-query";
import {
  GetServiceTypeQueryKey,
  GetServicesQueryKey,
} from "../../API/QueryKeys";
import { useService } from "../../hooks";
import { ScheduleAnAppointmentContext } from "../../Contexts/ScheduleAppointment-Context";
import { useLocation, useNavigate } from "react-router-dom";
export const DoctorsPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { appointment, setAppointment, doctorInfo, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const { servicesDoctorServices, serviceTypeServices } = useService();
  const bodyElement = document.getElementsByTagName("body")[0];
  const serviceTypeQuery = useQuery(
    [GetServiceTypeQueryKey, appointment?.doctorId],
    () => serviceTypeServices.getServicesType()
  );
  const servicesQuery = useQuery(
    [GetServicesQueryKey, appointment?.doctorId],
    () =>
      servicesDoctorServices.getDoctorServiceByType(
        appointment?.doctorId,
        doctorInfo?.appointmentTypeId ?? ""
      ),
    { enabled: false }
  );

  useEffect(() => {
    if (!pathname.includes("/Doctors")) {
      navigate("");
    }
  }, []);
  useEffect(() => {
    if (doctorInfo.appointmentTypeId && appointment.doctorId) {
      servicesQuery.refetch();
    }
  }, [doctorInfo.appointmentTypeId, appointment.doctorId]);
  useEffect(() => {
    if (servicesQuery.isSuccess && servicesQuery.data?.data.length > 0) {
      setAppointment((state) => ({
        ...state,
        serviceId: servicesQuery.data?.data[0].id,
      }));
      setDoctorInfo((state) => ({
        ...state,
        packetName: servicesQuery.data?.data[0].name,
        packetPrice: servicesQuery.data?.data[0].price,
        packetDuration: servicesQuery.data?.data[0].duration,
      }));
    } else if (servicesQuery.data?.data.length === 0) {
      setAppointment((state) => ({
        ...state,
        serviceId: "",
      }));
    }
  }, [servicesQuery.isSuccess]);

  const [scheduleAppointmentIsOpen, setScheduleAppointmentIsOpen] =
    useState<boolean>(false);
  useEffect(() => {
    if (scheduleAppointmentIsOpen) {
      bodyElement.style.overflowY = "hidden";
    } else {
      bodyElement.style.overflowY = "auto";
    }
  }, [scheduleAppointmentIsOpen]);

  return (
    <div className={styled.doctors}>
      <DoctorsList
        loadingIcon={loadingIcon}
        setScheduleAppointmentIsOpen={setScheduleAppointmentIsOpen}
        scheduleAppointmentIsOpen={scheduleAppointmentIsOpen}
        serviceTypeData={serviceTypeQuery}
        servicesQuery={servicesQuery}
      />
      <ScheduleAnAppointmentLayout
        scheduleAppointmentIsOpen={scheduleAppointmentIsOpen}
        setScheduleAppointmentIsOpen={setScheduleAppointmentIsOpen}
        serviceTypeData={serviceTypeQuery}
        servicesQuery={servicesQuery}
      />
    </div>
  );
};
