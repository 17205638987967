import React, { useContext, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import styled from "./DateDoctorDetails.module.scss";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ScheduleAnAppointmentContext } from "../../../../../Contexts/ScheduleAppointment-Context";

const times = ["10:00", "8:00", "9:00", "11:00", "12:00", "10:30", "10:10"];
export const DateDoctorDetails = () => {
  const { appointment, setAppointment } = useContext(
    ScheduleAnAppointmentContext
  );
  useEffect(() => {
    console.log(appointment, "s");
  }, [appointment]);
  return (
    <>
      <section className={styled.calendar}>
        <div className={styled.header}>
          <h1>Select a date</h1>
        </div>
        <div className={styled.main}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              sx={{ background: "#E3E9E9", borderRadius: "16px" }}
              components={["DateCalendar", "DateCalendar"]}
            >
              <DemoItem>
                <DateCalendar
                  onChange={(newValue) =>
                    setAppointment((state) => ({
                      ...state,
                      date: dayjs(newValue).format("DD/MM/YYYY"),
                    }))
                  }
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </section>
      <section className={styled.time}>
        <div className={styled.header}>
          <h1>Select time</h1>
        </div>
        <div className={styled.variants}>
          <ul>
            {times.map((time, index) => {
              return (
                <li
                  className={styled.variant}
                  style={{
                    background:
                      appointment.chosenHourId === time ? "#CCE8E8" : "",
                  }}
                  key={index}
                  onClick={() =>
                    setAppointment((state) => ({
                      ...state,
                      time: time,
                    }))
                  }
                >
                  {appointment.chosenHourId === time && (
                    <div className={styled.selectedIcon}></div>
                  )}
                  <h1>{time}</h1>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </>
  );
};
