import styled from "./Feedback.module.scss";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import star from "../../../../../assets/icons/Vector.svg";
import React from "react";
import { getDoctorReportsDTO } from "../../../../../models/DoctorModels";

export const FeedbackReportsPage: React.FC<{
  reporstDTO: getDoctorReportsDTO | null;
}> = ({ reporstDTO }) => {
  return (
    <section className={styled.feedback}>
      <div className={styled.container}>
        <div className={styled.header}>
          <h1>Feedback</h1>
          <button>
            <p>This month</p>
            <KeyboardArrowDown />
          </button>
        </div>
        <div className={styled.first}>
          <section className={styled.reviews}>
            <div className={styled.top}>
              <h1>Reviews</h1>
            </div>
            <div className={styled.bottom}>
              <p>{reporstDTO?.reviews}</p>
              <img src={star} />
            </div>
          </section>
          <section className={styled.favourites}>
            <div className={styled.top}>
              <h1>Favourites</h1>
            </div>
            <div className={styled.bottom}>
              <p>{reporstDTO?.favourite}</p>
              <img src={star} />
            </div>
          </section>
        </div>
        <div className={styled.second}>
          <section className={styled.questions}>
            <div className={styled.top}>
              <h1>Questions</h1>
            </div>
            <div className={styled.bottom}>
              <p>{reporstDTO?.questions}</p>
              <button>Answer</button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};
