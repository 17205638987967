import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "./ScheduleAnAppointmentDoctorDetails.module.scss";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ScheduleAnAppointmentContext } from "../../../Contexts/ScheduleAppointment-Context";
import { ServiceDoctorDetails } from "../../../components/User/DoctorDetailsPage/ScheduleAnAppointmentDoctorDetails/Service/ServiceDoctorDetails";
import { DateDoctorDetails } from "../../../components/User/DoctorDetailsPage/ScheduleAnAppointmentDoctorDetails/Date/DateDoctorDetails";
import { ReviewDoctorDetails } from "../../../components/User/DoctorDetailsPage/ScheduleAnAppointmentDoctorDetails/Review/ReviewDoctorDetails";
import { PaymentDoctorDetails } from "../../../components/User/DoctorDetailsPage/ScheduleAnAppointmentDoctorDetails/Payment/PaymentDoctorDetails";
import { ConfirmDoctorDetails } from "../../../components/User/DoctorDetailsPage/ScheduleAnAppointmentDoctorDetails/Confirm/ConfirmDoctorDetails";
import { Navigator } from "../../../components/User/DoctorDetailsPage/ScheduleAnAppointmentDoctorDetails/Navigator/NavigatorDoctorDetails";

const outlets = [
  {
    name: "Service",
    pageNumber: 1,
    element: <ServiceDoctorDetails />,
  },
  {
    name: "Date",
    pageNumber: 2,
    element: <DateDoctorDetails />,
  },
  {
    name: "Review",
    pageNumber: 3,
    element: <ReviewDoctorDetails />,
  },
  {
    name: "Payment",
    pageNumber: 4,
    element: <PaymentDoctorDetails />,
  },
  {
    name: "Confirm",
    pageNumber: 5,
    element: <ConfirmDoctorDetails />,
  },
];
export const ScheduleAnAppointmentLayoutDoctotDetails: React.FC<{
  setScheduleAppointmentIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setScheduleAppointmentIsOpen }) => {
  const scheduleAnAppointment = document.getElementById(
    "scheduleAppointmentDoctorDetails"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { appointment, setAppointment, doctorInfo, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const { pathname } = useLocation();
  const nextButtonHandler = () => {
    switch (currentPage) {
      case 1:
        if (doctorInfo.appointmentTypeId && appointment.serviceId) {
          setCurrentPage((state) => state + 1);
        }
        break;
      case 2:
        if (appointment.chosenDate && appointment.chosenHourId) {
          setCurrentPage((state) => state + 1);
        }
        break;
      case 3:
        setCurrentPage((state) => state + 1);
        break;
      case 4:
      // if (
      //   appointment.cardNumber &&
      //   appointment.cardNameSurname &&
      //   appointment.CVV &&
      //   appointment.MMYY
      // ) {
      //   setCurrentPage((state) => state + 1);
      // }
      // break;
    }
  };
  const backButtonHandler = () => {
    switch (currentPage) {
      case 2:
        setCurrentPage((state) => state - 1);
        break;
      case 3:
        setCurrentPage((state) => state - 1);
        break;
      case 4:
        if (doctorInfo.appointmentTypeId && appointment.serviceId) {
          setCurrentPage((state) => state - 1);
        }
        break;
      case 5:
        if (doctorInfo.appointmentTypeId && appointment.serviceId) {
          setCurrentPage((state) => state - 1);
        }
        break;
    }
  };
  const generateSection = () => {
    var outlet = outlets.find((outlet) => outlet.pageNumber === currentPage);
    return outlet ? outlet.element : <></>;
  };
  return (
    <div
      className={styled.scheduleAnAppointmentDoctorDetails}
      id="scheduleAppointmentDoctorDetails"
    >
      <div className={styled.container}>
        <section className={styled.header}>
          <div className={styled.content}>
            <div className={styled.contentLeft}>
              <h1>Schedule an appointment</h1>
            </div>
            <div className={styled.contentRight}>
              <button
                onClick={() => {
                  setScheduleAppointmentIsOpen(false);

                  if (scheduleAnAppointment) {
                    scheduleAnAppointment.style.right = "-400px";
                  }
                  setDoctorInfo(() => ({
                    doctorFirstName: "",
                    doctorLastName: "",
                    doctorCategory: "",
                    doctorImage: "",
                    appointmentTypeId: "",
                    categoryName: "",
                    packetName: "",
                    typeName: "",
                    packetPrice: "",
                    packetDuration: "",
                    appoinmentTime: "",
                    doctorRating: 0,
                  }));
                  setAppointment({
                    doctorId: "",

                    serviceId: "",
                    chosenDate: "",
                    chosenHourId: "",
                    // permission: false,
                    painDescription: "",
                    // cardNumber: "",
                    // cardNameSurname: "",
                    // MMYY: "",
                    // CVV: null,
                  });
                }}
              ></button>
            </div>
          </div>
        </section>
        <Navigator currentPage={currentPage} appointment={appointment} />

        <section className={styled.line}></section>
        {generateSection()}
        <section className={styled.actions}>
          <div className={styled.content}>
            <div className={styled.left}>
              {currentPage !== 1 ? (
                <button
                  onClick={backButtonHandler}
                  disabled={pathname === "Confirm" ? true : false}
                >
                  Back
                </button>
              ) : (
                ""
              )}
            </div>
            <div className={styled.right}>
              {currentPage === outlets.length ? (
                <button style={{ background: "#00696C" }}>Close</button>
              ) : currentPage === outlets.length - 1 ? (
                <button
                  style={{ background: "#00696C" }}
                  onClick={nextButtonHandler}
                >
                  Confirm and pay
                </button>
              ) : (
                <button onClick={nextButtonHandler}>Next</button>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
