import styled from "./VerifyIdentity.module.scss";
import styledC from "styled-components";

import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import styledComponent from "styled-components";
import { Box, height, width } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useService } from "../../../hooks";
import { VerifyIdentitySchema } from "../../../validations/FormSchemas";
import { verifyIdentityResponse } from "../../../models/AuthModels";
import { useMutation } from "react-query";
import { SignUpDoctorContext } from "../../../Contexts/SignUpDoctorContext";

// const AppTextField = (props: any) => {
//   const [field, meta, helpers] = useField(props);
//   const errorText = meta.error && meta.touched ? meta.error : " ";

//   const handleClear = () => {
//     helpers.setValue(""); // Clear the field value
//   };

//   return (
//     <TextField
//       {...props}
//       {...field}
//       helperText={errorText}
//       error={meta.touched && !!meta.error}
//       style={{
//         width: "100%",
//         borderRadius: "12px",
//       }}
//       // onChange={handleInputChange}
//       InputProps={{
//         endAdornment: (
//           <InputAdornment position="end">
//             {field.value && ( // Only show clear icon when field has value
//               <IconButton onClick={handleClear} edge="end">
//                 <Clear />
//               </IconButton>
//             )}
//           </InputAdornment>
//         ),
//       }}
//     />
//   );
// };

const StyledFieldButtonsWrapper = styledC.div`
  // width: 454px
  // flex: 1 1 calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styledC.button`
  // width: 100%;
  cursor: pointer;
  width: 117px;
  height: 40px;
  border: none;
  border-radius: 100px;
  gap: 8px;
  background-color: #00696C;
  color: #fff;
  padding: 10px 32px 10px 32px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  transition: all .2s linear;
  &:hover {
    background-color: #cce8e8;
    color: black;
  }
`;

const StyledFileInputWrapper = styledC.div`
  width: 100%;
  position: relative;
  border: 1px dashed #6F7979;
  border-radius: 12px;
  background-color: #EFF5F4;
  margin-bottom: 24px;
`;

const StyledFileInput = styledC.input`
  width: 100%;
  height: 56px;
  padding: 10px;
//   border: 1px solid #79747e;
//   border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: #6f7979;
  position: relative;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
`;

const StyledFileInputLabel = styledC.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  z-index: 0;
  cursor: pointer;
`;

type DoctorCategory = {
  id: string;
  name: string;
};

export const VerifyIdentity = () => {
  const { signUpDoctorInfo, setSignUpDoctorInfo } =
    useContext(SignUpDoctorContext);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);

  const { doctorServices } = useService();

  const [doctorCategories, setDoctorCategories] = useState<DoctorCategory[]>(
    []
  );
  const { doctorCategoryServices } = useService();

  let form = new FormData();
  const mutate = useMutation(
    () => doctorServices.doctorRegister(form, undefined),
    {
      onSuccess: (response) => {
        navigate("/Auth/VerifyEmail");
      },
      onError: (error) => console.log(error),
    }
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await doctorCategoryServices.getAllDoctorCategories();
        setDoctorCategories(response.data);
      } catch (error) {
        console.error("Error fetching doctor categories:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);

  const spanStyle: React.CSSProperties = {
    textDecoration: "none",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#00696C",
    marginLeft: "8px",
    cursor: "pointer",
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSignUpDoctorInfo((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handleClick = () => {
    for (const [key, value] of Object.entries(signUpDoctorInfo)) {
      form.append(key, value);
    }
    mutate.mutate();
  };

  return (
    <div className={styled.verify__identity}>
      <div className={styled.container}>
        <div className={styled.verify__identity__wrapper}>
          <div className={styled.verify__identity__wrapper__heading}>
            Verify your identity
          </div>

          <div>
            <Grid
              container
              spacing={0}
              sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <Grid item xs={12}>
                <TextField
                  style={{ width: "100%" }}
                  label="Fincode"
                  name="userName"
                  variant="outlined"
                  autoComplete="userName"
                  value={signUpDoctorInfo.userName}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Autocomplete
                    PaperComponent={({ children, ...other }) => (
                      <Paper {...other}>{children}</Paper>
                    )}
                    id="doctorCategoryId"
                    style={{ marginBottom: "24px", width: "100%" }}
                    options={doctorCategories}
                    getOptionLabel={(doctorCategory) =>
                      doctorCategory ? doctorCategory.name : ""
                    }
                    onChange={(event, newValue: DoctorCategory | null) =>
                      setSignUpDoctorInfo((state) => ({
                        ...state,
                        doctorCategoryId: newValue ? newValue?.id : "",
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Your specialization"
                      />
                    )}
                  />
                  <StyledFileInputWrapper>
                    <StyledFileInput
                      type="file"
                      id="idCardImageUrl"
                      name="idCardImageUrl"
                      accept="image/*"
                      onChange={(event) => {
                        const file = event.currentTarget.files?.[0];
                        if (file) {
                          setSignUpDoctorInfo((state) => ({
                            ...state,
                            idCardImageUrl: file,
                          }));
                        }
                      }}
                    />
                    <StyledFileInputLabel htmlFor="idCardImageUrl">
                      {signUpDoctorInfo.idCardImageUrl
                        ? signUpDoctorInfo.idCardImageUrl.name
                        : "Upload photo of your ID*"}
                    </StyledFileInputLabel>
                  </StyledFileInputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <StyledFileInputWrapper>
                    <StyledFileInput
                      type="file"
                      id="diplomaImageUrl"
                      name="diplomaImageUrl"
                      accept="image/*"
                      onChange={(event) => {
                        const file = event.currentTarget.files?.[0];
                        if (file) {
                          setSignUpDoctorInfo((state) => ({
                            ...state,
                            diplomaImageUrl: file,
                          }));
                        }
                      }}
                    />
                    <StyledFileInputLabel htmlFor="diplomaImageUrl">
                      {signUpDoctorInfo.diplomaImageUrl
                        ? signUpDoctorInfo.diplomaImageUrl.name
                        : "Upload photo of your diploma*"}
                    </StyledFileInputLabel>
                  </StyledFileInputWrapper>
                </Grid>
              </Grid>
            </Grid>

            <StyledFieldButtonsWrapper>
              <StyledButton onClick={handleClick}>Sign Up</StyledButton>
            </StyledFieldButtonsWrapper>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  gap: "8px",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "000",
                }}
              >
                Already have an account?
                <a style={spanStyle} href="../../../Auth/SignIn/Doctor">
                  Sign In.
                </a>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
