import React, { Children, createContext, useState } from "react";
import {
  doctorInformation,
  scheduleAnAppointmentContextModel,
  scheduleAppointment,
} from "../models/AppointmentModels";

interface ContainerProps {
  children: React.ReactNode;
}
// const appointmentInitialValue2 = {
//   appointment: {
//     doctorId: "",
//     chosenDate: new Date(),
//     chosenHourId: "",
//     serviceId: "",
//     PainDescription: null,
//   },
//   setAppointment: () => "",
// };
const appointmentInitialValue = {
  doctorInfo: {
    doctorFirstName: "",
    doctorLastName: "",
    doctorCategory: "",
    doctorImage: "",
    appointmentTypeId: "",
    categoryName: "",
    packetName: "",
    typeName: "",
    packetPrice: "",
    packetDuration: "",
    appoinmentTime: "",
    doctorRating: 0,
  },
  setDoctorInfo: () => "",
  appointment: {
    doctorId: "",
    serviceId: "",
    chosenDate: "",
    chosenHourId: "",
    // permission: false,
    painDescription: "",
    // cardNumber: "",
    // cardNameSurname: "",
    // MMYY: "",
    // CVV: null,
  },
  setAppointment: () => "",
};
export const ScheduleAnAppointmentContext =
  createContext<scheduleAnAppointmentContextModel>(appointmentInitialValue);

export const ScheduleAnAppointmentContextProvider = (props: ContainerProps) => {
  const [doctorInfo, setDoctorInfo] = useState<doctorInformation>({
    doctorFirstName: "",
    doctorLastName: "",
    doctorCategory: "",
    doctorImage: "",
    appointmentTypeId: "",
    categoryName: "",
    packetName: "",
    typeName: "",
    packetPrice: "",
    packetDuration: "",
    appoinmentTime: "",
    doctorRating: 0,
  });
  const [appointment, setAppointment] = useState<scheduleAppointment>({
    doctorId: "",
    serviceId: "",
    chosenDate: "",
    chosenHourId: "",
    // permission: false,
    painDescription: "",
    // cardNumber: "",
    // cardNameSurname: "",
    // MMYY: "",
    // CVV: null,
  });
  return (
    <ScheduleAnAppointmentContext.Provider
      value={{ appointment, setAppointment, doctorInfo, setDoctorInfo }}
    >
      {props.children}
    </ScheduleAnAppointmentContext.Provider>
  );
};
