import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

export const tokenEmailProperty =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
export const tokenUserNameProperty =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
export const tokenRoleProperty =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const tokenIdProperty =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";