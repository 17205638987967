import Welcome from "../../components/User/LandingPage/Welcome/Welcome";
import ServiceCategories from "../../components/User/LandingPage/ServiceCategories/ServiceCategories";
import ProblemHandling from "../../components/User/LandingPage/ProblemHandling/ProblemHandling";
import HowWeWork from "../../components/User/LandingPage/HowWeWork/HowWeWork";
import DoczyMobile from "../../components/User/LandingPage/DoczyMobile/DoczyMobile";

export const HomePage = () => {
  return (
    <div className="homePage">
      <Welcome />
      <ServiceCategories />
      <ProblemHandling />
      <HowWeWork />
      <DoczyMobile />
    </div>
  );
};
