import React, { createContext, useState } from "react";
import { loadingContextModel } from "../models/LoadingModels";
const initialValue = {
  loading: false,
  setLoading: () => Boolean,
};

export const doctorDetailsLoadingContext =
  createContext<loadingContextModel>(initialValue);

export const DoctorDetailsContextProvider = () => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <doctorDetailsLoadingContext.Provider
      value={{ loading, setLoading }}
    ></doctorDetailsLoadingContext.Provider>
  );
};
