import styled from "./Confirm.module.scss";
import stethoscope from "../../../../../assets/icons/stethoscope-2.svg";
import pc from "../../../../../assets/icons/monitor-mobbile.svg";
import videoTime from "../../../../../assets/icons/video-time.svg";
import calendar from "../../../../../assets/icons/calendar-tick.svg";
import clock from "../../../../../assets/icons/clock.svg";
import location from "../../../../../assets/icons/location.svg";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  doctorInformation,
  scheduleAppointment,
} from "../../../../../models/AppointmentModels";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const Confirm = () => {
  var information: doctorInformation | null = null;
  var appointmentInfo: scheduleAppointment | null = null;
  var doctorInfoString = sessionStorage.getItem("doctorInfo");
  var appointmentInfoString = sessionStorage.getItem("appointmentInfo");
  const navigate = useNavigate();
  if (doctorInfoString !== null && appointmentInfoString !== null) {
    information = JSON.parse(doctorInfoString);
    appointmentInfo = JSON.parse(appointmentInfoString);
  }
  const dateString = appointmentInfo?.chosenDate ?? "";
  const [year, month, day] = dateString.split("-").map(Number);

  const date = new Date(year, month - 1, day);
  const details = [
    {
      header: "Packet",
      icon: stethoscope,
      value: information?.packetName,
    },
    {
      header: "Type",
      icon: pc,
      value: information?.typeName,
    },
    {
      header: "Price and Duration",
      icon: calendar,
      value: `${information?.packetPrice} - ${information?.packetDuration} Minutes`,
    },
    {
      header: "Date and Time",
      icon: videoTime,
      secondIcon: clock,
      value: `${information?.appoinmentTime.substring(0, 5)} - ${day} ${
        monthNames[date.getMonth()]
      } ${year}
       `,
    },
    {
      header: "Location",
      icon: location,
      value: "Azerbaijan Bakue",
    },
  ];

  const [
    appointment,
    setAppointment,
    serviceTypeData,
    servicesQuery,
    doctorInfo,
    setDoctorInfo,
    setScheduleAppointmentIsOpen,
    scheduleAppointmentIsOpen,
  ]: [
    scheduleAppointment | null,
    React.Dispatch<React.SetStateAction<scheduleAppointment>>,
    any,
    any,
    any,
    any,
    React.Dispatch<React.SetStateAction<boolean>>,
    boolean
  ] = useOutletContext();

  useEffect(() => {
    let scheduleAppointment = document.getElementById("scheduleAppointment");
    let scheduleAnAppointmentActions = document.getElementById(
      "ScheduleAppointmentAction"
    );
    async function fetchData() {
      const response = await fetch("http://localhost:3000/Doctors/Confirm");
      if (scheduleAnAppointmentActions && scheduleAppointment && response.ok) {
        setScheduleAppointmentIsOpen(true);
        scheduleAppointment.style.right = "0px";
        scheduleAnAppointmentActions.style.right = "0px";
      }
    }
    fetchData();
  }, []);

  if (
    !sessionStorage.getItem("doctorInfo") &&
    !sessionStorage.getItem("appointmentInfo")
  ) {
    const scheduleAppointment = document.getElementById("scheduleAppointment");
    let scheduleAnAppointmentActions = document.getElementById(
      "ScheduleAppointmentAction"
    );
    navigate("/Doctors");
    setScheduleAppointmentIsOpen(false);
    if (scheduleAppointment && scheduleAnAppointmentActions) {
      scheduleAppointment.style.right = "-400px";
      scheduleAnAppointmentActions.style.right = "-400px";
    }
  }

  return (
    <>
      <section className={styled.status}>
        <div className={styled.header}>
          <h1>Payment Status</h1>
        </div>
        <div className={styled.paymentStatus}>
          <div className={styled.icon}></div>
          <div className={styled.info}>
            <h1>Payment completed</h1>
          </div>
        </div>
      </section>
      <section className={styled.appointmentDetails}>
        <div className={styled.header}>
          <h1>Appointment details</h1>
        </div>
        <div className={styled.details}>
          {details.map((detail) => {
            return (
              <div className={styled.box}>
                <div className={styled.header}>
                  <h1>{detail.header}</h1>
                </div>
                <div className={styled.packetName}>
                  <div
                    className={styled.icon}
                    style={{
                      backgroundImage: `url(${detail.icon})`,

                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  {detail.secondIcon && (
                    <div
                      className={styled.icon}
                      style={{
                        backgroundImage: `url(${detail.secondIcon})`,

                        width: "20px",
                        height: "20px",
                      }}
                    ></div>
                  )}
                  <div className={styled.value}>
                    <h1>{detail.value}</h1>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
