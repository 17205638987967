import { Outlet, Route, useLocation, useNavigate } from "react-router-dom";
import styled from "./Settings.module.scss";
import personalCard from "../../../../assets/icons/personalcard.svg";
import profile from "../../../../assets/icons/profile-circle.svg";
import link from "../../../../assets/icons/link.svg";
import lock from "../../../../assets/icons/lock.svg";
import { useEffect } from "react";

const menuButtons = [
  {
    id: 1,
    description: "Basic Information",
    icon: personalCard,
    route: "BasicInformation",
  },
  { id: 2, description: "Profile", icon: profile, route: "Profile" },
  { id: 3, description: "Social Links", icon: link, route: "SocialLinks" },
  { id: 4, description: "Security", icon: lock, route: "Security" },
];

export const DoctorPageSettings = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.substring(pathname.lastIndexOf("/")).includes("Settings")) {
      navigate("BasicInformation");
    }
  }, []);

  return (
    <section className={styled.settings}>
      <section className={styled.navigation}>
        <div className={styled.container}>
          <section className={styled.heading}>
            <h1>Settings</h1>
          </section>
          <section className={styled.menu}>
            {menuButtons.map((button: any) => {
              return (
                <div
                  className={styled.menuButton}
                  key={button.id}
                  onClick={() => navigate(button.route)}
                  style={{
                    background: `${
                      pathname.includes(button.route) ? "#cce8e8" : ""
                    }`,
                  }}
                >
                  <div
                    className={styled.icon}
                    style={{
                      backgroundImage: `url(${button.icon})`,
                      width: "24px",
                      height: "24px",
                      backgroundSize: "24px",
                    }}
                  ></div>
                  <h1>{button.description}</h1>
                </div>
              );
            })}
          </section>
        </div>
      </section>
      <Outlet />
    </section>
  );
};
