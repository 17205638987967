import styled from "./FirstColumn.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
export const FistColumnPatients = () => {
  return (
    <div className={styled.firstColumn}>
      <section className={styled.header}>
        <h1>Patients</h1>
        {/* <button>Add new +</button> */}
      </section>
      <section className={styled.search}>
        <SearchIcon />
        <input placeholder="Search"></input>
      </section>
      <section className={styled.list}>
        <ul>
          <li>
            <div className={styled.firstLine}>
              <section className={styled.left}>
                <h1>Name Surname</h1>
              </section>
              <section className={styled.right}>
                <MoreVertIcon />
              </section>
            </div>
            <div className={styled.secondLine}>
              <p>Age 51,Female</p>
            </div>
          </li>
          <li>
            <div className={styled.firstLine}>
              <section className={styled.left}>
                <h1>Name Surname</h1>
              </section>
              <section className={styled.right}>
                <MoreVertIcon />
              </section>
            </div>
            <div className={styled.secondLine}>
              <p>Age 51,Female</p>
            </div>
          </li>
          <li>
            <div className={styled.firstLine}>
              <section className={styled.left}>
                <h1>Name Surname</h1>
              </section>
              <section className={styled.right}>
                <MoreVertIcon />
              </section>
            </div>
            <div className={styled.secondLine}>
              <p>Age 51,Female</p>
            </div>
          </li>
          <li>
            <div className={styled.firstLine}>
              <section className={styled.left}>
                <h1>Name Surname</h1>
              </section>
              <section className={styled.right}>
                <MoreVertIcon />
              </section>
            </div>
            <div className={styled.secondLine}>
              <p>Age 51,Female</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};
