import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import styled from "./ChangeProfileImage.module.scss";
import { Modal, TextField } from "@mui/material";
import styledC from "styled-components";
import { Dispatch, useContext, useState } from "react";
import { changeUserProfileImage } from "../../../../../models/UserModels";
import { object } from "yup";
import { useService } from "../../../../../hooks";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { tokenContext } from "../../../../../Contexts/TokenContext";
import { GetDoctorDetailQueryKey } from "../../../../../API/QueryKeys";
import { getDoctorProfileDTO } from "../../../../../models/DoctorModels";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: "8px",
  padding: "12px 12px 16px 24px",
  pt: 2,
  px: 4,
  pb: 3,
  background: "#E9EFEF",
  borderRadius: "28px",
};
const StyledFileInputWrapper = styledC.div`
  width: 100%;
  position: relative;
  border: 1px dashed #6F7979;
  border-radius: 12px;
  background-color: #EFF5F4;
  margin-bottom: 24px;
`;
const StyledFileInput = styledC.input`
  width: 100%;
  height: 56px;
  padding: 10px;
//   border: 1px solid #79747e;
//   border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: #6f7979;
  position: relative;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
`;

const StyledFileInputLabel = styledC.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  z-index: 0;
  cursor: pointer;
`;

export const ChangeProfileImageModal: React.FC<{
  changeProfileImageisOpen: boolean;
  closeModal: () => void;
  setDoctorData: React.Dispatch<
    React.SetStateAction<getDoctorProfileDTO | null>
  >;
}> = ({ changeProfileImageisOpen, closeModal, setDoctorData }) => {
  const { userServices } = useService();
  const { accessToken } = useContext(tokenContext);
  const queryClient = useQueryClient();
  const [changeProfileImage, setChangeProfileImage] =
    useState<changeUserProfileImage>({ fileUrl: null });
  const mutate = useMutation(
    () => userServices.changeProfileImage(form, accessToken),
    {
      onSuccess: () => {
        queryClient.removeQueries([GetDoctorDetailQueryKey]);
        queryClient.refetchQueries([GetDoctorDetailQueryKey]);
        for (const [key, value] of Object.entries(changeProfileImage)) {
          form.delete(key);
        }
        closeModal();
      },
    }
  );

  const form = new FormData();
  const handleChangeProfileImage = () => {
    for (const [key, value] of Object.entries(changeProfileImage)) {
      form.append(key, value);
    }
    mutate.mutate();
  };

  return (
    <Modal
      open={changeProfileImageisOpen}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 560 }}>
        <section className={styled.header}>
          <h1>Profile Image</h1>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            <CloseIcon />
          </button>
        </section>
        <section className={styled.inputs}>
          <form>
            <StyledFileInputWrapper>
              <StyledFileInput
                type="file"
                id="idCardImageUrl"
                name="idCardImageUrl"
                accept="image/*"
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0];
                  if (file) {
                    setChangeProfileImage((state) => ({
                      ...state,
                      fileUrl: file,
                    }));
                  }
                }}
              />
              <StyledFileInputLabel htmlFor="idCardImageUrl">
                {changeProfileImage.fileUrl
                  ? changeProfileImage.fileUrl.name
                  : "Upload new photo"}
              </StyledFileInputLabel>
            </StyledFileInputWrapper>
          </form>
        </section>
        <section className={styled.actions}>
          <div className={styled.container}>
            <button className={styled.close} onClick={() => closeModal()}>
              Close
            </button>
            <button
              className={styled.finish}
              onClick={() => {
                handleChangeProfileImage();
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
