import styled from "./EarningStatistics.module.scss";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Monitor from "../../../../../assets/icons/monitor-recorder.svg";
export const EarningStatisticsReportsPage = () => {
  return (
    <section className={styled.earningStatistics}>
      <div className={styled.container}>
        <section className={styled.header}>
          <div className={styled.leftSide}>
            <h1>Earning statistics</h1>
          </div>
          <div className={styled.rightSide}>
            <button>
              <p>This month</p>
              <KeyboardArrowDown />
            </button>
          </div>
        </section>
        <section className={styled.total}>
          <div className={styled.name}>
            <h1>Total</h1>
          </div>
          <div className={styled.statistics}>
            <section className={styled.left}>
              <h1>250$</h1>
            </section>
            <section className={styled.right}>
              <button>See</button>
            </section>
          </div>
        </section>
        <section className={styled.breakdown}>
          <div className={styled.name}>
            <h1>Breakdown</h1>
          </div>
          <div className={styled.prices}>
            <section className={styled.left}>
              <h1>120$</h1>
              <p>Online</p>
              <div className={styled.icon}></div>
            </section>
            <section className={styled.right}>
              <h1>120$</h1>
              <p>In Person</p>
              <div className={styled.icon}></div>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
};
