import { useContext } from "react";
import styled from "./Packets.module.scss";
import { tokenContext } from "../../../../../../Contexts/TokenContext";
import { decodedToken } from "../../../../../../models/TokenContextModels";
import { jwtDecode } from "jwt-decode";
import { GetDoctorServiceQueryKey } from "../../../../../../API/QueryKeys";
import { useQuery } from "react-query";
import { useService } from "../../../../../../hooks";
import { getDoctorServiceForDoctorDetails } from "../../../../../../models/DoctorServiceModels";
import { AppointmentsSetModalIsOpen } from "../../../../../../models/ModalsModels";
export const PacketsListDoctorPage: React.FC<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<
    React.SetStateAction<AppointmentsSetModalIsOpen>
  >;
}> = ({ isModalOpen, setIsModalOpen }) => {
  const { servicesDoctorServices } = useService();
  const { accessToken } = useContext(tokenContext);
  const decodedToken: decodedToken | null = accessToken
    ? jwtDecode(accessToken)
    : null;
  const doctorId = decodedToken
    ? decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ]
    : "";
  const doctorPacketsQuery = useQuery(
    [GetDoctorServiceQueryKey, doctorId],
    () => servicesDoctorServices.getDoctorService(doctorId, accessToken)
  );
  if (doctorPacketsQuery.isLoading) {
    return <h1>Is loading</h1>;
  }

  return (
    <section className={styled.packets}>
      <div className={styled.header}>
        <div className={styled.container}>
          <h1>Your Packets</h1>
          <button
            onClick={() =>
              setIsModalOpen((state) => ({
                ...state,
                createPacket: true,
                editTimes: false,
              }))
            }
          >
            Create new packet +
          </button>
        </div>
      </div>
      <div className={styled.packetsList}>
        <div className={styled.container}>
          {doctorPacketsQuery.data?.data.map(
            (doctorPacket: getDoctorServiceForDoctorDetails) => {
              return (
                <div className={styled.packet}>
                  <section className={styled.packetName}>
                    <h1>{doctorPacket.name}</h1>
                  </section>
                  <section className={styled.description}>
                    <h1>{doctorPacket.description}</h1>
                  </section>
                  <section className={styled.info}>
                    <p>{doctorPacket.serviceType.name}</p>
                    <p>{doctorPacket.price}$</p>
                    <p>{doctorPacket.duration} minutes</p>
                  </section>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};
