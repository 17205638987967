import styled from "./Workhours.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useQuery } from "react-query";
import { getDoctorAvailabilityForDoctorPageQueryKey } from "../../../../../../API/QueryKeys";
import { useContext, useState } from "react";
import { tokenContext } from "../../../../../../Contexts/TokenContext";
import { useService } from "../../../../../../hooks";
import { AppointmentsSetModalIsOpen } from "../../../../../../models/ModalsModels";
const daysOfTheWeek = [
  { day: "Sunday", number: 0 },
  { day: "Monday", number: 1 },
  { day: "Tuesday", number: 2 },
  { day: "Wednesday", number: 3 },
  { day: "Thursday", number: 4 },
  { day: "Friday", number: 5 },
  { day: "Saturday", number: 6 },
];
export const WorkHoursDoctorPage: React.FC<{
  setIsModalOpen: React.Dispatch<
    React.SetStateAction<AppointmentsSetModalIsOpen>
  >;
}> = ({ setIsModalOpen }) => {
  const today = new Date();
  const [currentDay, setCurrentDay] = useState(today.getDay());
  const { doctorAvailabilityServices } = useService();
  const { accessToken } = useContext(tokenContext);
  const availableHoursQuery = useQuery(
    [getDoctorAvailabilityForDoctorPageQueryKey],
    () =>
      doctorAvailabilityServices.getDoctorAvailabilityForDoctorPage(accessToken)
  );
  return (
    <section className={styled.workHours}>
      <div className={styled.header}>
        <div className={styled.container}>
          <h1>Work hours and days</h1>
          <button
            onClick={() =>
              setIsModalOpen((state) => ({
                ...state,
                editTimes: true,
                createPacket: false,
              }))
            }
          >
            Edit times
          </button>
        </div>
      </div>
      <div className={styled.main}>
        <section className={styled.navigation}>
          <div className={styled.container}>
            <button
              onClick={() =>
                setCurrentDay((state) => (state > 0 ? state - 1 : 6))
              }
            >
              <ArrowBackIosIcon />
            </button>
            <h1>
              {daysOfTheWeek.find((day) => day.number === currentDay)?.day}
            </h1>
            <button
              onClick={() =>
                setCurrentDay((state) => (state < 6 ? state + 1 : 0))
              }
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        </section>
        <section className={styled.hoursList}>
          <div className={styled.container}>
            {availableHoursQuery.data?.data
              .find((day: any) => day.dayOfWeek === currentDay)
              .availableHours.map((availableHour: any) => {
                return (
                  <div className={styled.hour}>
                    {availableHour.time.substring(0, 5)}
                  </div>
                );
              })}
          </div>
        </section>
        <section className={styled.extraInfo}>
          <div className={styled.container}>
            <p>
              You will be available from <b>1 January</b> to <b>1 March</b>
            </p>
          </div>
        </section>
      </div>
    </section>
  );
};
