import { scheduleAppointment } from "../../models/AppointmentModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class AppointmentService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async createAppointment(
    scheduleAppointment: scheduleAppointment,
    token: string | undefined
  ) {
    return await this.post("Appointments", scheduleAppointment, token);
  }
  async getDoctorAppointments(token: string | null) {
    return await this.getAll("Appointments/doctor", token);
  }
  async getPatientAppointments(token: string | null) {
    return await this.getAll("Appointments/patient", token);
  }
}