import styled from "./VerifyEmailMessage.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import EmailConfirmed from "../../../assets/images/email-confirmed.png";
import { useEffect, useState } from "react";

export const VerifyEmailMessage = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);
  };

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);
    return (
      <div className={styled.verify__email__message}> 
        <div className={styled.container}>
            <div className={styled.verify__email__message__wrapper}>

                <div className={styled.verify__email__message__image__wrapper}>
                  <img src={EmailConfirmed} alt="" className={styled.verify__email__message__image}/>
                </div>

                <div className={styled.verify__email__message__wrapper__heading}>
                    You verified your email
                </div>

                <div className={styled.verify__email__message__description__wrapper}>
                    <span className={styled.verify__email__message__description}>You will be redirected to our website in XX seconds</span>
                </div>

                {/* <div className={styled.verify__email__message__doczysupport__wrapper}>
                    <span className={styled.verify__email__message__doczysupport}>Check your spam folder if you dont see any email from us or contact us at Doczysupport@xxxx.com.</span>
                </div> */}

                <div className={styled.verify__email__message__resendlink__wrapper}>
                    <a className={styled.verify__email__message__resendlink} href="SignIn/Patient">Click here if you haven’t automatically redirected.</a>
                </div>
                
            </div>
        </div>
      </div>
    );
};