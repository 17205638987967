import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class doctorCategoryService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getAllDoctorCategories() {
    return await this.getAll("DoctorCategories", null);
  }
  //   async createHospital(body, token) {
  //     return await this.post("Hospitals", body, token);
  //   }
  //   async getCategoryService(doctorId: string | undefined, token: string) {
  //     return await this.getById("Services", doctorId, token);
  //   }
  //   async getDoctorServiceByType(
  //     doctorId: string | undefined,
  //     typeId: string | undefined
  //   ) {
  //     return await this.getByIdWithExtraRoute(
  //       "Services/by-type",
  //       doctorId,
  //       typeId
  //     );
  //   }
}
