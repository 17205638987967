import ProblemHanlingImg from "../../../../assets/images/ProblemHandler.png";
function ProblemHandling() {
  return (
    <section className="problemHandling">
      <div className="container">
        <section className="header">
          <h1>Learn your problem</h1>
        </section>
        <section className="content">
          <div className="content-left">
            <div className="header">
              <h1>
                You can learn more about your problem by talking to our virtual
                assistant or specialized therapist
              </h1>
            </div>
            <div className="info">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="buttons">
              <button>Talk to our virtual assistant</button>
              <button>Talk to therapist</button>
            </div>
          </div>
          <div className="content-right">
            <div className="image">
              <img src={ProblemHanlingImg}></img>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default ProblemHandling;
