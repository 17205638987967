import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "./ScheduleAnAppointment.module.scss";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigator } from "../../../components/User/DoctorsPage/ScheduleAnAppointment/Navigator/Navigator";
import { ScheduleAnAppointmentContext } from "../../../Contexts/ScheduleAppointment-Context";
import { useQueryClient } from "react-query";
export const ScheduleAnAppointmentLayout: React.FC<{
  scheduleAppointmentIsOpen: boolean;
  setScheduleAppointmentIsOpen: Dispatch<SetStateAction<boolean>>;
  servicesQuery: any;
  serviceTypeData: any;
}> = ({
  scheduleAppointmentIsOpen,
  setScheduleAppointmentIsOpen,
  serviceTypeData,
  servicesQuery,
}) => {
  let scheduleAnAppointment = document.getElementById("scheduleAppointment");
  let scheduleAnAppointmentActions = document.getElementById(
    "ScheduleAppointmentAction"
  );

  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { appointment, setAppointment, doctorInfo, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  useEffect(() => {
    scheduleAnAppointment = document.getElementById("scheduleAppointment");
    scheduleAnAppointmentActions = document.getElementById(
      "ScheduleAppointmentAction"
    );
  }, []);

  const { pathname } = useLocation();
  const [location, setLocation] = useState<string>(
    pathname.substring(pathname.lastIndexOf("/") + 1)
  );

  useEffect(() => {
    setLocation(pathname.substring(pathname.lastIndexOf("/") + 1));
  }, [pathname]);
  const nextButtonHandler = () => {
    switch (location) {
      case "Service":
        if (doctorInfo.appointmentTypeId && appointment.serviceId) {
          navigate("Date");
          setCurrentPage((state) => state + 1);
        }
        break;
      case "Date":
        if (appointment.chosenDate && appointment.chosenHourId) {
          setCurrentPage((state) => state + 1);
          navigate("Review");
        }
        break;
      case "Review":
        navigate("Payment");
        setCurrentPage((state) => state + 1);

        break;
    }
  };
  const backButtonHandler = () => {
    switch (location) {
      case "Date":
        navigate("Service");
        setCurrentPage((state) => state - 1);
        break;
      case "Review":
        navigate("Date");
        setCurrentPage((state) => state - 1);

        break;
      case "Payment":
        navigate("Review");
        setCurrentPage((state) => state - 1);

        break;
      case "Confirm":
        navigate("Payment");
        setCurrentPage((state) => state - 1);

        break;
    }
  };

  const isDoctorPaymentPage = pathname.includes("/Doctors/Payment");
  const actionsClassName = `${styled.actions} ${
    isDoctorPaymentPage ? "active" : ""
  }`;

  return (
    <div
      className={styled.scheduleAnAppointment}
      // style={{ width: `${location === "Payment" ? "550px" : ""}` }}
      id="scheduleAppointment"
    >
      <div className={styled.container}>
        <section className={styled.header}>
          <div className={styled.content}>
            <div className={styled.contentLeft}>
              <h1>Schedule an appointment</h1>
            </div>
            <div className={styled.contentRight}>
              <button
                onClick={() => {
                  queryClient.invalidateQueries();
                  setScheduleAppointmentIsOpen(false);
                  setCurrentPage(1);
                  navigate("/Doctors");
                  if (scheduleAnAppointment && scheduleAnAppointmentActions) {
                    scheduleAnAppointment.style.right = "-400px";
                    scheduleAnAppointmentActions.style.right = "-400px";
                  }
                  setDoctorInfo(() => ({
                    doctorFirstName: "",
                    doctorLastName: "",
                    doctorCategory: "",
                    doctorImage: "",
                    appointmentTypeId: "",
                    categoryName: "",
                    packetName: "",
                    typeName: "",
                    packetPrice: "",
                    packetDuration: "",
                    appoinmentTime: "",
                    doctorRating: null,
                  }));
                  setAppointment({
                    doctorId: "",
                    serviceId: "",
                    chosenDate: "",
                    chosenHourId: "",
                    painDescription: "",
                  });
                }}
              ></button>
            </div>
          </div>
        </section>
        <Navigator
          currentPage={currentPage}
          location={location}
          appointment={appointment}
        />

        <section className={styled.line}></section>
        <Outlet
          context={[
            appointment,
            setAppointment,
            serviceTypeData,
            servicesQuery,
            doctorInfo,
            setDoctorInfo,
            setScheduleAppointmentIsOpen,
            scheduleAppointmentIsOpen,
          ]}
        />
        {/* <section className={styled.actions} id="ScheduleAppointmentAction"> */}
        <section className={actionsClassName} id="ScheduleAppointmentAction">
          <div className={styled.content}>
            <div className={styled.left}>
              {!(
                pathname.includes("Service") || pathname.includes("Confirm")
              ) ? (
                <button
                  onClick={backButtonHandler}
                  disabled={pathname === "Confirm" ? true : false}
                >
                  Back
                </button>
              ) : (
                ""
              )}
            </div>
            <div className={styled.right}>
              {pathname.substring(pathname.lastIndexOf("/") + 1) ===
              "Confirm" ? (
                <button
                  style={{ background: "#00696C" }}
                  onClick={() => {
                    queryClient.invalidateQueries();
                    setScheduleAppointmentIsOpen(false);
                    setCurrentPage(1);
                    if (scheduleAnAppointment) {
                      scheduleAnAppointment.style.right = "-400px";
                    }
                    setDoctorInfo(() => ({
                      doctorFirstName: "",
                      doctorLastName: "",
                      doctorCategory: "",
                      doctorImage: "",
                      appointmentTypeId: "",
                      categoryName: "",
                      packetName: "",
                      typeName: "",
                      packetPrice: "",
                      packetDuration: "",
                      appoinmentTime: "",
                      doctorRating: null,
                    }));
                    setAppointment({
                      doctorId: "",
                      serviceId: "",
                      chosenDate: "",
                      chosenHourId: "",
                      painDescription: "",
                    });
                    if (
                      sessionStorage.getItem("doctorInfo") &&
                      sessionStorage.getItem("appointmentInfo")
                    ) {
                      sessionStorage.removeItem("doctorInfo");
                      sessionStorage.removeItem("appointmentInfo");
                    }
                    navigate("/PatientPage/Appointments");
                  }}
                >
                  Go to appointments
                </button>
              ) : pathname.substring(pathname.lastIndexOf("/") + 1) ===
                "Payment" ? (
                <button
                  style={{ background: "#00696C" }}
                  onClick={nextButtonHandler}
                >
                  Confirm and pay
                </button>
              ) : (
                <button onClick={nextButtonHandler}>Next</button>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
