import { useNavigate } from "react-router-dom";
import styled from "../Sidebar/Sidebar.module.scss";

export const SidebarRoute: React.FC<{
  location: string;
  route: string;
  name: string;
}> = ({ location, route, name }) => {
  const navigate = useNavigate();
  return (
    <div className={styled.route} onClick={() => navigate(route)}>
      <div
        className={`${styled.icon} ${
          location.includes(name) ? "activeRoute" : ""
        }`}
      ></div>
      <h1>{name}</h1>
    </div>
  );
};
