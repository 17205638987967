import { Autocomplete, TextField } from "@mui/material";
import styled from "./Profile.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useService } from "../../../../../hooks";
import { useQuery } from "react-query";
import {
  GetDoctorAboutQueryKey,
  GetDoctorResumeQueryKey,
  getAllLanguages,
} from "../../../../../API/QueryKeys";
import { tokenContext } from "../../../../../Contexts/TokenContext";
import { decodedToken } from "../../../../../models/TokenContextModels";
import { jwtDecode } from "jwt-decode";
import {
  GetDoctorAbout,
  getDoctorAboutLanguages,
  getDoctorResumeDto,
} from "../../../../../models/DoctorModels";
import { AddNewExperience } from "./Modals/Modals";
import dayjs from "dayjs";
import { AddNewEducationModal } from "./Modals/AddNewEducationModal";

export const ProfileSettings = () => {
  const [doctorResumeData, setDoctorResumeData] =
    useState<getDoctorResumeDto | null>(null);
  const [doctorAboutData, setDoctorAboutData] = useState<GetDoctorAbout | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState({
    addNewExperience: false,
    addNewEducation: false,
  });
  const closeModal = (name: string) => {
    setIsModalOpen((state) => ({
      ...state,
      [name]: false,
    }));
  };
  const { accessToken } = useContext(tokenContext);
  const decodedToken: decodedToken | undefined = accessToken
    ? jwtDecode(accessToken)
    : undefined;
  const doctorId = decodedToken
    ? decodedToken[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ]
    : "";
  const { doctorServices, languageServices } = useService();
  const doctorAboutQuery = useQuery([GetDoctorAboutQueryKey, doctorId], () =>
    doctorServices.getDoctorAboutById(doctorId, accessToken)
  );
  const doctorResumeQuery = useQuery([GetDoctorResumeQueryKey, doctorId], () =>
    doctorServices.getDoctorResumeById(doctorId, accessToken)
  );
  const languageQuery = useQuery([getAllLanguages, doctorId], () =>
    languageServices.getAllLanguages(accessToken)
  );
  useEffect(() => {
    if (doctorAboutQuery.isSuccess) {
      setDoctorAboutData(doctorAboutQuery.data?.data);
    }
  }, [doctorAboutQuery.isSuccess]);
  useEffect(() => {
    if (doctorResumeQuery.isSuccess) {
      setDoctorResumeData(doctorResumeQuery.data?.data);
    }
  }, [doctorResumeQuery.isSuccess]);

  if (!doctorAboutData || !doctorResumeData) {
    return <h1>Is Loading</h1>;
  }

  return (
    <section className={styled.profile}>
      <section className={styled.header}>
        <h1>Profile</h1>
      </section>
      <section className={styled.aboutYou}>
        <section className={styled.heading}>
          <h1>About you</h1>
        </section>
        <section className={styled.input}>
          <textarea
            placeholder="About you"
            value={doctorAboutData.aboutDoctor}
          />
        </section>
        <section className={styled.action}>
          <button>Edit</button>
        </section>
      </section>
      <section className={styled.languagesSpecialities}>
        <section className={styled.heading}>
          <h1>Languages & Specialities</h1>
        </section>
        <section className={styled.languages}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={languageQuery.data?.data ?? []}
            getOptionLabel={(option: getDoctorAboutLanguages) => option.name}
            defaultValue={doctorAboutData.languages}
            onChange={(
              event: SyntheticEvent,
              newValue: getDoctorAboutLanguages[]
            ) => {
              setDoctorAboutData((state) =>
                state
                  ? {
                      ...state,
                      languages: newValue,
                    }
                  : null
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select languages"
                placeholder="Select languages"
              />
            )}
          />
          <p>Currently selected languages</p>
          <div className={styled.selectedLanguages}>
            {doctorAboutData.languages?.map(
              (language: getDoctorAboutLanguages) => {
                return (
                  <div key={language.id}>
                    <h1>{language.name}</h1>
                    <button>
                      <CloseIcon />
                    </button>
                  </div>
                );
              }
            )}
          </div>
        </section>
        {/* <section className={styled.specialities}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={[]}
            getOptionLabel={(option: getSpecialityDto) => option.title}
            defaultValue={doctorAboutData.specialities}
            size="medium"
            sx={{ width: "50%" }}
            renderInput={(params) => (
              <TextField {...params} label="Select specialities" />
            )}
          />
          <p>Currently selected specialities</p>
          <div className={styled.selectedSpecialities}>
            <div>
              <h1>Speciality</h1> <CloseIcon />
            </div>
          </div>
        </section> */}
      </section>
      <section className={styled.experience}>
        <section className={styled.column}>
          <div className={styled.heading}>
            <div className={styled.leftSide}>
              <h1>Experience</h1>
            </div>
            <div className={styled.rightSide}>
              <button
                onClick={() =>
                  setIsModalOpen((state) => ({
                    ...state,
                    addNewExperience: true,
                    addNewEducation: false,
                  }))
                }
              >
                Add new
              </button>
              <div></div>
              <button>Edit</button>
            </div>
          </div>
          <div className={styled.main}>
            <AddNewExperience
              doctorId={doctorId}
              addNewExperience={isModalOpen.addNewExperience}
              closeModal={closeModal}
            />
            {doctorResumeData.experiances?.map((experience) => {
              return (
                <div className={styled.info} key={experience.id}>
                  <h1>{experience.title}</h1>
                  <h2>{experience.hospital}</h2>
                  <p>{experience.location}</p>
                  <p>
                    {dayjs(experience.startDate).format("YYYY/MM/DD")} -{" "}
                    {dayjs(experience.endDate).format("YYYY/MM/DD")}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        <section className={styled.column}>
          <div className={styled.heading}>
            <div className={styled.leftSide}>
              <h1>Education</h1>
            </div>
            <div className={styled.rightSide}>
              <button
                onClick={() =>
                  setIsModalOpen((state) => ({
                    ...state,
                    addNewEducation: true,
                    addNewExperience: false,
                  }))
                }
              >
                Add new
              </button>
              <div></div>
              <button>Edit</button>
            </div>
          </div>
          <div className={styled.main}>
            <AddNewEducationModal
              addNewEducation={isModalOpen.addNewEducation}
              closeModal={() => closeModal("addNewEducation")}
            />
            {doctorResumeData.educations.map((education) => {
              return (
                <div className={styled.info} key={education.id}>
                  <h1>{education.fieldOfStudy}</h1>
                  <h2>{education.univercity}</h2>
                  <p>{education.univercityDegree}</p>
                  <p>
                    {education.startDate} - {education.endDate}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
      </section>
    </section>
  );
};
