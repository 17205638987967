import styled from "./ReviewDoctorDetails.module.scss";
import doctorImage from "../../../../../assets/images/doc-image.png";
import stethoscope from "../../../../../assets/icons/stethoscope-2.svg";
import pc from "../../../../../assets/icons/monitor-mobbile.svg";
import videoTime from "../../../../../assets/icons/video-time.svg";
import calendar from "../../../../../assets/icons/calendar-tick.svg";
import clock from "../../../../../assets/icons/clock.svg";
import location from "../../../../../assets/icons/location.svg";
import { useContext, useEffect, useState } from "react";
import { appointmentTypes, packets } from "../Service/ServiceDoctorDetails";
import { scheduleAppointment } from "../../../../../models/AppointmentModels";
import { useOutletContext } from "react-router-dom";
import { ScheduleAnAppointmentContext } from "../../../../../Contexts/ScheduleAppointment-Context";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const ReviewDoctorDetails = () => {
  const { appointment, setAppointment, doctorInfo, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const [packet, setPacket] = useState(
    packets.find((packet) => packet.id === appointment?.serviceId)
  );
  const [appointmentType, setAppointmentType] = useState(
    appointmentTypes.find(
      (appointmentType) => appointmentType.id === doctorInfo?.appointmentTypeId
    )
  );
  const dateString = appointment?.chosenDate ?? "";
  const [day, month, year] = dateString.split("/").map(Number);

  const date = new Date(year, month - 1, day);
  const properties = [
    {
      header: "Packet",
      icon: stethoscope,
      value: packet?.duration,
    },
    {
      header: "Type",
      icon: pc,
      value: appointmentType?.content,
    },
    {
      header: "Price and Duration",
      icon: calendar,
      value: `${packet?.price} - ${packet?.time}`,
    },
    {
      header: "Date and Time",
      icon: videoTime,
      secondIcon: clock,
      value: `${dateString.substring(0, dateString.indexOf("/"))} ${
        monthNames[date.getMonth()]
      }  ${date.getFullYear()}
-
        ${appointment?.chosenHourId}`,
    },
    {
      header: "Location",
      icon: location,
      value: "Azerbaijan Bakue",
    },
  ];
  return (
    <>
      <div className={styled.review}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1>Review</h1>
          </div>
          <div className={styled.information}>
            <section className={styled.doctorInfo}>
              <div className={styled.image}>
                <img src={doctorImage} />
              </div>
              <div className={styled.fullname}>
                {/* <h1>{appointment?.cardNameSurname}</h1>
                <p>{appointment?.categoryName}</p> */}
              </div>
            </section>
            <section className={styled.properties}>
              {properties.map((property) => {
                return (
                  <div className={styled.box}>
                    <div className={styled.header}>
                      <h1>{property.header}</h1>
                    </div>
                    <div className={styled.packetName}>
                      <div
                        className={styled.icon}
                        style={{
                          backgroundImage: `url(${property.icon})`,

                          width: "20px",
                          height: "20px",
                        }}
                      ></div>
                      {property.secondIcon && (
                        <div
                          className={styled.icon}
                          style={{
                            backgroundImage: `url(${property.secondIcon})`,

                            width: "20px",
                            height: "20px",
                          }}
                        ></div>
                      )}
                      <div className={styled.value}>
                        <h1>{property.value}</h1>
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
          </div>
          <div className={styled.feedback}>
            <section className={styled.radio}>
              <div className={styled.radioArea}>
                {/* <input
                  type="radio"
                  checked={appointment?.permission}
                  onClick={() =>
                    setAppointment((state) => ({
                      ...state,
                      permission: !state.permission,
                    }))
                  }
                /> */}
              </div>
              <div>
                <h1>
                  I give permission that my doctor can have access to my health
                  information
                </h1>
              </div>
            </section>
            <section className={styled.reason}>
              <div>
                <h1>Reason</h1>
              </div>
              <div className={styled.inputArea}>
                <textarea
                  placeholder="Please briefly explain your health problem in here.*"
                  onChange={(e) =>
                    setAppointment((state) => ({
                      ...state,
                      reason: e.target.value,
                    }))
                  }
                  value={appointment?.painDescription ?? ""}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
