import styled from "./BasicInformation.module.scss";
import profileImage from "../../../../../assets/images/Group 3.png";
import { Autocomplete, TextField } from "@mui/material";
import { useService } from "../../../../../hooks";
import { useQuery } from "react-query";
import {
  GetDoctorDetailQueryKey,
  getAllDoctorCategoriesQueryKey,
} from "../../../../../API/QueryKeys";

import { useContext, useEffect, useState } from "react";
import { tokenContext } from "../../../../../Contexts/TokenContext";
import { getDoctorProfileDTO } from "../../../../../models/DoctorModels";
import { DoctorProfileImagesRoot, MainURL } from "../../../../../API/URLs";
import { ChangeProfileImageModal } from "./ChangeProfileImageModal";
import { RemoveProfileImageModal } from "./RemoveProfileImage";
import { BasicInformationModalIsOpen } from "../../../../../models/ModalsModels";
export const BasicInformationSettings = () => {
  const { doctorServices, doctorCategoryServices } = useService();
  const { accessToken } = useContext(tokenContext);
  const [doctorData, setDoctorData] = useState<getDoctorProfileDTO | null>(
    null
  );
  const [modalIsOpen, setModalIsOpen] = useState<BasicInformationModalIsOpen>({
    changeProfileImage: false,
    removeProfileImage: false,
  });

  const categoryQuery = useQuery([getAllDoctorCategoriesQueryKey], () =>
    doctorCategoryServices.getAllDoctorCategories()
  );
  const basicInformationQuery = useQuery([GetDoctorDetailQueryKey], () =>
    doctorServices.GetDoctorForDoctorPage(accessToken)
  );

  useEffect(() => {
    if (basicInformationQuery.isSuccess) {
      setDoctorData(basicInformationQuery.data?.data);
    }
  }, [basicInformationQuery.isSuccess]);

  if (!doctorData || categoryQuery.isLoading) {
    return <h1>Is Loading</h1>;
  }
  const closeModal = (modalName: string) => {
    setModalIsOpen((state) => ({
      ...state,
      [modalName]: false,
    }));
  };
  return (
    <section className={styled.basicInformation}>
      <div className={styled.container}>
        <section className={styled.header}>
          <h1>Basic Information</h1>
        </section>
        <section className={styled.profilePicture}>
          <section className={styled.heading}>
            <h1>Profile picture</h1>
          </section>
          <section className={styled.image}>
            <img
              src={`${MainURL}/${DoctorProfileImagesRoot}/${doctorData.profileImageUrl}`}
            />
          </section>
          <section className={styled.info}>
            <h1>Profile picture is publicly available to everyone. </h1>
          </section>
          <section className={styled.actions}>
            <button
              onClick={() =>
                setModalIsOpen((state) => ({
                  ...state,
                  changeProfileImage: true,
                }))
              }
            >
              Change
            </button>
            <div className={styled.border}></div>
            <button
              onClick={() =>
                setModalIsOpen((state) => ({
                  ...state,
                  removeProfileImage: true,
                }))
              }
            >
              Remove
            </button>
          </section>
        </section>
        <section className={styled.personalInformation}>
          <section className={styled.heading}>
            <h1>Personal Information</h1>
          </section>
          <section className={styled.inputsOne}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{ width: "100%" }}
              value={doctorData.firstName}
              disabled
            />
            <TextField
              id="outlined-basic"
              label="Surname"
              variant="outlined"
              sx={{ width: "100%" }}
              value={doctorData.lastName}
              disabled
            />
          </section>
          <section className={styled.inputsTwo}>
            <Autocomplete
              disablePortal
              disabled
              id="combo-box-demo"
              options={[]}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
            <Autocomplete
              disabled
              disablePortal
              id="combo-box-demo"
              options={[]}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Gender" />}
            />
          </section>
        </section>
      </div>
      <ChangeProfileImageModal
        changeProfileImageisOpen={modalIsOpen.changeProfileImage}
        closeModal={() => closeModal("changeProfileImage")}
        setDoctorData={setDoctorData}
      />
      <RemoveProfileImageModal
        closeModal={closeModal}
        isModalOpen={modalIsOpen.removeProfileImage}
      />
    </section>
  );
};
