import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class LanguageService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getAllLanguages(token: string | null) {
    return await this.getAll("Languages", token);
  }
}
