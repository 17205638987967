import { EditTimesDTO } from "../../models/AvailabilityModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class DoctorAvailabilityService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getDoctorAvailabilityByDoctorIdDate(
    id: string | undefined,
    dateTime: string | undefined
  ) {
    return await this.getByIdWithExtraRoute("Availabilities", id, dateTime);
  }
  async getDoctorAvailabilityForDoctorPage(token: string | null) {
    return await this.getAll("Availabilities/DoctorPage", token);
  }
  async createDoctorAvailability(
    CreateAvailableHours: EditTimesDTO,
    token: string | undefined
  ) {
    return await this.post("Availabilities", CreateAvailableHours, token);
  }
}
