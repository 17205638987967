import styles from "../Navigation.module.scss";
import personalCard from "../../../../../assets/icons/personalcard.svg";
import documentText from "../../../../../assets/icons/document-text.svg";
import briefCase from "../../../../../assets/icons/briefcase.svg";
import shield from "../../../../../assets/icons/shield-tick.svg";
import call from "../../../../../assets/icons/call.svg";
import { useNavigate } from "react-router-dom";
import { IconDiv } from "../../../../../UI/styledComponents";
import { useEffect, useState } from "react";
export const buttons = [
  {
    navigate: "AboutDoc",
    innerText: "About Doc",
    icon: personalCard,
  },
  {
    navigate: "Resume",
    innerText: "Resume",
    icon: documentText,
  },
  {
    navigate: "Services",
    innerText: "Services",
    icon: briefCase,
  },
  {
    navigate: "Contact",
    innerText: "Contact",
    icon: call,
  },
  {
    navigate: "Insurance",
    innerText: "Insurance",
    icon: shield,
  },
];

export const ButtonList: React.FC<{
  currentPathname: string;
  setWindowIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const StyledIconDiv: React.FC<{
    iconPass: string;
  }> = ({ iconPass }) => {
    const StyledIcon = IconDiv(typeof iconPass === "string" ? iconPass : "");

    return <StyledIcon />;
  };

  const navigate = useNavigate();

  return (
    <div className={styles.buttonList}>
      <ul className={styles.buttonsContainer}>
        {buttons.map((button) => {
          if (button.navigate !== props.currentPathname) {
            return (
              <li>
                <div
                  className={styles.singleButton}
                  onClick={() => (
                    navigate(button.navigate),
                    props.setWindowIsOpen((state) => !state)
                  )}
                >
                  <button
                    style={{
                      background: `${
                        props.currentPathname === button.navigate
                          ? "#cce8e8"
                          : ""
                      }`,
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${button.icon})`,
                        backgroundSize: "20px",
                      }}
                    ></div>
                    <StyledIconDiv iconPass={button.icon}></StyledIconDiv>
                    {button.innerText}
                  </button>
                </div>
              </li>
            );
          }
        })}

        {/* <li>
    <div
      className={styles.singleButton}
      onClick={() => navigate("Insurance")}
    >
      <button
        style={{
          background: `${
            currentPathname === "Insurance" ? "#cce8e8" : ""
          }`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${icon})`,
            backgroundSize: "20px",
          }}
        ></div>
        <StyledIconDiv></StyledIconDiv>
        {currentPathname}
        <div
          className={styles.arrow}
          style={{
            backgroundImage: `url(${arrow})`,
            backgroundSize: "30px",
          }}
        ></div>
      </button>
    </div>
  </li>
  <li>
    <div
      className={styles.singleButton}
      onClick={() => navigate("Insurance")}
    >
      <button
        style={{
          background: `${
            currentPathname === "Insurance" ? "#cce8e8" : ""
          }`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${icon})`,
            backgroundSize: "20px",
          }}
        ></div>
        <StyledIconDiv></StyledIconDiv>
        {currentPathname}
        <div
          className={styles.arrow}
          style={{
            backgroundImage: `url(${arrow})`,
            backgroundSize: "30px",
          }}
        ></div>
      </button>
    </div>
  </li>
  <li>
    <div
      className={styles.singleButton}
      onClick={() => navigate("Insurance")}
    >
      <button
        style={{
          background: `${
            currentPathname === "Insurance" ? "#cce8e8" : ""
          }`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${icon})`,
            backgroundSize: "20px",
          }}
        ></div>
        <StyledIconDiv></StyledIconDiv>
        {currentPathname}
        <div
          className={styles.arrow}
          style={{
            backgroundImage: `url(${arrow})`,
            backgroundSize: "30px",
          }}
        ></div>
      </button>
    </div>
  </li>
  <li>
    <div
      className={styles.singleButton}
      onClick={() => navigate("Insurance")}
    >
      <button
        style={{
          background: `${
            currentPathname === "Insurance" ? "#cce8e8" : ""
          }`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${icon})`,
            backgroundSize: "20px",
          }}
        ></div>
        <StyledIconDiv></StyledIconDiv>
        {currentPathname}
        <div
          className={styles.arrow}
          style={{
            backgroundImage: `url(${arrow})`,
            backgroundSize: "30px",
          }}
        ></div>
      </button>
    </div>
  </li> */}
      </ul>
    </div>
  );
};
