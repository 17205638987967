import dayjs from "dayjs";
import { MainURL, ServiceTypeIconsRoot } from "../../../../../API/URLs";
import styled from "./SecondColumn.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";

const daysOfTheWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const SecondColumnDoctorPage: React.FC<{ appoinmentData: any }> = ({
  appoinmentData,
}) => {
  const [appointments, setAppointments] = useState(appoinmentData);
  const currentDate = dayjs(new Date()).format("DD/MM/YYYY");
  const [currentDay, currentMonth, currentYear] = currentDate
    .split("/")
    .map(Number);
  // useEffect(() => {
  //   setAppointments(appoinmentData.filter((appointmentQuery)=>appointmentQuery.));
  // }, []);
  useEffect(() => {
    const test = new Date().toString();

    setAppointments(
      appoinmentData.filter((appointment: any) => {
        const date = new Date(appointment.appointmentDate).toString();
        return date === test;
      })
    );
  }, []);
  const minutes = new Date().getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return (
    <section className={styled.secondColumn}>
      <div className={styled.currentDate}>
        <div className={styled.box}>
          <section className={styled.date}>
            <h1>
              {daysOfTheWeek[new Date().getDay()]},{" "}
              {`${currentDay}  ${months[currentMonth - 1]} ${currentYear}`}{" "}
            </h1>
          </section>
          <section className={styled.time}>
            <p>
              {new Date().getHours()}:{formattedMinutes}
            </p>
          </section>
        </div>
      </div>
      <div className={styled.upcomingEvents}>
        <div className={styled.box}>
          <section className={styled.heading}>
            <h1>Upcoming appointments</h1>
            <button>See all</button>
          </section>
          <section className={styled.firstLine}>
            <div className={styled.header}>
              <h1>
                Starts in <b>15</b> minutes
              </h1>
            </div>
            <div className={styled.appointment}>
              <section className={styled.information}>
                <div className={styled.nameSurname}>
                  <h1>Name Surname</h1>
                </div>
                <div className={styled.description}>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
                <div className={styled.aboutService}>
                  <div></div>
                  <p>Online</p>
                  <p>17:45-18:15</p>
                  <p>30 minutes</p>
                </div>
              </section>
              <section className={styled.action}>
                <button>Go to appointment screen</button>
              </section>
            </div>
          </section>
          <section className={styled.secondLine}>
            <div className={styled.header}>
              <h1>
                Today
                <ExpandMoreIcon />
              </h1>
            </div>
            <div className={styled.appointments}>
              {appointments.map((appointment: any) => {
                return (
                  <div className={styled.appointment} key={appointment.id}>
                    <section className={styled.information}>
                      <div className={styled.nameSurname}>
                        <h1>
                          {appointment.firstName} {appointment.lastName}
                        </h1>
                      </div>
                      <div className={styled.description}>
                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                      </div>
                      <div className={styled.aboutService}>
                        <div>
                          <img
                            src={`${MainURL}/${ServiceTypeIconsRoot}/${appointment.serviceTypeIconUrl}`}
                          />
                        </div>
                        <p>{appointment.serviceTypeName}</p>
                        <p>17:45-18:15</p>
                        <p>30 minutes</p>
                      </div>
                    </section>
                    <section className={styled.action}>
                      <button>Go to appointment screen</button>
                    </section>
                  </div>
                );
              })}

              {/* <div className={styled.appointment}>
                <section className={styled.information}>
                  <div className={styled.nameSurname}>
                    <h1>Name Surname</h1>
                  </div>
                  <div className={styled.description}>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div className={styled.aboutService}>
                    <div></div>
                    <p>Online</p>
                    <p>17:45-18:15</p>
                    <p>30 minutes</p>
                  </div>
                </section>
                <section className={styled.action}>
                  <button>Go to appointment screen</button>
                </section>
              </div> */}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};
