import { useQuery } from "react-query";
import styled from "./Services.module.scss";
import { GetDoctorServiceQueryKey } from "../../../../API/QueryKeys";
import { useParams } from "react-router-dom";
import { useService } from "../../../../hooks";
import { getDoctorServiceForDoctorDetails } from "../../../../models/DoctorServiceModels";
import { MainURL, ServiceTypeIconsRoot } from "../../../../API/URLs";
export const Services = () => {
  const { servicesDoctorServices } = useService();
  const { id } = useParams();
  const doctorServicesQuery = useQuery([GetDoctorServiceQueryKey, id], () =>
    servicesDoctorServices.getDoctorService(id, null)
  );

  if (doctorServicesQuery.isLoading) {
    return <h1>Is Loading</h1>;
  }
  console.log(doctorServicesQuery.data?.data, "da");

  return (
    <div className={styled.Services}>
      <div className={styled.box}>
        <div className={styled.main}>
          <section className={styled.header}>
            <div className={styled.info}>
              <h1>Packets and Services</h1>
            </div>
          </section>
          <section className={styled.list}>
            <ul className={styled.main}>
              {doctorServicesQuery.data?.data.length
                ? doctorServicesQuery.data?.data.map(
                    (doctorService: getDoctorServiceForDoctorDetails) => {
                      return (
                        <li id={doctorService.name}>
                          <div className={styled.serviceName}>
                            <h1>{doctorService.name}</h1>
                          </div>
                          <div className={styled.content}>
                            <p>{doctorService.description}</p>
                          </div>
                          <div className={styled.action}>
                            <div>
                              <button>Use this service</button>
                            </div>
                            <div>
                              <p>{doctorService.duration} minutes</p>
                              <p>{doctorService.price}$</p>
                              <div
                                style={{
                                  backgroundImage: `url(${MainURL}/${ServiceTypeIconsRoot}/${doctorService.serviceType.iconUrl})`,
                                  backgroundRepeat: "no-repeat",
                                  width: "20px",
                                  height: "20px",
                                }}
                              ></div>

                              <p>{doctorService.serviceType.name}</p>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )
                : "Məlumat yoxdu"}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};
