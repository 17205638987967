import { createEducation } from "../../models/EducationModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class educationService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async createEducation(createBody: createEducation, token: string) {
    return await this.post("Educations", createBody, token);
  }
}
