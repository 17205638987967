import DoczyMobileAppStore from "../../../../assets/images/App-Store-dark.png";
import DoczyMobileGooglePlay from "../../../../assets/images/Google-Play-dark.png";
import MapsHome from "../../../../assets/images/maps_home_work.png";
import Phone from "../../../../assets/images/phone.png";
import envelope from "../../../../assets/images/Vector.png";
import facebook from "../../../../assets/images/facebook.png";
import instagram from "../../../../assets/images/instagram.png";
import twitter from "../../../../assets/images/twitter.png";
import youtube from "../../../../assets/images/youtube.png";

function Footer() {
    return (
        <footer className="section__footer">
            <div className="container">
               <div className="footer__heading__wrapper">
                <div className="footer__heading">
                        <div className="footer__heading__logo">
                            <a href="">
                                Doczy
                            </a>
                        </div>
                    </div>
               </div>
                <div className="footer__references">
                    <div className="references__left">
                        <div className="references__doczy__title">About Us</div>
                        <div className="references__doczy__description">
                            Doczy is dedicated to providing personalized medical advice, fostering a healthier community. Our vision is tailored care for each patient, ensuring exceptional support and well-being.
                        </div>
                        <div className="references__doczy__download">
                            <a href="#" className="doczy__app__store">
                                <img src={DoczyMobileAppStore} alt="" />
                            </a>
                            <a href="#" className="doczy__google__play">
                                <img src={DoczyMobileGooglePlay} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="references__right">
                        <div className="refernces__contact">
                            <div className="refernces__contact__up">Contact</div>
                            <div className="refernces__contact__bottom">
                                <div className="contact__number">
                                    <img src={Phone} alt="" />
                                    <span>(+994)55-448-20-45</span>
                                </div>
                                <div className="contact__mail">
                                    <img src={envelope} alt="" />
                                    <span>doczy.support@gmail.com</span>
                                </div>
                                <div className="contact__location">
                                    <img src={MapsHome} alt="" />
                                    <span>Baku, Nizami rayonu</span>
                                </div>
                            </div>
                        </div>
                        <div className="refernces__pages">
                            <div className="pages__up">Pages</div>
                            <div className="pages__bottom">
                                    <div className="page__item">
                                        <a href="#" className="page__item__link">Doctors</a>
                                    </div>
                                    <div className="page__item">
                                        <a href="#" className="page__item__link">Blogs</a>
                                    </div>
                                    <div className="page__item">
                                        <a href="#" className="page__item__link">Categories</a>
                                    </div>
                                    <div className="page__item">
                                        <a href="#" className="page__item__link">About us</a>
                                    </div>   
                            </div>
                        </div>
                        <div className="refernces__social__media">
                            <div className="social__media__up">Find us</div>
                            <div className="social__media__bottom">
                                <div className="social__media__item">
                                    <a href="https://www.youtube.com/watch?v=DRYzImN_bDM" className="social__media__item__link">
                                        <img src={facebook} alt="" />
                                    </a>
                                </div>
                                <div className="social__media__item">
                                    <a href="#" className="social__media__item__link">
                                        <img src={instagram} alt="" />
                                    </a>
                                </div>
                                <div className="social__media__item">
                                    <a href="#" className="social__media__item__link">
                                        <img src={twitter} alt="" />
                                    </a>
                                </div>
                                <div className="social__media__item">
                                    <a href="#" className="social__media__item__link">
                                        <img src={youtube} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__copyright">© 2024 Doczy. All rights reserved</div>
            </div>
        </footer>
    );
}

export default Footer;