import { createServiceDTO } from "../../models/DoctorServiceModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class ServicesDoctorService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getDoctorService(doctorId: string | undefined, token: null | string) {
    return await this.getById("Services", doctorId, token);
  }
  async getDoctorServiceByType(
    doctorId: string | undefined,
    typeId: string | undefined
  ) {
    return await this.getByIdWithExtraRoute(
      "Services/by-type",
      doctorId,
      typeId
    );
  }
  async createDoctorService(postService: createServiceDTO, token: string) {
    return await this.post("Services", postService, token);
  }
}
