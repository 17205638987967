import Welcome from "../../components/User/LandingPage/Welcome/Welcome";
import ServiceCategories from "../../components/User/LandingPage/ServiceCategories/ServiceCategories";
import ProblemHandling from "../../components/User/LandingPage/ProblemHandling/ProblemHandling";
import HowWeWork from "../../components/User/LandingPage/HowWeWork/HowWeWork";
import DoczyMobile from "../../components/User/LandingPage/DoczyMobile/DoczyMobile";
// import ComingSoon from "../../assets/icons/session.gif";
import ComingSoon from "../../assets/icons/doczy-coming-soon.gif";

export const BlogsPage = () => {
  return (
    <div className="blogPage">
        <div className="container">
            {/* <img src="https://4youcare.com/static/media/product.fdf4984fd853084f9bbf.gif" alt="Coming Soon" /> */}
            <img src={ComingSoon} alt="Coming Soon" />
            <h2>TEZLİKLƏ</h2>
        </div>
        {/* <div>
        </div> */}
    </div>
  );
};
