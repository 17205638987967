import { createNewExperience } from "../../models/ExperienceModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class experienceService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }

  async createExperience(
    postExperience: createNewExperience,
    token: string | undefined
  ) {
    return await this.post("Experiances", postExperience, token);
  }
}
