import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { NavigationDoctorDetails } from "../../components/User/DoctorDetailsPage/Navigation/Navigation";

import { SnackBar } from "../../components/User/DoctorDetailsPage/Snackbar/Snackbar";
import styled from "./DoctorDetailsPage.module.scss";

import { ScheduleAnAppointmentLayoutDoctotDetails } from "../../layouts/Global/ScheduleAnAppointmentDoctorDetails/ScheduleAnAppointDoctorDetails";
import { useContext, useEffect, useState } from "react";
import { doctorDetailsLoadingContext } from "../../Contexts/DoctorDetailsLoadingContext";

export const DoctorDetailsPage = () => {
  const bodyElement = document.getElementsByTagName("body")[0];
  const { pathname } = useLocation();
  const [scheduleAppointmentIsOpen, setScheduleAppointmentIsOpen] =
    useState<boolean>(false);
  const { loading } = useContext(doctorDetailsLoadingContext);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (scheduleAppointmentIsOpen) {
      bodyElement.style.overflowY = "hidden";
    } else {
      bodyElement.style.overflowY = "auto";
    }
  }, [scheduleAppointmentIsOpen]);
  useEffect(() => {
    if (
      pathname.substring(pathname.lastIndexOf("/") + 1) === params.id ||
      "/"
    ) {
      navigate("AboutDoc");
    }
  }, []);
  if (loading) {
    return <h1>Loading</h1>;
  }
  return (
    <div className={styled.DoctorDetailsPage}>
      <ScheduleAnAppointmentLayoutDoctotDetails
        setScheduleAppointmentIsOpen={setScheduleAppointmentIsOpen}
      />

      <div
        className={styled.container}
        style={{
          opacity: `${scheduleAppointmentIsOpen ? "0.2" : "1"}`,
          pointerEvents: `${scheduleAppointmentIsOpen ? "none" : "all"}`,
        }}
      >
        <SnackBar setScheduleAppointmentIsOpen={setScheduleAppointmentIsOpen} />
        <NavigationDoctorDetails />
        <Outlet />
      </div>
    </div>
  );
};
