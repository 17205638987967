import { TextField } from "@mui/material";
import styled from "./Security.module.scss";
import { useContext, useEffect, useState } from "react";
import { changePassword } from "../../../../../models/AuthModels";
import { useMutation } from "react-query";
import { useService } from "../../../../../hooks";
import { tokenContext } from "../../../../../Contexts/TokenContext";

interface isEditingSecurity {
  accountinformation: boolean;
  changePassword: boolean;
}
export const SecuritySettings = () => {
  const { authServices } = useService();
  const [changePasswordMessage, setChangePasswordMessage] = useState({
    message: "",
    statusCode: 0,
  });
  const [isEditing, setIsEditing] = useState<isEditingSecurity>({
    accountinformation: false,
    changePassword: false,
  });
  const [newPassword, setNewPassword] = useState<changePassword>({
    currentPassword: "",
    newPassword: "",
  });
  const { accessToken } = useContext(tokenContext);
  const changePasswordMutate = useMutation(
    () => authServices.changePassword(newPassword, accessToken),
    {
      onSuccess: (response) => {
        const { message, statusCode } = response.data;

        setChangePasswordMessage({
          message: message,
          statusCode: statusCode,
        });
      },
    }
  );

  const handleChangePassword = () => {
    changePasswordMutate.mutate();
  };

  return (
    <section className={styled.security}>
      <section className={styled.accountInformation}>
        <div className={styled.header}>
          <h1>Account Information</h1>
        </div>
        <div className={styled.inputs}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Email"
            variant="outlined"
          />
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Phone number"
            variant="outlined"
          />
        </div>
        <div className={styled.action}>
          <button>Edit</button>
        </div>
      </section>
      <section className={styled.changePassword}>
        <div className={styled.header}>
          <h1>Security</h1>
        </div>
        <div className={styled.action}>
          {isEditing.changePassword ? (
            <>
              <p>Write your current password</p>
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="Current password"
                variant="outlined"
                value={newPassword.currentPassword}
                onChange={(event) =>
                  setNewPassword((state) => ({
                    ...state,
                    currentPassword: event.target?.value
                      ? event.target?.value
                      : "",
                  }))
                }
              />
              <p>Write your New password</p>
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="New password"
                variant="outlined"
                type="text"
                value={newPassword.newPassword}
                onChange={(event) =>
                  setNewPassword((state) => ({
                    ...state,
                    newPassword: event.target?.value ? event.target?.value : "",
                  }))
                }
              />
              <h1
                style={{
                  color:
                    changePasswordMessage.statusCode === 400 ? "red" : "green",
                }}
              >
                {changePasswordMessage.message}
              </h1>
              <div className={styled.buttons}>
                <button id={styled.next} onClick={() => handleChangePassword()}>
                  Change
                </button>
                <button
                  id={styled.cancel}
                  onClick={() =>
                    setIsEditing((state) => ({
                      ...state,
                      changePassword: false,
                    }))
                  }
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <button
              id={styled.changePassword}
              onClick={() =>
                setIsEditing((state) => ({
                  ...state,
                  changePassword: true,
                }))
              }
              style={{ width: "130px" }}
            >
              Change Password
            </button>
          )}

          {/*  */}
        </div>
      </section>
    </section>
  );
};
