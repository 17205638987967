import styled from "./Insurance.module.scss";
import PashaInsurance from "../../../../assets/images/PashaInsurance.png";
export const Insurance = () => {
  return (
    <div className={styled.Insurance}>
      <div className={styled.box}>
        <div className={styled.main}>
          <section className={styled.header}>
            <div className={styled.info}>
              <h1>Insurance Information</h1>
            </div>
          </section>
          <section className={styled.information}>
            <div className={styled.wrapper}>
              <div className={styled.image}>
                <img src={PashaInsurance} />
              </div>
              <div className={styled.description}>
                <h1>Pasha Insurance</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Dicta aut Lorem ipsum dolor sit amet, consectetur adipisicing
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
