import { Autocomplete, MenuItem, Modal, TextField } from "@mui/material";
import styled from "./EditTimesModal.module.scss";
import { Box } from "@mui/system";
import React, {
  ChangeEvent,
  EventHandler,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useService } from "../../../../../hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  GetDoctorServiceQueryKey,
  getDoctorAvailabilityForDoctorPageQueryKey,
} from "../../../../../API/QueryKeys";
import { tokenContext } from "../../../../../Contexts/TokenContext";
import { decodedToken } from "../../../../../models/TokenContextModels";
import { jwtDecode } from "jwt-decode";
import {
  CreateAvailableHoursDto,
  EditTimesDTO,
  dayOfTheWeek,
} from "../../../../../models/AvailabilityModels";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: "8px",
  padding: "12px 12px 16px 24px",
  pt: 2,
  px: 4,
  pb: 3,
  background: "#E9EFEF",
  borderRadius: "28px",
};
const daysOfTheWeek: dayOfTheWeek[] = [
  {
    day: "Sunday",
    digit: 0,
  },
  { day: "Monday", digit: 1 },
  { day: "Tuesday", digit: 2 },
  { day: "Wednesday", digit: 3 },
  { day: "Thursday", digit: 4 },
  { day: "Friday", digit: 5 },
  { day: "Saturday", digit: 6 },
];
export const EditTimesModal: React.FC<{
  isModalOpen: boolean;
  closeModal: () => void;
}> = ({ isModalOpen, closeModal }) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState("");
  const [createBody, setCreateBody] = useState<EditTimesDTO>({
    dayOfWeek: 0,
    availableHours: [],
  });
  const [timeToAdd, setTimeToAdd] = useState<CreateAvailableHoursDto>({
    minute: 0,
    hour: 0,
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "minute" || name === "hour") {
      let val = parseInt(value);
      if (val) {
        if (name === "minute" && val <= 59 && val >= 0) {
          setTimeToAdd((state) => ({ ...state, [name]: val }));
        } else if (val <= 23 && val >= 0 && name === "hour") {
          setTimeToAdd((state) => ({ ...state, [name]: val }));
        }
      } else if (value === "" || val === 0) {
        if (name === "minute") {
          setTimeToAdd((state) => ({ ...state, [name]: 0 }));
        } else if (name === "hour") {
          setTimeToAdd((state) => ({ ...state, [name]: 0 }));
        }
      }
    } else {
      setCreateBody((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const handleAddTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (
      createBody.availableHours.find(
        (time) =>
          time.hour === timeToAdd.hour && time.minute === timeToAdd.minute
      )
    ) {
      setError("You have already add this time");
      return;
    }
    setError("");
    setCreateBody((state) => ({
      ...state,
      availableHours: [...state.availableHours, timeToAdd],
    }));
    setTimeToAdd({ minute: 0, hour: 0 });
  };

  const { accessToken } = useContext(tokenContext);
  const { doctorAvailabilityServices } = useService();

  const createService = useMutation(
    () =>
      doctorAvailabilityServices.createDoctorAvailability(
        createBody,
        accessToken
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          getDoctorAvailabilityForDoctorPageQueryKey,
        ]);
        closeModal();
        setCreateBody({
          dayOfWeek: 0,
          availableHours: [],
        });
      },
    }
  );
  const handleCreateService = () => {
    createService.mutate();
  };
  const handleRemoveTime = (index: number) => {
    setCreateBody((state) => ({
      ...state,
      availableHours: state.availableHours.filter((hour, ind) => ind !== index),
    }));
  };
  const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };
  useEffect(() => {
    console.log(createBody, "cre");
  }, [createBody]);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        closeModal();
        setCreateBody({
          dayOfWeek: 0,
          availableHours: [],
        });
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <section className={styled.header}>
          <h1>Add times</h1>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </section>
        <section className={styled.inputs}>
          <form>
            <div className={styled.dayOfTheWeek}>
              <label>Select day of the week</label>
              <TextField
                id="filled-select-currency"
                select
                defaultValue={1}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  if (typeof event.target.value === "number") {
                    setCreateBody((state) => ({
                      ...state,
                      dayOfWeek: parseInt(event.target.value),
                    }));
                  }
                }}
              >
                {daysOfTheWeek.map((dayOfTheWeek) => (
                  <MenuItem key={dayOfTheWeek.day} value={dayOfTheWeek.digit}>
                    {dayOfTheWeek.day}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={styled.addHour}>
              <section className={styled.label}>
                <h1>Add Hour</h1>
              </section>
              <section className={styled.inputs}>
                <section>
                  <input
                    type="number"
                    name="hour"
                    onChange={handleChange}
                    value={`${formatTime(timeToAdd.hour)}`}
                  />

                  <label>Hour</label>
                </section>
                :
                <section>
                  <input
                    type="number"
                    name="minute"
                    onChange={handleChange}
                    value={`${formatTime(timeToAdd.minute)}`}
                  />
                  <label>Minute</label>
                </section>
              </section>
            </div>
            <div className={styled.addButton}>
              <button onClick={(event) => handleAddTime(event)}>Add</button>
            </div>
          </form>
        </section>
        <section className={styled.selectedHours}>
          <div className={styled.container}>
            <div className={styled.header}>
              <h1>Selected hours:</h1>
            </div>
            <div className={styled.hours}>
              {createBody.availableHours.map((time, index) => {
                return (
                  <div>
                    {time.hour < 10 ? `0${time.hour}` : time.hour}:
                    {time.minute < 10 ? `0${time.minute}` : time.minute}
                    <button onClick={() => handleRemoveTime(index)}>
                      <CloseIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className={styled.error}>
          <h1>{error}</h1>
        </section>
        <section className={styled.actions}>
          <div className={styled.container}>
            <button
              className={styled.close}
              onClick={() => {
                closeModal();
                setCreateBody({
                  dayOfWeek: 0,
                  availableHours: [],
                });
              }}
            >
              Close
            </button>
            <button
              className={styled.finish}
              onClick={() => {
                handleCreateService();
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
