import { Outlet, useNavigate } from "react-router-dom";
import styled from "./DoctorPage.module.scss";
import { Sidebar } from "../../components/User/DoctorPage/Sidebar/Sidebar";
import { useContext, useEffect } from "react";
import { tokenContext } from "../../Contexts/TokenContext";
import { jwtDecode } from "jwt-decode";
import { decodedToken } from "../../models/TokenContextModels";
import { Roles } from "../../Enums/RoleEnums";

export const DoctorPage = () => {
  const { accessToken } = useContext(tokenContext);
  const navigate = useNavigate();
  const decodedToken: decodedToken | null = accessToken
    ? jwtDecode(accessToken)
    : null;

  useEffect(() => {
    if (!accessToken) {
      return navigate("/Auth/SignIn/Doctor");
    } else if (decodedToken) {
      if (
        Array.isArray(
          decodedToken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ]
        ) &&
        decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ].includes(Roles.Patient)
      ) {
        navigate("/Auth/SignIn/Doctor");
      }
    }
  });

  return (
    <div className={styled.doctorPage}>
      <Sidebar />
      <Outlet />
    </div>
  );
};
