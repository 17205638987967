import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

import styled from "./RemoveProfileImage.module.scss";
import { useService } from "../../../../../hooks";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { tokenContext } from "../../../../../Contexts/TokenContext";
import { GetDoctorDetailQueryKey } from "../../../../../API/QueryKeys";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: "8px",
  padding: "12px 12px 16px 24px",
  pt: 2,
  px: 4,
  pb: 3,
  background: "#E9EFEF",
  borderRadius: "28px",
};
export const RemoveProfileImageModal: React.FC<{
  closeModal: (modalName: string) => void;
  isModalOpen: boolean;
}> = ({ closeModal, isModalOpen }) => {
  const { accessToken } = useContext(tokenContext);
  const { userServices } = useService();
  const queryClient = useQueryClient();
  const removeProfilePhoto = useMutation(
    () => userServices.removeProfileImage(accessToken),
    {
      onSuccess: () => {
        queryClient.removeQueries([GetDoctorDetailQueryKey]);
        queryClient.refetchQueries([GetDoctorDetailQueryKey]);
        closeModal("removeProfileImage");
      },
    }
  );
  const handleRemoveImage = () => {
    removeProfilePhoto.mutate();
  };
  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        closeModal("removeProfileImage");
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 560 }}>
        <section className={styled.header}>
          <h1>Remove profile picture?</h1>
          <button
            onClick={() => {
              closeModal("removeProfileImage");
            }}
          >
            <CloseIcon />
          </button>
        </section>
        <section className={styled.warning}>
          <h2>
            You will remove your profile picture. This action cannot be undone.
          </h2>
        </section>
        <section className={styled.actions}>
          <div className={styled.container}>
            <button
              className={styled.close}
              onClick={() => closeModal("removeProfileImage")}
            >
              Close
            </button>
            <button
              className={styled.finish}
              onClick={() => {
                handleRemoveImage();
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
