import { changeUserProfileImage } from "../../models/UserModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class UserService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getUserInfo(token: string) {
    return await this.get("Users/profile-info", token);
  }
  async changeProfileImage(changeProfileBody: FormData, token: string) {
    return await this.patch(
      "Users/change-profile-photo",
      changeProfileBody,
      undefined,
      token
    );
  }
  async removeProfileImage(token: string) {
    return await this.patch(
      "Users/remove-profile-photo",
      null,
      undefined,
      token
    );
  }
}
