import styled from "./FirstColumn.module.scss";
import maleDoctor from "../../../../../assets/images/maleDoctor.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getUserInfo } from "../../../../../models/UserModels";
import { Calendar } from "primereact/calendar";
import { getDoctorReportsDTO } from "../../../../../models/DoctorModels";

export const FirstColumnDoctorPage: React.FC<{
  UserInfoData: getUserInfo;
  reportsData: getDoctorReportsDTO | null;
}> = ({ UserInfoData, reportsData }) => {
  return (
    <section className={styled.firstColumn}>
      <div className={styled.welcomeDoctor}>
        <div className={styled.image}>
          <img src={maleDoctor} />
        </div>
        <div className={styled.text}>
          <h1>Welcome Dr. !</h1>
          <p>You have 7 appointments for today.</p>
        </div>
      </div>
      <div className={styled.reports}>
        <section className={styled.heading}>
          <h1>Reports</h1>
          <button>See all</button>
        </section>
        <section className={styled.timeSelection}>
          <h1>
            This month <ExpandMoreIcon />{" "}
          </h1>
        </section>
        <section className={styled.table}>
          <div className={styled.firstLine}>
            <div className={styled.box}>
              <section className={styled.about}>
                <h1>Patients</h1>
              </section>
              <section className={styled.info}>
                <div className={styled.leftSide}>
                  <b>{reportsData?.patients}</b>
                  <p>Total</p>
                </div>
                <div className={styled.rightSide}>
                  <button>See</button>
                </div>
              </section>
            </div>
            <div className={styled.box}>
              <section className={styled.about}>
                <h1>Questions</h1>
              </section>
              <section className={styled.info}>
                <div className={styled.leftSide}>
                  <b>{reportsData?.questions}</b>
                  <p>Total</p>
                </div>
                <div className={styled.rightSide}>
                  <button>See</button>
                </div>
              </section>
            </div>
          </div>
          <div className={styled.secondLine}>
            <div className={styled.box}>
              <section className={styled.about}>
                <h1>Consultations</h1>
              </section>
              <section className={styled.info}>
                <div className={styled.leftSide}>
                  <b>{reportsData?.online}</b>
                  <p>Online</p>
                </div>
                <div className={styled.rightSide}>
                  <b>{reportsData?.inPerson}</b>
                  <p>In Person</p>
                </div>
              </section>
            </div>
            <div className={styled.box}>
              <section className={styled.about}>
                <h1>Reviews</h1>
              </section>
              <section className={styled.info}>
                <div className={styled.leftSide}>
                  <b>{reportsData?.reviews}</b>
                  <div className={styled.iconContainer}></div>
                </div>
              </section>
            </div>
            <div className={styled.box}>
              <section className={styled.about}>
                <h1>Favorites</h1>
              </section>
              <section className={styled.info}>
                <div className={styled.leftSide}>
                  <b>{reportsData?.favourite}</b>
                  <div className={styled.iconContainer}></div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <div className={styled.schedule}>
        <section className={styled.header}>
          <h1>Schedule</h1>
          <button>See all</button>
        </section>
        <section className={styled.calendar}></section>
      </div>
    </section>
  );
};
