import { useParams } from "react-router-dom";
import styled from "./Resume.module.scss";
import { useService } from "../../../../hooks";
import { useEffect, useState } from "react";
import { getDoctorResumeDto } from "../../../../models/DoctorModels";
import { useQuery } from "react-query";
import { GetDoctorResumeQueryKey } from "../../../../API/QueryKeys";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const Resume = () => {
  const { id } = useParams();
  const { doctorServices } = useService();
  const token = localStorage.getItem("accessToken");
  const [doctorData, setDoctorData] = useState<
    getDoctorResumeDto | undefined
  >();
  const { data, isLoading, isSuccess } = useQuery(
    [GetDoctorResumeQueryKey, id],
    () => doctorServices.getDoctorResumeById(id ?? "", token ?? "")
  );

  useEffect(() => {
    if (isSuccess) {
      setDoctorData(data?.data);
    }
  }, [isSuccess]);
  return (
    <div className={styled.resume}>
      <div className={styled.box}>
        <div className={styled.education}>
          <div className={styled.header}>
            <h1>Education</h1>
          </div>
          <div className={styled.info}>
            <ul>
              {doctorData?.educations.length
                ? doctorData?.educations.map((education) => {
                    const startDate = new Date(education.startDate);
                    const endDate = new Date(education.endDate);

                    return (
                      <li>
                        <h1>{education.univercityDegree}</h1>
                        <h2>{education.univercity}</h2>
                        <h3>Baku,Azerbaijan</h3>
                        <h4>
                          {monthNames[startDate.getMonth()]}{" "}
                          {startDate.getFullYear()} -{" "}
                          {monthNames[endDate.getMonth()]}{" "}
                          {endDate.getFullYear()}
                        </h4>
                      </li>
                    );
                  })
                : "Məlumat yoxdu"}
            </ul>
          </div>
        </div>
        <div className={styled.experience}>
          <div className={styled.header}>
            <h1>Experience</h1>
          </div>
          <div className={styled.info}>
            <ul>
              {doctorData?.experiances.length
                ? doctorData?.experiances.map((experiance) => {
                    const startDate = new Date(experiance.startDate);
                    const endDate = new Date(experiance.endDate);
                    return (
                      <li>
                        <h1>{experiance.title}</h1>
                        <h2>{experiance.hospital}</h2>
                        <h3>{experiance.location}</h3>
                        <h4>
                          {monthNames[startDate.getMonth()]}{" "}
                          {startDate.getFullYear()} -{" "}
                          {monthNames[endDate.getMonth()]}{" "}
                          {endDate.getFullYear()}
                        </h4>
                      </li>
                    );
                  })
                : "Məlumat yoxdu"}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
