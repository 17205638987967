import { getDoctorsForDoctorsPageFilter } from "../../models/DoctorModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class DoctorService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }

  async GetDoctorsForDoctorsPage(token: null | string) {
    return await this.getAll("Doctors", token);
  }
  async GetDoctorsForDoctorsPageFilter(
    body: getDoctorsForDoctorsPageFilter,
    token: null | string
  ) {
    return await this.getFilter("Doctors/filter", body, token);
  }
  async GetDoctorsForDoctorsPagePaginate(
    token: null | string,
    pageSize: number,
    pageNumber: number
  ) {
    return await this.getPaginate("Doctors", pageSize, pageNumber, token);
  }
  async GetDoctorForDoctorPage(token: null | string) {
    return await this.getAll("Doctors/profile", token);
  }

  async getDoctorDashboardReports(token: string) {
    return await this.get(`Doctors/dashboard-reports`, token);
  }

  async getDoctorAboutById(id: string, token: string) {
    return await this.getById(`Doctors/about`, id, token);
  }
  async getDoctorResumeById(id: string, token: string) {
    return await this.getById(`Doctors/resume`, id, token);
  }
  async getFavouriteDoctor(id: string, token: string) {
    return await this.getById("Doctors/favourite", id, token);
  }
  async getDoctorDetailById(id: string, token: string) {
    return await this.getById(`Doctors/detail`, id, token);
  }
  async addFavouriteDoctor(id: string, token: string | undefined) {
    return await this.post(`Doctors/add-to-favourite/${id}`, {}, token);
  }
  async removeFavouriteDoctor(id: string, token: string | undefined) {
    return await this.delete(`Doctors/remove-from-favourite`, id, token);
  }

  async doctorRegister(body: object, token: undefined) {
    return await this.post("Doctors/register", body, token);
  }
}
