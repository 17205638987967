import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from "./Navigation.module.scss";
import arrow from "../../../../assets/icons/keyboard-arrow-down.png";
import personalCard from "../../../../assets/icons/personalcard.svg";
import documentText from "../../../../assets/icons/document-text.svg";
import briefCase from "../../../../assets/icons/briefcase.svg";
import shield from "../../../../assets/icons/shield-tick.svg";
import call from "../../../../assets/icons/call.svg";
import { useEffect, useState } from "react";
import { IconDiv } from "../../../../UI/styledComponents";
import { ButtonList, buttons } from "./ButtonList/ButtonList";

export const NavigationDoctorDetails = () => {
  const [icon, setIcon] = useState<string | null>();
  const { pathname } = useLocation();
  let index = pathname.lastIndexOf("/");
  const [windowIsOpen, setWindowIsOpen] = useState<boolean>(false);

  const currentPathname = pathname.substring(index + 1);

  const [currentButton, setCurrentButton] = useState(
    buttons.find((button) => button.navigate === currentPathname)
  );

  useEffect(() => {
    const shortedPathname = pathname.substring(pathname.lastIndexOf("/") + 1);
    switch (shortedPathname) {
      case "AboutDoc":
        setIcon(personalCard);
        break;
      case "Resume":
        setIcon(documentText);
        break;
      case "Services":
        setIcon(briefCase);
        break;
      case "Contact":
        setIcon(call);
        break;
      case "Insurance":
        setIcon(shield);
        break;
    }
    setCurrentButton(
      buttons.find((button) => button.navigate === currentPathname)
    );
  }, [pathname]);
  const navigate = useNavigate();
  const StyledCurrentIconDiv = IconDiv(typeof icon === "string" ? icon : "");
  useEffect(() => {
    console.log(windowIsOpen);
  }, [windowIsOpen]);
  return (
    <div className={styles.Navigation}>
      <div className={styles.box}>
        <ul className={styles.buttons}>
          {window.innerWidth >= 700 ? (
            <>
              <li
                className={styles.button}
                onClick={() => navigate("AboutDoc")}
              >
                <button
                  style={{
                    background: `${
                      currentPathname === "AboutDoc" ? "#cce8e8" : ""
                    }`,
                  }}
                >
                  <div></div>
                  About
                </button>
              </li>
              <li className={styles.button} onClick={() => navigate("Resume")}>
                <button
                  style={{
                    background: `${
                      currentPathname === "Resume" ? "#cce8e8" : ""
                    }`,
                  }}
                >
                  <div></div>
                  Resume
                </button>
              </li>
              <li
                className={styles.button}
                onClick={() => navigate("Services")}
              >
                <button
                  style={{
                    background: `${
                      currentPathname === "Services" ? "#cce8e8" : ""
                    }`,
                  }}
                >
                  <div></div>
                  Services
                </button>
              </li>
              <li className={styles.button} onClick={() => navigate("Contact")}>
                <button
                  style={{
                    background: `${
                      currentPathname === "Contact" ? "#cce8e8" : ""
                    }`,
                  }}
                >
                  <div></div>
                  Contact
                </button>
              </li>
              <li
                className={styles.button}
                onClick={() => navigate("Insurance")}
              >
                <button
                  style={{
                    background: `${
                      currentPathname === "Insurance" ? "#cce8e8" : ""
                    }`,
                  }}
                >
                  <div></div>
                  Insurance
                </button>
              </li>
            </>
          ) : (
            <>
              <div
                className={styles.singleButton}
                onClick={() => {
                  navigate(currentButton?.navigate ?? "");
                  setWindowIsOpen((state) => !state);
                }}
              >
                <button
                  style={{
                    background: "#cce8e8",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${currentButton?.icon})`,
                      backgroundSize: "20px",
                    }}
                  ></div>
                  <StyledCurrentIconDiv></StyledCurrentIconDiv>
                  {currentButton?.innerText}
                  <div
                    className={styles.arrow}
                    style={{
                      backgroundImage: `url(${arrow})`,
                      backgroundSize: "30px",
                    }}
                  ></div>
                </button>
              </div>
              {windowIsOpen && (
                <ButtonList
                  setWindowIsOpen={setWindowIsOpen}
                  currentPathname={currentPathname}
                />
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
