// import { jwtDecode } from 'jwt-decode';
// import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import Footer from "../../components/User/Global/Footer/Footer";
// import Header from "../../components/User/Global/Header/Header";
// import HeaderAuthorized from "../../components/User/Global/Header/HeaderAuthorized";
// import HeaderAuthorizedDoctor from "../../components/User/Global/Header/HeaderAuthorizedDoctor";
// import { tokenRoleProperty } from "../../components/Auth/AuthProvider/AuthProvider";
// import { useEffect } from "react";

// export const GlobalLayout = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   let token = localStorage.getItem("accessToken");
//   let decodedToken;

//   if (token) {
//     decodedToken = jwtDecode(token);
//   }

//   const tokenRoleProperty =
//   "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

//   switch (decodedToken[tokenRoleProperty]) {
//     case "Admin":
//       console.log("Navigating to Admin Dashboard");
//       break;
//     case "Doctor":
//       console.log("Navigating to Doctor Dashboard");
//       break;
//     case "Patient":
//       console.log("Navigating to Patient Dashboard");
//       break;
//     default:
//       console.log("Unknown role, navigating to a default route");
//       navigate('/default-route'); // Specify a default route
//   }

//   useEffect(() => {
//     if (location.pathname === "/" || location.pathname === "") {
//       navigate("/HomePage");
//     }
//   }, []);

//   return (
//     <>
//       <Header />
//       {/* <HeaderAuthorized /> */}
//       {/* <HeaderAuthorizedDoctor /> */}
//       <Outlet />
//       <Footer />
//     </>
//   );
// };

import { jwtDecode, JwtPayload } from "jwt-decode";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/User/Global/Footer/Footer";
import Header from "../../components/User/Global/Header/Header";
import HeaderAuthorized from "../../components/User/Global/Header/HeaderAuthorized";
import HeaderAuthorizedDoctor from "../../components/User/Global/Header/HeaderAuthorizedDoctor";
import { useEffect, useState } from "react";

// Define an interface that extends JwtPayload to include custom claim properties
interface CustomJwtPayload extends JwtPayload {
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"?:
    | string
    | string[];
  // Add other custom claim properties here if needed
}

export const GlobalLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [headerComponent, setHeaderComponent] = useState(<Header />);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      const decodedToken = jwtDecode<CustomJwtPayload>(token);
      const tokenRoleProperty =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

      if (typeof decodedToken[tokenRoleProperty] === "string") {
        const roles = decodedToken[tokenRoleProperty]
          .split(",")
          .map((role) => role.trim());

        if (roles.includes("Admin") || roles.includes("Patient")) {
          setHeaderComponent(<HeaderAuthorized />);
        } else if (roles.includes("Doctor")) {
          setHeaderComponent(<HeaderAuthorizedDoctor />);
        } else {
          setHeaderComponent(<Header />);
        }
      } else if (Array.isArray(decodedToken[tokenRoleProperty])) {
        const roles = decodedToken[tokenRoleProperty].map((role) =>
          role.trim()
        );

        if (roles.includes("Admin") || roles.includes("Patient")) {
          setHeaderComponent(<HeaderAuthorized />);
        } else if (roles.includes("Doctor")) {
          setHeaderComponent(<HeaderAuthorizedDoctor />);
        } else {
          setHeaderComponent(<Header />);
        }
      } else {
        setHeaderComponent(<Header />);
      }
    } else {
      // If no token found, render the default header
      setHeaderComponent(<Header />);
    }

    if (location.pathname === "/" || location.pathname === "") {
      navigate("/HomePage");
    }
  }, [location.pathname, navigate]);

  return (
    <>
      {headerComponent}
      <Outlet />
      <Footer />
    </>
  );
};
