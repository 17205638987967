import styled from "./SecondColumn.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const SecondColumnPatientsPage = () => {
  return (
    <section className={styled.secondColumn}>
      <div className={styled.header}>
        <h1>Details</h1>
      </div>
      <div className={styled.patientInfo}>
        <section className={styled.heading}>
          <h1>Patient information</h1>
        </section>
        <section className={styled.main}>
          <div className={styled.container}>
            <section className={styled.first}>
              <div>
                <section>
                  <p>Name:</p>
                </section>
                <section>
                  <h2>Musa</h2>
                </section>
              </div>
              <div>
                <section>
                  <p>Surname:</p>
                </section>
                <section>
                  <h2>Mahmudov</h2>
                </section>
              </div>
              <div>
                <section>
                  <p>Age:</p>
                </section>
                <section>
                  <h2>20</h2>
                </section>
              </div>
              <div>
                <section>
                  <p>Gender:</p>
                </section>
                <section>
                  <h2>Male</h2>
                </section>
              </div>
            </section>
            <section className={styled.second}>
              <div>
                <section>
                  <p>Email:</p>
                </section>
                <section>
                  <h2>Musa</h2>
                </section>
              </div>
              <div>
                <section>
                  <p>Phone:</p>
                </section>
                <section>
                  <h2>Mahmudov</h2>
                </section>
              </div>
              <div>
                <section>
                  <p>FIN Code:</p>
                </section>
                <section>
                  <h2>20</h2>
                </section>
              </div>
            </section>
          </div>
        </section>
        <section className={styled.button}>
          <div className={styled.container}>
            <button>Write new diagnosis</button>
          </div>
        </section>
      </div>
      <div className={styled.diagnosis}>
        <section className={styled.action}>
          <button>
            <p>Latest diagnosis</p> <KeyboardArrowDownIcon />
          </button>
        </section>
        <section className={styled.main}>
          <div className={styled.container}>
            <section className={styled.header}>
              <h1>Diagnosis</h1>
            </section>
            <section className={styled.info}>
              <div className={styled.parameter}>
                <div className={styled.left}>
                  <p>Diagnosis:</p>
                </div>
                <div className={styled.right}>
                  <p>Acute Sinusitis</p>
                </div>
              </div>
              <div className={styled.parameter}>
                <div className={styled.left}>
                  <p>Diagnosis:</p>
                </div>
                <div className={styled.right}>
                  <p>Acute Sinusitis</p>
                </div>
              </div>
              <div className={styled.parameter}>
                <div className={styled.left}>
                  <p>Details:</p>
                </div>
                <div className={styled.right}>
                  <p>
                    Acute sinusitis is a condition characterized by inflammation
                    and swelling of the sinuses, which are air-filled cavities
                    located within the bones of the face and skull. It is
                    usually caused by a viral infection, although bacterial
                    infections and allergies can also contribute to its
                    development.
                  </p>
                </div>
              </div>
              <div className={styled.parameter}>
                <div className={styled.left}>
                  <p>Treatment:</p>
                </div>
                <div className={styled.right}>
                  <section>
                    <div>
                      <p>1.</p>
                    </div>
                    <div>
                      <p>
                        Ibuprofen: Take 200-400mg every 4-6 hours as needed for
                        pain and fever relief, up to 1200mg per day.
                      </p>
                    </div>
                  </section>
                  <section>
                    <div>
                      <p>1.</p>
                    </div>
                    <div>
                      <p>
                        Ibuprofen: Take 200-400mg every 4-6 hours as needed for
                        pain and fever relief, up to 1200mg per day.
                      </p>
                    </div>
                  </section>
                  <section>
                    <div>
                      <p>1.</p>
                    </div>
                    <div>
                      <p>
                        Ibuprofen: Take 200-400mg every 4-6 hours as needed for
                        pain and fever relief, up to 1200mg per day.
                      </p>
                    </div>
                  </section>
                  <section>
                    <div>
                      <p>1.</p>
                    </div>
                    <div>
                      <p>
                        Ibuprofen: Take 200-400mg every 4-6 hours as needed for
                        pain and fever relief, up to 1200mg per day.
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
};
