import React from "react";
import { PacketsListDoctorPage } from "./PacketsList/PacketsList";
import { WorkHoursDoctorPage } from "./WorkHours/WorkHours";
import styled from "./WorkHoursAndPackets.module.scss";
import { AppointmentsSetModalIsOpen } from "../../../../../models/ModalsModels";
export const WorkHoursAndPackets: React.FC<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<
    React.SetStateAction<AppointmentsSetModalIsOpen>
  >;
}> = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <section className={styled.workHoursAndPackets}>
      <WorkHoursDoctorPage setIsModalOpen={setIsModalOpen} />
      <PacketsListDoctorPage
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </section>
  );
};
