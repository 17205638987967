import styled2 from "./VerifyAccount.module.scss";
import React, { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Box, borderColor, styled } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styledC from "styled-components";
import OTPInput from "react-otp-input";
// import OTPInput from "react-otp-input";

const StyledForm = styledC(Form)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledFieldWrapper = styledC.div`
  display: flex;
  justify-content: space-between;
//   max-width: 348px;
//   width: 100%;
  gap: 12px;
`;

const StyledErrorMessage = styledC(ErrorMessage)`
  color: red;
`;

const StyledInput = styledC.input`
//   width: 100%;
//   height: 56px;
  height: 48px;
  width: 48px;
  padding: 10px;
  background-color: #E3E9E9;
  border: 1px solid #E3E9E9;
  border-radius: 8px;
  gap: 10px;
  // border: 1px linear #79747E;

//   font-family: 'Inter', sans-serif;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 24px;
//   color: #6F7979; 
`;

const VerifyAccount: React.FC = () => {
  const spanStyle: React.CSSProperties = {
    textDecoration: "none",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#00696C",
    marginLeft: "8px",
    cursor: "pointer",
  };

  const moveBackLink: React.CSSProperties = {
    color: "#00696C",
    gap: "8px",
    padding: "10px 12px 10px 12px",
    textDecoration: "none",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    alignItems: "center",
  };

  const [otp, setOtp] = useState("");
  return (
    <div className={styled2.verify__account}>
      <div className={styled2.container}>
        <div className={styled2.verify__account__wrapper}>
          <div className={styled2.verify__account__wrapper__heading}>
            Enter the verification code
          </div>
          <div className={styled2.verify__account__description__wrapper}>
            <span className={styled2.verify__account__description}>
              We sent your number a confirmation code, please enter that code in
              the field below.
            </span>
          </div>

          {/* <div style={{display: "flex", justifyContent: "center"}}> */}
          <div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              // validationSchema={SignupSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({ errors, touched }) => (
                <StyledForm>
                  {/* <Box sx={{maxWidth: "348px"}}> */}
                  {/* <Box sx={{display: "flex", justifyContent: "center"}}> */}
                  <Box sx={{ margin: "0px auto" }}>
                    {/* <StyledFieldWrapper>
                                    <Field type="number" name="email" as={StyledInput}/>
                                    <Field type="number" name="email" as={StyledInput}/>
                                    <Field type="number" name="email" as={StyledInput}/>
                                    <Field type="number" name="email" as={StyledInput}/>
                                    <Field type="number" name="email" as={StyledInput}/>
                                    <Field type="number" name="email" as={StyledInput}/>
                                    <StyledErrorMessage name="email" />
                                </StyledFieldWrapper> */}
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      containerStyle={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      inputStyle={{
                        width: "48px",
                        height: "48px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        borderRadius: "8px",
                        margin: "0 10px",
                        border: "none",
                        backgroundColor: "#E3E9E9",
                        outlineColor: "#00696C",
                      }}
                      renderInput={(props: any) => <input {...props} />}
                      shouldAutoFocus
                      inputType="tel"
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        gap: "8px",
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19.36px",
                        color: "000",
                      }}
                    >
                      Didn’t receive the code?
                      <a style={spanStyle} href="#">
                        {" "}
                        Resend it in XX seconds.
                      </a>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%", gap: "10px" }}>
                    <a style={moveBackLink} href="../Auth/ForgotPassword">
                      Back
                    </a>
                  </Box>
                </StyledForm>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;

// import styled2 from "./ResetPassword.module.scss";
// import React, { ChangeEvent, useState } from 'react';
// import TextField from '@mui/material/TextField';
// import Grid from '@mui/material/Grid';
// import { makeStyles, createStyles } from "@mui/material";
// import { styled } from '@mui/system';

// const StyledTextField = styled(TextField)({
//     width: '2rem',
//     height: '2rem',
//     margin: '0 0.5rem',
//     borderRadius: '0.25rem',
//     textAlign: 'center',
//     fontSize: '1.5rem',
//     border: '2px solid transparent',
//     transition: 'border-color 0.3s',
//   });

//   const StyledDigitEnteredTextField = styled(TextField)({
//     borderColor: 'green',
//   });

// const ResetPassword: React.FC = () => {

//     const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));

//   const handleOtpChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
//     const newOtp = [...otp];
//     const newValue = event.target.value;
//     // Allow only digits
//     if (/^\d*$/.test(newValue) && newValue.length <= 1) {
//       newOtp[index] = newValue;
//       setOtp(newOtp);
//     }
//   };
//     return (

//         <div className={styled2.reset__password}>
//         <div className={styled2.container}>
//             <div className={styled2.reset__password__wrapper}>
//                 <div className={styled2.reset__password__wrapper__heading}>
//                     Enter the verification code
//                 </div>
//                 <div className={styled2.reset__password__description__wrapper}>
//                     <span className={styled2.reset__password__description}>We sent your number a confirmation code, please enter that code in the field below.</span>
//                 </div>

//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                     {otp.map((digit, index) => (
//                     <div key={index}>
//                         <StyledTextField
//                         type="text"
//                         variant="outlined"
//                         size="small"
//                         value={digit}
//                         onChange={handleOtpChange(index)}
//                         inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
//                         className={digit && 'digitEntered'}
//                         />
//                     </div>
//                     ))}
//                 </div>

//                 <div>
//                 </div>
//             </div>
//         </div>
//       </div>

//     );
//   };

//   export default ResetPassword;

// import styled from "./ResetPassword.module.scss";
// // import { Outlet } from "react-router-dom";

// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import styledComponent from "styled-components";
// import { Box, height, width } from "@mui/system";

// import React, { ChangeEvent, useState } from 'react';
// import TextField from '@mui/material/TextField';
// import Grid from '@mui/material/Grid';

// const resetPasswordSchema = Yup.object().shape({
//     email: Yup.string().email("Invalid email").required("Required"),
//   });

//   const StyledForm = styledComponent(Form)`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
// `;

// const StyledFieldWrapper = styledComponent.div`
//   width: 100%;
// `;

// const StyledFieldButtonWrapper = styledComponent.div`
//   // width: 454px
//   // flex: 1 1 calc(100%);
//   width: 100%;
//   display: flex;
//   justify-content: center;
// `;

// const StyledErrorMessage = styledComponent(ErrorMessage)`
//   color: red;
// `;

// const StyledButton = styledComponent.button`
//   // width: 100%;
//   cursor: pointer;
// //   width: 117px;
//   height: 40px;
//   border: none;
//   border-radius: 100px;
//   gap: 8px;
//   background-color: #00696C;
//   color: #fff;
//   padding: 10px 32px 10px 32px;
//   font-family: 'Inter', sans-serif;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: 0.1px;

//   transition: all .2s linear;
//   &:hover {
//     background-color: #cce8e8;
//     color: black;
//   }
// `;

// const StyledInput = styledComponent.input`
//   width: 100%;
//   height: 56px;
//   padding: 10px;
//   border: 1px solid #79747E;
//   border-radius: 12px;
//   gap: 10px;
//   // border: 1px linear #79747E;

//   font-family: 'Inter', sans-serif;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 24px;
//   color: #6F7979;
// `;

// const ResetPassword: React.FC = () => {
//     const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));

//     const handleOtpChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
//       const newOtp = [...otp];
//       newOtp[index] = event.target.value;
//       setOtp(newOtp);
//     };

//     return (

//         <div className={styled.reset__password}>
//         <div className={styled.container}>
//             <div className={styled.reset__password__wrapper}>
//                 <div className={styled.reset__password__wrapper__heading}>
//                     Enter the verification code
//                 </div>
//                 <div className={styled.reset__password__description__wrapper}>
//                     <span className={styled.reset__password__description}>We sent your number a confirmation code, please enter that code in the field below.</span>
//                 </div>

//                 <Grid container spacing={2} alignItems="center" justifyContent="center">
//                     {otp.map((digit, index) => (
//                     <Grid item key={index}>
//                         <TextField
//                         type="number"
//                         variant="outlined"
//                         size="small"
//                         value={digit}
//                         onChange={handleOtpChange(index)}
//                         inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
//                         />
//                     </Grid>
//                     ))}
//                 </Grid>

//                 <div>
//                 </div>
//                 {/* <Outlet /> */}
//             </div>
//         </div>
//       </div>

//     );
//   };

//   export default ResetPassword;
