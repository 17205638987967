import { AppointmentService } from "./API/Services/AppointmentService";
import { AuthService } from "./API/Services/AuthService";
import { DoctorAvailabilityService } from "./API/Services/DoctorAvailabilityService";
import { DoctorService } from "./API/Services/DoctorService";
import { doctorCategoryService } from "./API/Services/DoctorCategoryService";
import { genderService } from "./API/Services/GenderService";
import { ServicesDoctorService } from "./API/Services/ServicesDoctorService";
import { ServicesTypeService } from "./API/Services/ServicesTypeService";
import { UserService } from "./API/Services/UserService";
import { patientService } from "./API/Services/PatientService";
import { LanguageService } from "./API/Services/LanguagesService";
import { HospitalService } from "./API/Services/HospitalService";
import { experienceService } from "./API/Services/ExperienceService";
import { educationService } from "./API/Services/EducationService";
import { UniversityService } from "./API/Services/UniversityService";
import { UniversityDegreeService } from "./API/Services/UniversityDegreeService";
import { FieldOfStudyService } from "./API/Services/FieldOfStudyService";

export function useService() {
  const doctorServices = new DoctorService();
  const doctorCategoryServices = new doctorCategoryService();
  const genderServices = new genderService();
  const servicesDoctorServices = new ServicesDoctorService();
  const serviceTypeServices = new ServicesTypeService();
  const doctorAvailabilityServices = new DoctorAvailabilityService();
  const appointmentServices = new AppointmentService();
  const authServices = new AuthService();
  const userServices = new UserService();
  const patientServices = new patientService();
  const languageServices = new LanguageService();
  const hospitalServices = new HospitalService();
  const experienceServices = new experienceService();
  const educationServices = new educationService();
  const universityServices = new UniversityService();
  const universityDegreeServices = new UniversityDegreeService();
  const fieldOfStudyServices = new FieldOfStudyService();
  return {
    fieldOfStudyServices,
    universityDegreeServices,
    universityServices,
    educationServices,
    experienceServices,
    hospitalServices,
    languageServices,
    userServices,
    authServices,
    patientServices,
    doctorServices,
    doctorCategoryServices,
    genderServices,
    appointmentServices,
    servicesDoctorServices,
    serviceTypeServices,
    doctorAvailabilityServices,
  };
}
