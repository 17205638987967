import { useQuery } from "react-query";
import { useService } from "../../../../hooks";
import styled from "./Appointments.module.scss";
import { getDoctorAppointment } from "../../../../API/QueryKeys";
import { useContext, useEffect, useState } from "react";
import { tokenContext } from "../../../../Contexts/TokenContext";
import { DoctorPageAppointmentsList } from "./AppointmentsList/AppointmentsList";
import { WorkHoursAndPackets } from "./WorkHoursAndPackets/WorkHoursAndPackets";
import { useNavigate } from "react-router-dom";
import { CreatePacketModal } from "./Modals/Modals";
import { EditTimesModal } from "./Modals/EditTimesModal";
import { AppointmentsSetModalIsOpen } from "../../../../models/ModalsModels";
export const Appointments = () => {
  const { accessToken } = useContext(tokenContext);
  const { appointmentServices } = useService();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<AppointmentsSetModalIsOpen>({
    createPacket: false,
    editTimes: false,
  });
  const appointmentQuery = useQuery([getDoctorAppointment], () =>
    appointmentServices.getDoctorAppointments(accessToken)
  );

  const closeModal = () => {
    setIsModalOpen({ createPacket: false, editTimes: false });
  };
  useEffect(() => {
    if (!accessToken) {
      navigate("/Auth/SignIn/Doctor");
    }
  }, []);

  return (
    <>
      <section className={styled.appointmentsSection}>
        <DoctorPageAppointmentsList appointmentQuery={appointmentQuery} />
        <WorkHoursAndPackets
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen.editTimes}
        />
      </section>
      <CreatePacketModal
        isModalOpen={isModalOpen.createPacket}
        closeModal={closeModal}
      />
      <EditTimesModal
        isModalOpen={isModalOpen.editTimes}
        closeModal={closeModal}
      />
    </>
  );
};
