import { useLocation, useNavigate } from "react-router-dom";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import HamburgerMenu from "../../../../assets/icons/doczy-hamburger-menu.svg";
import Message from "../../../../assets/images/messages.png";
import HeartEmpty from "../../../../assets/icons/doczy-heart-empty.svg";
import Notification from "../../../../assets/images/notification.png";
import ProfileImg from "../../../../assets/icons/doczy-profile.svg";
import ArrowDown from "../../../../assets/icons/doczy-arrow-down.svg";
import XImg from "../../../../assets/icons/doczy-close.svg";
import HomeMenu from "../../../../assets/icons/doczy-home-menu.svg";
import DoctorsMenu from "../../../../assets/icons/doczy-doctor-menu.svg";
import BlogsMenu from "../../../../assets/icons/doczy-blogs.svg";
import CategoriesMenu from "../../../../assets/images/categories-menu-img.png";
import AboutUsMenu from "../../../../assets/icons/doczy-aboutus-menu.svg";
import LogoutMenu from "../../../../assets/icons/doczy-logout.svg";

import DoczyLikes from "../../../../assets/icons/doczy-heart-empty.svg";
import DoczyNotifications from "../../../../assets/icons/doczy-notification.svg";
import DoczyMessages from "../../../../assets/icons/doczy-messages.svg";

import { useService } from "../../../../hooks";
import { useMutation } from "react-query";
import { tokenContext } from "../../../../Contexts/TokenContext";

function HeaderAuthorized() {
  const {
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    setExpiration,
  } = useContext(tokenContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authServices } = useService();
  const [page, setPage] = useState<string>(pathname);
  const [isAuthPage, setIsAuthPage] = useState<boolean>(
    pathname === "/Auth/SignUp"
  );
  const [HamburgerMenuElemVisible, setHamburgerMenuElemVisible] =
    useState(false); // State for HamburgerMenuElem visibility
    const [ArrowDownElemVisible, setArrowDownElemVisible] =
    useState(false); // State for HamburgerMenuElem visibility

    const [hasScrolledToBottom, setHasScrolledToBottom] = useState<boolean>(
      false
    ); // State for tracking if scrolled to bottom

    const mutateLogout = useMutation(() => authServices.signOut(), {
      onSuccess: () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiration");
        setAccessToken(""); // Clear token from context
        setRefreshToken("");
        setExpiration("");
        const route = sessionStorage.getItem("route");
        if (route) {
          sessionStorage.removeItem("route");
          navigate(route);
        } else {
          // navigate("/DoctorPage/Dashboard");
          navigate("/Auth/SignIn/Patient");
        }
      },
    });


  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);

    document.querySelector(".header__menu__bar__authorized")?.classList.remove("active");
  };

  useEffect(() => {
    setPage(pathname);
    setIsAuthPage(pathname === "/Auth/SignUp/Patient");
    // setIsAuthPage(pathname === "/Auth/SignUp/Doctor");
  }, [pathname]);






  useEffect(() => {
    const handleScroll = () => {
      const isScrolledDown = window.scrollY > 0;
      setHasScrolledToBottom(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleHamburgerMenuElem = () => {
    setHamburgerMenuElemVisible(!HamburgerMenuElemVisible); // Toggle filter visibility
    setArrowDownElemVisible(!ArrowDownElemVisible); // Toggle filter visibility
  };

  const toggleArrowDownElemVisible = () => {
    setHamburgerMenuElemVisible(!HamburgerMenuElemVisible); // Toggle filter visibility
    setArrowDownElemVisible(!ArrowDownElemVisible); // Toggle filter visibility
  };

  const isPatientPage = pathname.includes("PatientPage");

  return (
    // <header className={`homePage_header ${isAuthPage ? "auth" : ""}`}>
    <header
      className={`homePage_header ${isPatientPage ? "active" : ""} ${isAuthPage ? "auth" : ""} ${
        hasScrolledToBottom ? "scrolledToBottom" : ""
      }`}
    >
      <div className="container">
        <div className="header__inner">
          <div className="header__logo">
            <a href="HomePage">Doczy</a>
            {/* <a onClick={() => handleClick("HomePage")}>Doczy</a> */}
          </div>
          <div className="navigation">
            <nav className="nav">
              <div className="nav__link__holder">
                <a
                  className={`nav__link ${
                    page.includes("HomePage") ? "active" : ""
                  }`}
                  onClick={() => handleClick("HomePage")}
                >
                  Home
                </a>
              </div>
              <div className="nav__link__holder">
                <a
                  className={`nav__link  ${
                    page.includes("Doctors") ? "active" : ""
                  }`}
                  // onClick={() => handleClick("Doctors/Service")}
                  onClick={() => handleClick("Doctors")}
                >
                  Doctors
                </a>
              </div>
              <div className="nav__link__holder">
                <a
                  className={`nav__link  ${
                    page.includes("AboutUs") ? "active" : ""
                  }`}
                  // onClick={() => handleClick("Doctors/Service")}
                  onClick={() => handleClick("Blogs")}
                >
                  Blogs
                </a>
              </div>
              {/* <div className="nav__link__holder">
                <a className="nav__link" href="#">
                  Categories
                </a>
              </div> */}
              <div className="nav__link__holder">
                <a
                  className={`nav__link  ${
                    page.includes("AboutUs") ? "active" : ""
                  }`}
                  // onClick={() => handleClick("Doctors/Service")}
                  onClick={() => handleClick("Blogs")}
                >
                  About Us
                </a>
              </div>
            </nav>
            <div className="header__auth__authorized">
                <div className="header__auth__actions">
                    <div className="header__auth__actions__message">
                        {/* <img src={Message} alt="" /> */}
                        <img src={DoczyMessages} alt="" />
                    </div>
                    <div className="header__auth__actions__liked">
                        {/* <img src={HeartEmpty} alt="" /> */}
                        <img src={DoczyLikes} alt="" />
                    </div>
                    <div className="header__auth__actions__notification">
                        {/* <img src={Notification} alt="" /> */}
                        <img src={DoczyNotifications} alt="" />
                    </div>
                </div>
                <div className="header__auth__profile">
                    {/* <div className="header__auth__profile__image"> */}
                    <div className="header__auth__profile__image" onClick={() => handleClick("PatientPage/Appointments")}>
                        <img src={ProfileImg} alt="" />
                    </div>
                    <div className="header__auth__profile__dropdown"  onClick={toggleArrowDownElemVisible}>
                        <img src={ArrowDown} alt="" />
                    </div>
                </div>
              {/* <button
                className="login"
                onClick={() => handleClick("Auth/SignIn/Patient")}
              >
                <a>Sign In</a>
              </button>
              <button
                className="register"
                onClick={() => handleClick("Auth/SignUp/Patient")}
              >
                <a>Sign Up</a>
              </button> */}
            </div>
          </div>
          <div className="hamburger__menu">
            <img src={HamburgerMenu} alt="" onClick={toggleHamburgerMenuElem} />
          </div>
          <div
            className={`header__menu__bar__authorized ${
              HamburgerMenuElemVisible || ArrowDownElemVisible ? "active" : ""
            }`}
          >
            <div className="header__menu__authorized__close">
              <div className="menu__closingimg__wrapper">
                <img
                  src={XImg}
                  alt=""
                  className="closingimg"
                  onClick={toggleHamburgerMenuElem}
                />
              </div>
            </div>
            <div className="header__menu__profile">
              <div className="profile__image__wrapper">
                <img src={ProfileImg} alt="" />
              </div>
              <div className="profile__fullname">
                Firstname Lastname
              </div>
              {/* <button
                className="menu__auth__login"
                onClick={() => handleClick("Auth/SignIn/Patient")}
              >
                <a>Sign In</a>
              </button>
              <button
                className="menu__auth__register"
                onClick={() => handleClick("Auth/SignUp/Patient")}
              >
                <a>Sign Up</a>
              </button> */}
            </div>


            <nav className="menu__nav first">
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("PatientPage/Appointments")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={ProfileImg} alt="" />
                  <span>Profile</span>
                </div>
              </a>
              <a
                className="menu__nav__link__holder"
                // onClick={() => handleClick("Doctors")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={HeartEmpty} alt="" />
                  <span>Saved</span>
                </div>
              </a>
            </nav>




            <nav className="menu__nav second">
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("HomePage")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={HomeMenu} alt="" />
                  <span>Home</span>
                </div>
              </a>
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("Doctors")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={DoctorsMenu} alt="" />
                  <span>Doctors</span>
                </div>
              </a>
              <a className="menu__nav__link__holder" href="#">
                <div className="menu__nav__link__holder__wrapper">
                  <img src={BlogsMenu} alt="" />
                  <span>Blogs</span>
                </div>
              </a>
              {/* <a className="menu__nav__link__holder" href="#">
                <div className="menu__nav__link__holder__wrapper">
                  <img src={CategoriesMenu} alt="" />
                  <span>Categories</span>
                </div>
              </a> */}
              <a className="menu__nav__link__holder" href="#">
                <div className="menu__nav__link__holder__wrapper">
                  <img src={AboutUsMenu} alt="" />
                  <span>About us</span>
                </div>
              </a>
            </nav>




            <nav className="menu__nav third">
              <a
                className="menu__nav__link__holder"
                // onClick={() => handleClick("HomePage")}
                onClick={() => mutateLogout.mutate()}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={LogoutMenu} alt="" />
                  <span>Log out</span>
                </div>
              </a>
            </nav>




              
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderAuthorized;