import React, { createContext } from "react";
import { tokenContextModel } from "../models/TokenContextModels";
import { useState } from "react";

type ContainerProps = {
  children: React.ReactNode;
};

const initialValue: tokenContextModel = {
  accessToken: localStorage.getItem("accessToken") ?? "",
  setAccessToken: () => "",
  refreshToken: localStorage.getItem("refreshToken") ?? "",
  setRefreshToken: () => "",
  expiration: localStorage.getItem("expiration") ?? "",
  setExpiration: () => "",
};

export const tokenContext = createContext<tokenContextModel>(initialValue);

export const TokenContextProvider = (props: ContainerProps) => {
  const [accessToken, setAccessToken] = useState<string>(
    initialValue.accessToken
  );
  const [refreshToken, setRefreshToken] = useState<string>(
    initialValue.refreshToken
  );
  const [expiration, setExpiration] = useState<string>(initialValue.expiration);

  return (
    <tokenContext.Provider
      value={{
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        expiration,
        setExpiration,
      }}
    >
      {props.children}
    </tokenContext.Provider>
  );
};
