import styled2 from "./ResetPassword.module.scss";
import React, { ChangeEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { makeStyles, createStyles, IconButton, InputAdornment } from "@mui/material";
import { Box, styled } from '@mui/system';
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import styledC from "styled-components";
import * as Yup from "yup";
import { RemoveRedEye, Visibility, VisibilityOff } from "@mui/icons-material";
import { ResetPasswordSchema } from "../../../validations/FormSchemas";

const AppTextFieldPassword = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={meta.touched && !!meta.error}
      style={{
        width: '100%',
        marginBottom: '8px',
      }}
      // onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

  const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    // gap: 20px;
  `;
  
  const StyledFieldButtonWrapper = styledC.div`
    // width: 454px
    // flex: 1 1 calc(100%);
    width: 100%;
    display: flex;
    justify-content: center;
  `;

  const StyledButton = styledC.button`
    // width: 100%;
    cursor: pointer;
    // width: 117px;
    // height: 40px;
    border: none;
    border-radius: 100px;
    gap: 8px;
    background-color: #00696C;
    color: #fff;
    padding: 10px 32px 10px 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  
    transition: all .2s linear;
    &:hover {
      background-color: #cce8e8;
      color: black;
    }
  `;
const ResetPassword: React.FC = () => {
    return (
        
        <div className={styled2.reset__password}> 
        <div className={styled2.container}>
            <div className={styled2.reset__password__wrapper}>
                <div className={styled2.reset__password__wrapper__heading}>
                    Change your password
                </div>
                <div className={styled2.reset__password__description__wrapper}>
                    <span className={styled2.reset__password__description}>Password should include number, upper case and lower case letters.</span>
                </div>

                

                <div>
                    <Formik
                        initialValues={{
                        password: "",
                        confirmPassword: "",
                        }}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={(values) => {
                        console.log(values);
                        }}
                    >
                      {({ values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched, }) => (
                        <StyledForm>
                            <Grid container>
                              <Grid item xs={12}>
                                <AppTextFieldPassword
                                  label="Password"
                                  name="password"
                                  type="password"
                                  variant="outlined"
                                  autoComplete="password"
                                  value={values.password}
                                  // onChange={handleInputChange}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <AppTextFieldPassword
                                  label="Password again"
                                  name="confirmPassword"
                                  type="password"
                                  variant="outlined"
                                  autoComplete="confirmPassword"
                                  value={values.confirmPassword}
                                  // onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>


                            <StyledFieldButtonWrapper>
                                <StyledButton type="submit">Change password</StyledButton>
                            </StyledFieldButtonWrapper>
                        </StyledForm>
                        )}
                    </Formik>
                </div>


            </div>
        </div>
      </div>
    );
  };
  
  export default ResetPassword;