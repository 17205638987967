import React, {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
} from "react";
import { useState } from "react";
import { useField } from "formik";

import styled from "styled-components";
import { Box, width } from "@mui/system";

import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

import {
  signUpDoctorModel,
  signUpDoctorModelAll,
} from "../../../../models/AuthModels";
import { useService } from "../../../../hooks";
import { useQuery } from "react-query";
import { getGendersQueryKey } from "../../../../API/QueryKeys";
import { getAllGenders } from "../../../../models/GenderModels";
import { object } from "yup";
import { SignUpDoctorContext } from "../../../../Contexts/SignUpDoctorContext";

const AppTextFieldPassword = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <TextField
      {...props}
      style={{
        width: "100%",
        marginBottom: "8px",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? "text" : "password"}
    />
  );
};

const StyledFieldButtonWrapper = styled.div`
  // width: 454px
  // flex: 1 1 calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  // width: 100%;
  cursor: pointer;
  width: 117px;
  height: 40px;
  border: none;
  border-radius: 100px;
  gap: 8px;
  background-color: #00696c;
  color: #fff;
  padding: 10px 32px 10px 32px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  transition: all 0.2s linear;
  &:hover {
    background-color: #cce8e8;
    color: black;
  }
`;

const SignUpDoctorForm: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);
  const { genderServices } = useService();
  const [isValidBody, setIsValidBody] = useState({
    firstName: true,
    lastName: true,
    email: true,
    password: true,
    confirmPassword: true,
    genderId: true,
  });
  const { signUpDoctorInfo, setSignUpDoctorInfo } =
    useContext(SignUpDoctorContext);
  const [signUpBody, setSignUpBody] = useState<signUpDoctorModel>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    genderId: "",
    confirmPassword: "",
    // userName: "",
    // idCardImageUrl: null,
    // diplomaImageUrl: null,
    // doctorCategoryId: "",
  });
  const genderQuery = useQuery([getGendersQueryKey], () =>
    genderServices.getAllGenders()
  );

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);

  type Gender = {
    id: string;
    name: string;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSignUpDoctorInfo((state) => ({
      ...state,
      [name]: value,
    }));
    setSignUpBody((state) => ({
      ...state,
      [name]: value,
    }));
  };
  useEffect(() => {
    console.log(signUpDoctorInfo);
  }, [signUpDoctorInfo]);
  const handleValidation = (
    event:
      | React.FocusEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
        >
      | React.SyntheticEvent<Element | Event>
  ) => {
    if ("target" in event) {
      if ("value" in event.target && "name" in event.target) {
        const { value, name } = event?.target;

        if (
          (value.trim().length === 0 || value.trim() === "") &&
          name !== "confirmPassword"
        ) {
          setIsValidBody((state) => ({
            ...state,
            [name]: false,
          }));
        } else if (
          (name === "confirmPassword" || name === "password") &&
          signUpBody.password !== signUpBody.confirmPassword
        ) {
          setIsValidBody((state) => ({
            ...state,
            confirmPassword: false,
          }));
          if (
            (value.trim().length > 0 || value.trim() !== "") &&
            name === "password"
          ) {
            setIsValidBody((state) => ({
              ...state,
              password: true,
            }));
          }
        } else {
          setIsValidBody((state) => ({
            ...state,
            [name]: true,
          }));
        }
      }
    }
  };
  const handleNext = () => {
    let verify = 0;
    const properties = [];

    for (const [key, value] of Object.entries(signUpBody)) {
      if (
        (value?.trim().length === 0 || value?.trim() === "") &&
        key !== "confirmPassword"
      ) {
        verify++;
        setIsValidBody((state) => ({
          ...state,
          [key]: false,
        }));
      }
    }
    for (const [key, value] of Object.entries(isValidBody)) {
      if (!value) {
        return;
      }
    }
    if (verify) {
      return;
    }
    // setSignUpDoctorInfo((state) => ({
    //   ...state,
    //   ...signUpBody,
    // }));
    navigate("VerifyIdentity");
  };

  const spanStyle: React.CSSProperties = {
    textDecoration: "none",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#00696C",
    marginLeft: "8px",
    cursor: "pointer",
  };
  if (genderQuery.isLoading) {
    return <h1>Is Loading</h1>;
  }

  return (
    <div>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label="Firstname"
              name="firstName"
              variant="outlined"
              required
              autoComplete="FirstName"
              defaultValue={signUpBody.firstName ?? ""}
              value={signUpBody.firstName}
              onChange={handleInputChange}
              onBlur={(event) => handleValidation(event)}
              error={!isValidBody.firstName}
              helperText={`${
                !isValidBody.firstName ? "First name is required" : ""
              }`}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="LastName"
              name="lastName"
              variant="outlined"
              autoComplete="LastName"
              value={signUpBody.lastName}
              defaultValue={signUpBody.lastName ?? ""}
              onChange={handleInputChange}
              error={!isValidBody.lastName}
              onBlur={handleValidation}
              helperText={`${
                !isValidBody.lastName ? "Last name is required" : ""
              }`}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            PaperComponent={({ children, ...other }) => (
              <Paper {...other}>{children}</Paper>
            )}
            id="GenderId"
            options={genderQuery.data?.data || []}
            defaultValue={
              genderQuery.data?.data.find(
                (gender: getAllGenders) => gender.id === signUpBody?.genderId
              ) ?? ""
            }
            onChange={(event, newValue: getAllGenders | null) => {
              setSignUpBody((state) => ({
                ...state,
                genderId: newValue ? newValue.id : "",
              }));
              setSignUpDoctorInfo((state) => ({
                ...state,
                genderId: newValue ? newValue.id : "",
              }));
            }}
            getOptionLabel={(gender: Gender) => (gender ? gender.name : "")}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="filled"
                label="Your gender"
                name="genderId"
                error={!isValidBody.genderId}
                onBlur={handleValidation}
                helperText={`${
                  !isValidBody.genderId ? "Gender is required" : ""
                }`}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            sx={{ width: "100%" }}
            label="Email"
            name="email"
            variant="outlined"
            autoComplete="Email"
            value={signUpBody.email}
            defaultValue={signUpBody.email ?? ""}
            onChange={handleInputChange}
            error={!isValidBody.email}
            onBlur={handleValidation}
            helperText={`${!isValidBody.email ? "Email is required" : ""}`}
          />
        </Grid>
        <Grid item xs={12}>
          <AppTextFieldPassword
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            autoComplete="Password"
            value={signUpBody.password}
            defaultValue={signUpBody.password ?? ""}
            onChange={handleInputChange}
            error={!isValidBody.password}
            onBlur={handleValidation}
            helperText={`${
              !isValidBody.password ? "Password is required" : ""
            }`}
          />
        </Grid>
        <Grid item xs={12}>
          <AppTextFieldPassword
            label="Password again"
            name="confirmPassword"
            type="password"
            variant="outlined"
            autoComplete="ConfirmPassword"
            value={signUpBody.confirmPassword}
            defaultValue={signUpBody.confirmPassword ?? ""}
            onChange={handleInputChange}
            error={!isValidBody.confirmPassword}
            onBlur={handleValidation}
            helperText={`${
              !isValidBody.confirmPassword ? "Passwords do not match" : ""
            }`}
          />
        </Grid>
      </Grid>

      <StyledFieldButtonWrapper>
        <StyledButton type="submit" onClick={() => handleNext()}>
          Next
        </StyledButton>
      </StyledFieldButtonWrapper>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            gap: "8px",
            fontFamily: "'Inter', sans-serif",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "000",
          }}
        >
          Already have an account?
          <a style={spanStyle} href="../../Auth/SignIn/Doctor">
            Sign In.
          </a>
        </Box>
      </Box>
    </div>
  );
};

export default SignUpDoctorForm;
