import styled from "./ForgotPassword.module.scss";
// import { Outlet } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import styledComponent from "styled-components";
import { Box, height, width } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { tokenContext } from "../../../Contexts/TokenContext";
import { forgotPasswordModel, tokenResponse } from "../../../models/AuthModels";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { ForgotPasswordSchema } from "../../../validations/FormSchemas";
import { Clear } from "@mui/icons-material";
import { useService } from "../../../hooks";
import { useMutation } from "react-query";

const AppTextField = (props: any) => {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";

  const handleClear = () => {
    helpers.setValue(''); // Clear the field value
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={meta.touched && !!meta.error}
      style={{
        width: '100%',
        marginBottom: '8px', // adjust as needed
        borderRadius: '12px', // apply borderRadius directly
      }}
      // onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {field.value && ( // Only show clear icon when field has value
              <IconButton onClick={handleClear} edge="end">
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

  const StyledForm = styledComponent(Form)`
  display: flex;
  flex-wrap: wrap;
  // gap: 20px;
`;

const StyledFieldButtonWrapper = styledComponent.div`
  // width: 454px
  // flex: 1 1 calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styledComponent.button`
  // width: 100%;
  cursor: pointer;
//   width: 117px;
  height: 40px;
  border: none;
  border-radius: 100px;
  gap: 8px;
  background-color: #00696C;
  color: #fff;
  padding: 10px 32px 10px 32px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  transition: all .2s linear;
  &:hover {
    background-color: #cce8e8;
    color: black;
  }
`;

export const ForgotPassword = () => {
  // const sendCodeLink: React.CSSProperties = {
  //     textDecoration: 'none',
  //     color: '#fff',
  // };
  const { setAccessToken, setRefreshToken, setExpiration } = useContext(tokenContext);
  const { authServices } = useService();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);
  };

  // State initialization and mutation
  const [forgotPasswordBody, setForgotPasswordBody] = useState<forgotPasswordModel>({
    emailOrPhoneNumber: "",
  });
  const mutate = useMutation((values: { emailOrPhoneNumber: string}) => {
    // Destructure values to get email, password, and rememberMe
    const { emailOrPhoneNumber } = values;
    
    // Call authServices.signIn with the required parameters
    return authServices.forgotPassword({
      emailOrPhoneNumber
    })
  }, {
    onSuccess: () => {
      navigate("/Auth/VerifyAccount");
      // const response: tokenResponse = tokenResponse;
      // localStorage.setItem("accessToken", response.accessToken);
      // localStorage.setItem("refreshToken", response.refreshToken);
      // localStorage.setItem("expiration", response.expiration);
      // setAccessToken(response.accessToken);
      // setRefreshToken(response.refreshToken);
      // setExpiration(response.expiration);
      // const route = sessionStorage.getItem("route");
      // if (route) {
      //   sessionStorage.clear();
      //   return navigate(route);
      // } else {
      //   navigate("/HomePage");
      // }
    },
  });

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);
    return (
      <div className={styled.forgot__password}> 
        <div className={styled.container}>
            <div className={styled.forgot__password__wrapper}>
                <div className={styled.forgot__password__wrapper__heading}>
                    Reset Password
                </div>
                <div className={styled.forgot__password__description__wrapper}>
                    <span className={styled.forgot__password__description}>Enter your email or phone number below associeted with your account. We will send you a recovery code.</span>
                </div>


                <div>
                    <Formik
                        initialValues={forgotPasswordBody}
                        validationSchema={ForgotPasswordSchema}
                        onSubmit={(values) => {mutate.mutate(values)}}
                    >
                        {({ values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched, }) => (
                        <StyledForm>
                            {/* <StyledFieldWrapper>
                                <Field type="email" name="email" placeholder="Email" as={StyledInput}/>
                                <StyledErrorMessage name="email" />
                            </StyledFieldWrapper> */}
                            <Grid container>
                              <Grid item xs={12}>
                                <AppTextField
                                  label="Email or phone number"
                                  name="emailOrPhoneNumber"
                                  variant="outlined"
                                  autoComplete="emailOrPhoneNumber"
                                  value={values.emailOrPhoneNumber}
                                  // onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>

                            <StyledFieldButtonWrapper>
                                {/* <StyledButton type="submit"onClick={() => {handleClick("../../Auth/VerifyAccount");setPage("../VerifyAccount");}}> */}
                                <StyledButton type="submit">
                                  Send code
                                  {/* <a href="../Auth/ResetPassword" style={sendCodeLink}>Send code</a> */}
                                </StyledButton>
                            </StyledFieldButtonWrapper>
                        </StyledForm>
                        )}
                    </Formik>
                </div>
                {/* <Outlet /> */}
            </div>
        </div>
      </div>
    );
};