import { Autocomplete, Modal, TextField } from "@mui/material";
import styled from "./Modals.module.scss";
import { Box } from "@mui/system";
import React, {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useService } from "../../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetDoctorServiceQueryKey,
  GetServiceTypeQueryKey,
} from "../../../../../API/QueryKeys";
import {
  createServiceDTO,
  serviceType,
} from "../../../../../models/DoctorServiceModels";
import { tokenContext } from "../../../../../Contexts/TokenContext";
import { decodedToken } from "../../../../../models/TokenContextModels";
import { jwtDecode } from "jwt-decode";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: "8px",
  padding: "12px 12px 16px 24px",
  pt: 2,
  px: 4,
  pb: 3,
  background: "#E9EFEF",
  borderRadius: "28px",
};
export const CreatePacketModal: React.FC<{
  isModalOpen: boolean;
  closeModal: () => void;
}> = ({ isModalOpen, closeModal }) => {
  const queryClient = useQueryClient();
  const [createBodyIsValid, setCreateBodyIsValid] = useState({
    name: false,
    duration: false,
    description: false,
    price: false,
    serviceTypeId: false,
  });
  const [createBody, setCreateBody] = useState<createServiceDTO>({
    name: "",
    duration: 0,
    description: "",
    price: 0,
    serviceTypeId: "",
  });
  const { accessToken } = useContext(tokenContext);
  const [servicesTypes, setServiceTypes] = useState<serviceType[] | null>(null);
  const { serviceTypeServices, servicesDoctorServices } = useService();
  const serviceTypeQuery = useQuery([GetServiceTypeQueryKey], () =>
    serviceTypeServices.getServicesType()
  );
  const decodedToken: decodedToken | null = accessToken
    ? jwtDecode(accessToken)
    : null;
  const doctorId = decodedToken
    ? decodedToken?.[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ]
    : "";

  const createService = useMutation(
    () => servicesDoctorServices.createDoctorService(createBody, accessToken),
    {
      onSuccess: () => {
        queryClient.refetchQueries([GetDoctorServiceQueryKey, doctorId]);
        closeModal();
        setCreateBody({
          name: "",
          duration: 0,
          description: "",
          price: 0,
          serviceTypeId: "",
        });
      },
    }
  );
  const handleCreateService = () => {
    if (createBody.name.trim() === "" || createBody.name === null) {
      setCreateBodyIsValid((state) => ({
        ...state,
        name: false,
      }));
    } else {
      setCreateBodyIsValid((state) => ({
        ...state,
        name: true,
      }));
    }
    if (
      createBody.description.trim() === "" ||
      createBody.description === null
    ) {
      setCreateBodyIsValid((state) => ({
        ...state,
        description: false,
      }));
    } else {
      setCreateBodyIsValid((state) => ({
        ...state,
        description: true,
      }));
    }
    if (
      createBody.serviceTypeId.trim() === "" ||
      createBody.serviceTypeId === null
    ) {
      setCreateBodyIsValid((state) => ({
        ...state,
        serviceTypeId: false,
      }));
    } else {
      setCreateBodyIsValid((state) => ({
        ...state,
        serviceTypeId: true,
      }));
    }

    if (createBody.price <= 0 || createBody.price === null) {
      setCreateBodyIsValid((state) => ({
        ...state,
        price: false,
      }));
    } else {
      setCreateBodyIsValid((state) => ({
        ...state,
        price: true,
      }));
    }
    if (createBody.duration <= 0 || createBody.duration === null) {
      setCreateBodyIsValid((state) => ({
        ...state,
        duration: false,
      }));
    } else {
      setCreateBodyIsValid((state) => ({
        ...state,
        duration: true,
      }));
    }

    createService.mutate();
  };
  useEffect(() => {
    if (serviceTypeQuery.isSuccess) {
      setServiceTypes(serviceTypeQuery.data?.data);
    }
  }, [serviceTypeQuery.isSuccess]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCreateBody((state) => ({
      ...state,
      [name]: value,
    }));
  };

  if (serviceTypeQuery.isLoading || !servicesTypes) {
    return (
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h1>Is loading</h1>
        </Box>
      </Modal>
    );
  }
  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        closeModal();
        setCreateBody({
          name: "",
          duration: 0,
          description: "",
          price: 0,
          serviceTypeId: "",
        });
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <section className={styled.header}>
          <h1>Create new packet</h1>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </section>
        <section className={styled.inputs}>
          <form>
            <TextField
              id="outlined-basic"
              label="Packet name"
              variant="outlined"
              name="name"
              required
              onChange={handleChange}
              error={!createBodyIsValid.name}
              helperText={
                !createBodyIsValid.name
                  ? "Name's length has to be minimum 3"
                  : ""
              }
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={servicesTypes || []}
              getOptionLabel={(option: serviceType) => option.name}
              onChange={(event: SyntheticEvent, newValue: serviceType | null) =>
                setCreateBody((state) => ({
                  ...state,
                  serviceTypeId: newValue ? newValue?.id : "",
                }))
              }
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Packet type"
                  error={!createBodyIsValid.serviceTypeId}
                  helperText={
                    !createBodyIsValid.serviceTypeId
                      ? "Packet type is required"
                      : ""
                  }
                />
              )}
            />
            <div>
              <TextField
                required
                id="outlined-basic"
                label="Duration"
                variant="outlined"
                name="duration"
                type="number"
                onChange={handleChange}
                error={!createBodyIsValid.duration}
                helperText={
                  !createBodyIsValid.duration
                    ? "Duration has to be more than 0"
                    : ""
                }
              />
              <TextField
                required
                id="outlined-basic"
                label="Price"
                type="number"
                variant="outlined"
                name="price"
                onChange={handleChange}
                error={!createBodyIsValid.price}
                helperText={
                  !createBodyIsValid.price ? "Price has to be more than 0" : ""
                }
              />
            </div>
            <TextField
              required
              id="outlined-basic"
              label="Packet description"
              variant="outlined"
              name="description"
              onChange={handleChange}
              error={!createBodyIsValid.description}
              helperText={
                !createBodyIsValid.description ? "Description is required" : ""
              }
            />
          </form>
        </section>
        <section className={styled.actions}>
          <div className={styled.container}>
            <button
              className={styled.close}
              onClick={() => {
                closeModal();
                setCreateBody({
                  name: "",
                  duration: 0,
                  description: "",
                  price: 0,
                  serviceTypeId: "",
                });
              }}
            >
              Close
            </button>
            <button
              className={styled.finish}
              onClick={() => {
                handleCreateService();
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
