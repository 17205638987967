import { ChangeEvent, useContext, useEffect, useState } from "react";
import styled from "./PaymentDoctorDetails.module.scss";
import { ScheduleAnAppointmentContext } from "../../../../../Contexts/ScheduleAppointment-Context";
import { packets } from "../Service/ServiceDoctorDetails";
export const PaymentDoctorDetails = () => {
  const { appointment, setAppointment } = useContext(
    ScheduleAnAppointmentContext
  );
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAppointment((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const [packet, setPacket] = useState(
    packets.find((packet) => packet.id === appointment.serviceId)
  );

  return (
    <>
      <section className={styled.priceDetails}>
        <div className={styled.header}>
          <h1>Price</h1>
        </div>
        <div className={styled.details}>
          <div className={styled.box}>
            <div>
              <h1>{packet?.duration}</h1>
            </div>
            <div>
              <ul>
                <li>
                  <p>Subtotal</p>
                  <h1>{packet?.price}</h1>
                </li>
                <li>
                  <p>Discount</p>
                  <h1>-</h1>
                </li>
                <li>
                  <p>Service fee</p>
                  <h1>{parseInt(packet?.price ?? "") * 0.25}$</h1>
                </li>
              </ul>
            </div>
            <div className={styled.total}>
              <p>Total</p>
              <h1>{parseInt(packet?.price ?? "") * 1.25}$</h1>
            </div>
          </div>
        </div>
      </section>
      <section className={styled.cardDetails}>
        <div className={styled.header}>
          <h1>Card details</h1>
        </div>
        <div className={styled.inputs}>
          <form>
            {/* <div className={styled.firstLine}>
              <input
                placeholder="Card Number*"
                name="cardNumber"
                value={appointment.cardNumber ?? ""}
                onChange={handleInputChange}
              />
            </div>
            <div className={styled.secondLine}>
              <input
                placeholder="Name Surname*"
                name="cardNameSurname"
                onChange={handleInputChange}
                value={appointment.cardNameSurname ?? ""}
              />
            </div>
            <div className={styled.thirdLine}>
              <input
                placeholder="MM / YY*"
                onChange={handleInputChange}
                value={appointment.MMYY ?? ""}
                name="MMYY"
              />
              <input
                placeholder="CVC/CVV*"
                onChange={handleInputChange}
                type="number"
                name="CVV"
                value={appointment.CVV ?? ""}
              />
            </div> */}
          </form>
        </div>
      </section>
    </>
  );
};
