import { scheduleAppointment } from "../../models/AppointmentModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class patientService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async patientRegister(body: object, token: undefined) {
    return await this.post("Patients/register", body, token);
  }
//   async signIn(body: object, token: undefined) {
//     return await this.post("Authentications/login", body, token);
//   }
//   async getDoctorAppointments(token: string | null) {
//     return await this.getAll("Appointments/doctor", token);
//   }
}