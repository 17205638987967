import { Route, Routes } from "react-router-dom";
import { ScheduleAnAppointmentContextProvider } from "../Contexts/ScheduleAppointment-Context";
import { GlobalLayout } from "../layouts/Global/GlobalLayout";
import { HomePage } from "../pages/HomePage/HomePage";
import { BlogsPage } from "../pages/BlogsPage/BlogsPage";
import { DoctorsPage } from "../pages/DoctorsPage/DoctorsPage";
import { Service } from "../components/User/DoctorsPage/ScheduleAnAppointment/Service/Service";
import { Review } from "../components/User/DoctorsPage/ScheduleAnAppointment/Review/Review";
import { Payment } from "../components/User/DoctorsPage/ScheduleAnAppointment/Payment/Payment";
import { Confirm } from "../components/User/DoctorsPage/ScheduleAnAppointment/Confirm/Confirm";
import { DoctorDetailsPage } from "../pages/DoctorDetailsPage/DoctorDetailsPage";
import { AboutDoc } from "../components/User/DoctorDetailsPage/AboutDoc/AboutDoc";
import { Resume } from "../components/User/DoctorDetailsPage/Resume/Resume";
import { Services } from "../components/User/DoctorDetailsPage/Services/Services";
import { Contact } from "../components/User/DoctorDetailsPage/Contact/Contact";
import { Insurance } from "../components/User/DoctorDetailsPage/Insurance/Insurance";
import { AuthLayout } from "../layouts/Auth/AuthLayout";
import { GlobalDoctorLayout } from "../layouts/Global/GlobalDoctorLayout";
import { SignUp } from "../pages/Auth/SignUp/SignUp";
import SignUpPatientForm from "../components/Auth/SignUp/SignUpForms/SignUpPatientForm";
import SignUpDoctorForm from "../components/Auth/SignUp/SignUpForms/SignUpDoctorForm";
import { SignIn } from "../pages/Auth/SignIn/SignIn";
import SignInPatientForm from "../components/Auth/SignIn/SignInForms/SignInPatientFrom";
import SignInDoctorForm from "../components/Auth/SignIn/SignInForms/SignInDoctorFrom";
import { ForgotPassword } from "../pages/Auth/ForgotPassword/ForgotPassword";
import { DateSection } from "../components/User/DoctorsPage/ScheduleAnAppointment/Date/Date";
import ResetPassword from "../pages/Auth/ResetPassword/ResetPassword";
import VerifyAccount from "../pages/Auth/VerifyAccount/VerifyAccount";
import { VerifyEmail } from "../pages/Auth/VerifyEmail/VerifyEmail";
import { VerifyEmailMessage } from "../pages/Auth/VerifyEmailMessage/VerifyEmailMessage";
import { VerifyIdentity } from "../pages/Auth/VerifyIdentity/VerifyIdentity";
import { DoctorPage } from "../pages/DoctorPage/DoctorPage";
import { PatientPage } from "../pages/PatientPage/PatientPage";
import { Dashboard } from "../components/User/DoctorPage/Dashboard/Dashboard";
import { Appointments } from "../components/User/DoctorPage/Appointments/Appointments";
import { AppointmentsPatient } from "../components/User/PatientPage/Appointments/Appointments";
import { Patients } from "../components/User/DoctorPage/Patients/Patients";
import { Reports } from "../components/User/DoctorPage/Reports/Reports";
import { DoctorPageSettings } from "../components/User/DoctorPage/Settings/Settings";
import { BasicInformationSettings } from "../components/User/DoctorPage/Settings/BasicInformation/BasicInformation";
import { ProfileSettings } from "../components/User/DoctorPage/Settings/Profile/Profile";
import { SocialLinksSettings } from "../components/User/DoctorPage/Settings/SocialLinks/SocialLinks";
import { SecuritySettings } from "../components/User/DoctorPage/Settings/Security/Security";
import {
  SignUpDoctorContext,
  SignUpDoctorContextProvider,
} from "../Contexts/SignUpDoctorContext";
import { AboutUsPage } from "../pages/AboutUsPage/AboutUsPage";

export const MainRoutes = () => {
  return (
    <SignUpDoctorContextProvider>
      <ScheduleAnAppointmentContextProvider>
        <Routes>
          <Route path="/" element={<GlobalLayout />}>
            <Route path="/HomePage" element={<HomePage />}></Route>
            <Route path="/Doctors" element={<DoctorsPage />}>
              <Route path="/Doctors/Service" element={<Service />} />
              <Route path="/Doctors/Date" element={<DateSection />} />
              <Route path="/Doctors/Review" element={<Review />} />
              <Route path="/Doctors/Payment" element={<Payment />} />
              <Route path="/Doctors/Confirm" element={<Confirm />} />
            </Route>

            <Route path="/Blogs" element={<BlogsPage />}></Route>
            <Route path="/AboutUs" element={<AboutUsPage />}></Route>

            {/* <Route path="/DoctorPage" element={<DoctorPage />}>
            <Route path="/DoctorPage/Dashboard" element={<Dashboard />}></Route>
            <Route
              path="/DoctorPage/Appointments"
              element={<Appointments />}
            ></Route>
          </Route> */}


            <Route path="/PatientPage" element={<PatientPage />}>
              {/* <Route
                path="/PatientPage/Dashboard"
                element={<Dashboard />}
              ></Route> */}
              <Route
                path="/PatientPage/Appointments"
                element={<AppointmentsPatient />}
              ></Route>
              <Route
                path="/PatientPage/Settings"
                element={<DoctorPageSettings />}
              >
                <Route
                  path="/PatientPage/Settings/BasicInformation"
                  element={<BasicInformationSettings />}
                ></Route>
                <Route
                  path="/PatientPage/Settings/Profile"
                  element={<ProfileSettings />}
                ></Route>
                <Route
                  path="/PatientPage/Settings/SocialLinks"
                  element={<SocialLinksSettings />}
                ></Route>
                <Route
                  path="/PatientPage/Settings/Security"
                  element={<SecuritySettings />}
                ></Route>
              </Route>
              {/* <Route path="/PatientPage/Reports" element={<Reports />}></Route>
              <Route path="/PatientPage/Patients" element={<Patients />}></Route> */}
            </Route>






            <Route path="/Doctors/:id" element={<DoctorDetailsPage />}>
              <Route
                path="/Doctors/:id/AboutDoc"
                element={<AboutDoc />}
              ></Route>
              <Route path="/Doctors/:id/Resume" element={<Resume />}></Route>
              <Route
                path="/Doctors/:id/Services"
                element={<Services />}
              ></Route>
              <Route path="/Doctors/:id/Contact" element={<Contact />}></Route>
              <Route
                path="/Doctors/:id/Insurance"
                element={<Insurance />}
              ></Route>
            </Route>
          </Route>

          <Route path="/" element={<GlobalDoctorLayout />}>
            <Route path="/DoctorPage" element={<DoctorPage />}>
              <Route
                path="/DoctorPage/Dashboard"
                element={<Dashboard />}
              ></Route>
              <Route
                path="/DoctorPage/Appointments"
                element={<Appointments />}
              ></Route>
              <Route
                path="/DoctorPage/Settings"
                element={<DoctorPageSettings />}
              >
                <Route
                  path="/DoctorPage/Settings/BasicInformation"
                  element={<BasicInformationSettings />}
                ></Route>
                <Route
                  path="/DoctorPage/Settings/Profile"
                  element={<ProfileSettings />}
                ></Route>
                <Route
                  path="/DoctorPage/Settings/SocialLinks"
                  element={<SocialLinksSettings />}
                ></Route>
                <Route
                  path="/DoctorPage/Settings/Security"
                  element={<SecuritySettings />}
                ></Route>
              </Route>
              <Route path="/DoctorPage/Reports" element={<Reports />}></Route>
              <Route path="/DoctorPage/Patients" element={<Patients />}></Route>
            </Route>
          </Route>
          
          <Route path="/" element={<AuthLayout />}>
            <Route path="/Auth/SignUp" element={<SignUp />}>
              <Route
                path="/Auth/SignUp/Patient"
                element={<SignUpPatientForm />}
              ></Route>
              <Route
                path="/Auth/SignUp/Doctor"
                element={<SignUpDoctorForm />}
              ></Route>
            </Route>
            <Route
              path="/Auth/SignUp/Doctor/VerifyIdentity"
              element={<VerifyIdentity />}
            ></Route>
            <Route path="/Auth/SignIn" element={<SignIn />}>
              <Route
                path="/Auth/SignIn/Patient"
                element={<SignInPatientForm />}
              ></Route>
              <Route
                path="/Auth/SignIn/Doctor"
                element={<SignInDoctorForm />}
              ></Route>
            </Route>
            <Route
              path="/Auth/ForgotPassword"
              element={<ForgotPassword />}
            ></Route>
            <Route
              path="/Auth/VerifyAccount"
              element={<VerifyAccount />}
            ></Route>
            <Route
              path="/Auth/ResetPassword"
              element={<ResetPassword />}
            ></Route>
            <Route path="/Auth/VerifyEmail" element={<VerifyEmail />}></Route>
            <Route
              path="/Auth/VerifyEmailMessage"
              element={<VerifyEmailMessage />}
            ></Route>
          </Route>
        </Routes>
      </ScheduleAnAppointmentContextProvider>
    </SignUpDoctorContextProvider>
  );
};
