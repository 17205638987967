import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import styled from "./PatientStatistics.module.scss";
import { getDoctorReportsDTO } from "../../../../../models/DoctorModels";
export const PatientStatisticsReportsPage: React.FC<{
  reportsDTO: getDoctorReportsDTO;
}> = ({ reportsDTO }) => {
  return (
    <section className={styled.patientStatistics}>
      <div className={styled.container}>
        <section className={styled.header}>
          <div className={styled.leftSide}>
            <h1>Patient statistics</h1>
          </div>
          <div className={styled.rightSide}>
            <button>
              <p>This month</p>
              <KeyboardArrowDown />
            </button>
          </div>
        </section>
        <section className={styled.patientsTotal}>
          <div className={styled.name}>
            <h1>Patients</h1>
          </div>
          <div className={styled.statistics}>
            <section className={styled.left}>
              <h1>{reportsDTO.patients}</h1>
              <p>Total</p>
            </section>
            <section className={styled.right}>
              <button>See</button>
            </section>
          </div>
        </section>
        <section className={styled.consultations}>
          <div className={styled.name}>
            <h1>Consultations</h1>
          </div>
          <div className={styled.statistics}>
            <section className={styled.left}>
              <h1>{reportsDTO.online}</h1>
              <p>Online</p>
              <div className={styled.icon}></div>
            </section>
            <section className={styled.right}>
              <h1>{reportsDTO.inPerson}</h1>
              <p>In Person</p>
              <div className={styled.icon}></div>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
};
