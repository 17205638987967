import styled from "./ConfirmDoctorDetails.module.scss";
import stethoscope from "../../../../../assets/icons/stethoscope-2.svg";
import pc from "../../../../../assets/icons/monitor-mobbile.svg";
import videoTime from "../../../../../assets/icons/video-time.svg";
import calendar from "../../../../../assets/icons/calendar-tick.svg";
import clock from "../../../../../assets/icons/clock.svg";
import location from "../../../../../assets/icons/location.svg";

const details = [
  {
    header: "Packet",
    icon: stethoscope,
    value: "Packet Name",
  },
  {
    header: "Type",
    icon: pc,
    value: "Online",
  },
  {
    header: "Price and Duration",
    icon: calendar,
    value: "xxxx",
  },
  {
    header: "Date and Time",
    icon: videoTime,
    secondIcon: clock,
    value: "January 17 10:00",
  },
  {
    header: "Location",
    icon: location,
    value: "Azerbaijan Bakue",
  },
];
export const ConfirmDoctorDetails = () => {
  return (
    <>
      <section className={styled.status}>
        <div className={styled.header}>
          <h1>Payment Status</h1>
        </div>
        <div className={styled.paymentStatus}>
          <div className={styled.icon}></div>
          <div className={styled.info}>
            <h1>Payment completed</h1>
          </div>
        </div>
      </section>
      <section className={styled.appointmentDetails}>
        <div className={styled.header}>
          <h1>Appointment details</h1>
        </div>
        <div className={styled.details}>
          {details.map((detail) => {
            return (
              <div className={styled.box}>
                <div className={styled.header}>
                  <h1>{detail.header}</h1>
                </div>
                <div className={styled.packetName}>
                  <div
                    className={styled.icon}
                    style={{
                      backgroundImage: `url(${detail.icon})`,

                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  {detail.secondIcon && (
                    <div
                      className={styled.icon}
                      style={{
                        backgroundImage: `url(${detail.secondIcon})`,

                        width: "20px",
                        height: "20px",
                      }}
                    ></div>
                  )}
                  <div className={styled.value}>
                    <h1>{detail.value}</h1>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
