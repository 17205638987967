import React from "react";
import frame from "../../../../../assets/icons/Frame 427319134.svg";
import styled from "./NavigatorDoctorDetails.module.scss";
import { scheduleAppointment } from "../../../../../models/AppointmentModels";

const navigates = [
  {
    content: "Service",
    stageNumber: 1,
    requiredProperties: ["appointmentTypeId", "packetId"],
  },
  {
    content: "Date",
    stageNumber: 2,
    requiredProperties: ["date", "time"],
  },
  {
    content: "Review",
    stageNumber: 3,
    requiredProperties: [],
  },
  {
    content: "Payment",
    stageNumber: 4,
    requiredProperties: ["cardNumber", "cardNameSurname", "MMYY", "CVV"],
  },
  {
    content: "Confirm",
    stageNumber: 5,
  },
];
export const Navigator: React.FC<{
  appointment: scheduleAppointment;
  currentPage: number;
}> = ({ appointment, currentPage }) => {
  return (
    <section className={styled.navigator}>
      <div className={styled.content}>
        <ul>
          {navigates.map((navigate) => {
            let isActive;

            if (navigate && navigate.requiredProperties) {
              isActive =
                currentPage > navigate.stageNumber &&
                navigate.content !== "Confirm" &&
                navigate.requiredProperties.every((property) => {
                  return appointment[property as keyof scheduleAppointment];
                });
            } else {
              isActive =
                currentPage > navigate.stageNumber &&
                navigate.content !== "Confirm";
            }

            const stageContent = isActive ? (
              <img src={frame} />
            ) : (
              navigate.stageNumber
            );

            return (
              <li>
                <div className={styled.stageNumber}>
                  <h1>{stageContent}</h1>
                </div>
                <div className={styled.stageName}>
                  <h2>{navigate.content}</h2>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
