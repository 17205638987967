import { ChangeEvent, useContext, useEffect, useState } from "react";
import styled from "./Payment.module.scss";
import { ScheduleAnAppointmentContext } from "../../../../../Contexts/ScheduleAppointment-Context";
import { packets } from "../Service/Service";
import { useMutation } from "react-query";
import { useService } from "../../../../../hooks";
import { CircularProgress } from "@mui/material";

const BANK_URL_HEADER = "https://kapitalbank.az";

export const Payment = () => {
  const { appointmentServices } = useService();
  const { appointment, setAppointment, doctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const [urlResponse, setUrlResponse] = useState<string>("");
  const mutate = useMutation(
    () =>
      appointmentServices.createAppointment(
        appointment,
        localStorage.getItem("accessToken") ?? ""
      ),
    {
      onSuccess: (response) => {
        setUrlResponse(response.data.message);
        // window.location.href = response.data.message;
        window.open(response.data.message, "_self", "noopener noreferrer");
      },
    }
  );
  useEffect(() => {
    sessionStorage.removeItem("doctorInfo");
    sessionStorage.removeItem("appointmentInfo");
    sessionStorage.setItem("doctorInfo", JSON.stringify(doctorInfo));
    sessionStorage.setItem("appointmentInfo", JSON.stringify(appointment));

    mutate.mutate();
  }, []);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAppointment((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const [packet, setPacket] = useState(
    packets.find((packet) => packet.id === appointment.serviceId)
  );

  if (mutate.isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      {/* <div>
        <iframe
          src={urlResponse}
          // allow={BANK_URL_HEADER}
          // ALLOW-FROM={BANK_URL_HEADER}
          style={{
            width: "510px",
            height: "740px",
            padding: "0px",
          }}
        ></iframe>
      </div> */}
    </>
  );
};
