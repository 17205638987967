import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class HospitalService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getAllHospital() {
    return await this.get("Hospitals", null);
  }
}
