import styled from "./Snackbar.module.scss";
import facebook from "../../../../assets/images/facebook.png";
import instagram from "../../../../assets/images/instagram.png";
import twitter from "../../../../assets/images/twitter.png";
import Heart from "../../../../assets/images/heart.png";
import HeartEmpty from "../../../../assets/images/heart-img-empty.png";
import { useNavigate, useParams } from "react-router-dom";
import likedHeart from "../../../../assets/images/heart.png";
import emptyHeart from "../../../../assets/images/heart-img-empty.png";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ScheduleAnAppointmentContext } from "../../../../Contexts/ScheduleAppointment-Context";
import { useService } from "../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetDoctorDetailQueryKey,
  GetDoctorFavouriteQueryKey,
} from "../../../../API/QueryKeys";
import { GetDoctorDetail } from "../../../../models/DoctorModels";
import {
  DoctorProfileImagesRoot,
  HospitalIconsRoot,
  MainURL,
} from "../../../../API/URLs";
import dayjs from "dayjs";
import { doctorDetailsLoadingContext } from "../../../../Contexts/DoctorDetailsLoadingContext";
import { tokenContext } from "../../../../Contexts/TokenContext";

export const SnackBar: React.FC<{
  setScheduleAppointmentIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setScheduleAppointmentIsOpen }) => {
  const { setLoading } = useContext(doctorDetailsLoadingContext);
  let scheduleAnAppointment = document.getElementById(
    "scheduleAppointmentDoctorDetails"
  );
  const { accessToken } = useContext(tokenContext);
  const { setAppointment, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const { doctorServices } = useService();
  const [doctorData, setDoctorData] = useState<GetDoctorDetail | undefined>();
  const doctorDataQuery = useQuery([GetDoctorDetailQueryKey, id], () =>
    doctorServices.getDoctorDetailById(id ?? "", accessToken)
  );
  const doctorFavouriteQuery = useQuery([GetDoctorFavouriteQueryKey, id], () =>
    doctorServices.getFavouriteDoctor(id ?? "", accessToken)
  );

  const addFavouriteDoctor = useMutation(() =>
    doctorServices.addFavouriteDoctor(doctorData?.id ?? "", accessToken)
  );
  const removeFavouriteDoctor = useMutation(() =>
    doctorServices.removeFavouriteDoctor(doctorData?.id ?? "", accessToken)
  );
  useEffect(() => {
    scheduleAnAppointment = document.getElementById(
      "scheduleAppointmentDoctorDetails"
    );
  }, []);
  useEffect(() => {
    if (doctorDataQuery.isSuccess) {
      setDoctorData(doctorDataQuery.data?.data);
    }
  }, [doctorDataQuery.isSuccess]);
  useEffect(() => {
    if (doctorFavouriteQuery.isSuccess) {
      setDoctorData((state: GetDoctorDetail | undefined) =>
        state
          ? {
              ...state,
              isFavourite: doctorFavouriteQuery.data?.data.isFavourite,
              favourite: doctorFavouriteQuery.data?.data.favourite,
            }
          : undefined
      );
    }
  }, [doctorFavouriteQuery.isSuccess]);
  const addFavouriteHandler = () => {
    if (!accessToken) {
      sessionStorage.setItem("route", `/Doctors/${id}`);
      return navigate("/Auth/SignIn/Patient");
    }
    addFavouriteDoctor.mutate();
  };
  const removeFavouriteHandler = () => {
    if (!accessToken) {
      sessionStorage.setItem("route", `/Doctors/${id}`);
      return navigate("/Auth/SignIn/Patient");
    }
    removeFavouriteDoctor.mutate();
  };
  useEffect(() => {
    if (removeFavouriteDoctor.isSuccess || addFavouriteDoctor.isSuccess) {
      queryClient.removeQueries([GetDoctorFavouriteQueryKey, id]);
      doctorFavouriteQuery.refetch();
    }
  }, [removeFavouriteDoctor.isSuccess, addFavouriteDoctor.isSuccess]);

  useEffect(() => {
    if (doctorDataQuery.isLoading || doctorFavouriteQuery.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [doctorDataQuery.isLoading, doctorFavouriteQuery]);

  return (
    <section className={styled.snackbar}>
      <div className={styled.wrapper}>
        <div className={styled.left}>
          <div className={styled.box}>
            <div className={styled.firstLine}>
              <div className={styled.leftSide}>
                <div className={styled.image}>
                  <img
                    src={`${MainURL}/${DoctorProfileImagesRoot}/${doctorData?.profileImageUrl}`}
                  />
                </div>

                <div className={styled.info}>
                  <h1>
                    {doctorData?.firstName} {doctorData?.lastName}{" "}
                  </h1>
                  <h2>{doctorData?.categoryName}</h2>

                  {doctorData?.rating && (
                    <p>
                      <button className={styled.svgContainer}></button>
                      <span>{doctorData?.rating}</span> ({doctorData?.reviews}{" "}
                      reviews)
                    </p>
                  )}
                </div>
              </div>
              <div className={styled.rightSide}>
                <button></button>

                <button
                  disabled={
                    removeFavouriteDoctor.isLoading ||
                    addFavouriteDoctor.isLoading
                  }
                >
                  {doctorData?.isFavourite ? (
                    <img src={likedHeart} onClick={removeFavouriteHandler} />
                  ) : (
                    <img src={emptyHeart} onClick={addFavouriteHandler} />
                  )}
                  <span>{doctorData?.favourite}</span>
                </button>
              </div>
            </div>
            <div className={styled.secondLine}>
              <button
                onClick={() => {
                  if (scheduleAnAppointment) {
                    scheduleAnAppointment.style.right = "0px";
                  }
                  setScheduleAppointmentIsOpen(true);
                  setDoctorInfo(() => ({
                    doctorFirstName: `${
                      doctorData ? doctorData.firstName : ""
                    }`,
                    doctorIsFavourite: `${
                      doctorData ? doctorData.isFavourite : ""
                    }`,
                    doctorLastName: `${doctorData ? doctorData.lastName : ""}`,
                    doctorCategory: `${
                      doctorData ? doctorData.categoryName : ""
                    }`,
                    doctorImage: doctorData ? doctorData.profileImageUrl : "",
                    categoryName: doctorData
                      ? doctorData.categoryName
                      : "" ?? "No category",
                    appointmentTypeId: "1",
                    packetName: "",
                    typeName: "",
                    packetPrice: "",
                    packetDuration: "",
                    appoinmentTime: "",
                    doctorRating: doctorData?.rating
                      ? doctorData?.rating
                      : null,
                  }));
                  setAppointment(() => ({
                    doctorId: doctorData ? doctorData.id : "",
                    serviceId: "1",
                    chosenDate: "",

                    chosenHourId: "",
                    // permission: false,
                    painDescription: "",
                    // cardNumber: "",
                    // cardNameSurname: "",
                    // MMYY: "",
                    // CVV: null,
                  }));
                }}
              >
                Appointment
              </button>
              <button>Message</button>
            </div>
          </div>
        </div>
        <div className={styled.right}>
          <div className={styled.box}>
            <div className={styled.workplace}>
              <div className={styled.firstLine}>
                <div className={styled.leftSide}>
                  <p>Workplace:</p>
                  <h1>
                    {doctorData?.hospital ? (
                      <button></button>
                    ) : (
                      <>
                        <button></button> Məlumat yoxdu
                      </>
                    )}
                    {doctorData?.hospital?.name}
                  </h1>
                </div>
                <div className={styled.rightSide}>
                  {doctorData?.hospital ? (
                    <img
                      src={`${MainURL}/${HospitalIconsRoot}/${doctorData?.hospital.iconUrl}`}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={styled.secondLine}>
                <p>Earliest available:</p>
                <h1>
                  <button></button>{" "}
                  {doctorData?.earliestAvailable !== "0001-01-01T00:00:00"
                    ? dayjs(doctorData?.earliestAvailable).format("DD/MM/YYYY")
                    : "Məlumat yoxdu"}{" "}
                </h1>
              </div>
            </div>
            <div className={styled.socialMedia}>
              <div className={styled.social__media__item}>
                <a
                  href="https://www.youtube.com/watch?v=DRYzImN_bDM"
                  className={styled.social__media__item__link}
                >
                  <img src={facebook} alt="" />
                </a>
              </div>
              <div className={styled.social__media__item}>
                <a
                  href="https://www.youtube.com/watch?v=DRYzImN_bDM"
                  className={styled.social__media__item__link}
                >
                  <img src={instagram} alt="" />
                </a>
              </div>
              <div className={styled.social__media__item}>
                <a
                  href="https://www.youtube.com/watch?v=DRYzImN_bDM"
                  className={styled.social__media__item__link}
                >
                  <img src={twitter} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
