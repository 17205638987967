// import TickContainerHolder from "../../../../assets/images/tick-container-unchecked.png";
// import TickContainerChecked from "../../../../assets/images/tick-container-checked.png";
// import XContainerChecked from "../../../../assets/images/x-container.png";
// import KeyboardDown from "../../../../assets/images/keyboard-arrow-down.png";
// import SearchBar from "../../../../assets/images/serach-bar.png";
// import Filter from "../../../../assets/images/filter.png";
// import Star from "../../../../assets/images/star.png";
// import LikedHeart from "../../../../assets/images/heart.png";
// import EmptyHeart from "../../../../assets/images/heart-img-empty.png";
// import Message from "../../../../assets/images/message-img.png";
// import BannerImg from "../../../../assets/images/banner-doczy.jpg";
// import XImg from "../../../../assets/images/x-container.png";
// import NoResultsGif from "../../../../assets/icons/doczy-no-results.gif";
// import NoDataFoundGif from "../../../../assets/icons/doczy-nodata-found.gif";
import TickContainerHolder from "../../../../assets/icons/doczy-tickContainer-unchecked.svg";
import TickContainerChecked from "../../../../assets/icons/doczy-tickContainer-checked.svg";
import XContainerChecked from "../../../../assets/images/x-container.png";
import KeyboardDown from "../../../../assets/icons/doczy-arrow-down.svg";
import SearchBar from "../../../../assets/icons/doczy-search-bar.svg";
import Filter from "../../../../assets/images/filter.png";
import Star from "../../../../assets/images/star.png";
import LikedHeart from "../../../../assets/icons/doczy-heart-liked.svg";
import EmptyHeart from "../../../../assets/icons/doczy-heart-empty.svg";
import Message from "../../../../assets/icons/doczy-messages.svg";
import BannerImg from "../../../../assets/images/banner-doczy.jpg";
import XImg from "../../../../assets/icons/doczy-close.svg";
import NoResultsGif from "../../../../assets/icons/doczy-no-results.gif";
import NoDataFoundGif from "../../../../assets/icons/doczy-nodata-found.gif";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useService } from "../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetDoctorsQueryKey,
  GetServiceTypeQueryKey,
  getAllDoctorCategoriesQueryKey,
} from "../../../../API/QueryKeys";
import {
  GetDoctorsDTO,
  getDoctorsForDoctorsPageFilter,
  priceRangeFilter,
} from "../../../../models/DoctorModels";
import { DoctorProfileImagesRoot, MainURL } from "../../../../API/URLs";
import { ScheduleAnAppointmentContext } from "../../../../Contexts/ScheduleAppointment-Context";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { tokenContext } from "../../../../Contexts/TokenContext";
import { getAllDoctorCategories } from "../../../../models/DoctorCategoriesModels";
import { getAllServiceTypes } from "../../../../models/ServiceTypeModels";
const DoctorsList: React.FC<{
  loadingIcon: string;
  setScheduleAppointmentIsOpen: Dispatch<SetStateAction<boolean>>;
  scheduleAppointmentIsOpen: boolean;
  serviceTypeData: any;
  servicesQuery: any;
}> = ({
  loadingIcon,
  setScheduleAppointmentIsOpen,
  scheduleAppointmentIsOpen,
  serviceTypeData,
  servicesQuery,
}) => {
  const queryClient = useQueryClient();
  const { setAppointment, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const [doctorFavourite, setDoctorFavourite] = useState({
    doctorId: "",
    action: "",
  });
  const navigate = useNavigate();

  const { accessToken } = useContext(tokenContext);
  const scheduleAnAppointment = document.getElementById("scheduleAppointment");
  const scheduleAnAppointmentActions = document.getElementById(
    "ScheduleAppointmentAction"
  );
  const [fullNameSearch, setFullNameSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [doctorCategories, setDoctorCategories] = useState<
    getAllDoctorCategories[]
  >([]);
  const [filter, setFilter] = useState<getDoctorsForDoctorsPageFilter>({
    CategoryId: "",
    ServiceTypeId: "",
    FullName: "",
    MinPrice: 0,
    MaxPrice: 0,
    HospitalId: "",
  });
  const [hasMore, setHasMore] = useState(true);
  const [doctors, setDoctors] = useState<GetDoctorsDTO[]>([]);
  const { doctorServices, doctorCategoryServices, serviceTypeServices } =
    useService();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const doctorCategoryQuery = useQuery([getAllDoctorCategoriesQueryKey], () =>
    doctorCategoryServices.getAllDoctorCategories()
  );
  const serviceTypeQuery = useQuery([GetServiceTypeQueryKey], () =>
    serviceTypeServices.getServicesType()
  );
  // const doctorQuery = useQuery(
  //   [GetDoctorsQueryKey],
  //   () =>
  //     doctorServices.GetDoctorsForDoctorsPagePaginate(
  //       accessToken,
  //       pageSize,
  //       pageNumber
  //     ),
  //   {
  //     onSuccess: (response) => {
  //       const newDoctors = response.data;

  //       if (newDoctors.length === 0) {
  //         setHasMore(false);
  //       } else if (newDoctors.length < pageSize) {
  //         setHasMore(false);
  //         setDoctors((state) => [...state, ...newDoctors]);
  //       } else {
  //         setDoctors((state) => [...state, ...newDoctors]);
  //       }
  //     },
  //   }
  // );
  const doctorQuery = useQuery([GetDoctorsQueryKey], () =>
    doctorServices.GetDoctorsForDoctorsPageFilter(filter, accessToken)
  );

  // function handleScroll() {
  //   if (!doctorQuery.isLoading && hasMore) {
  //     const scrollTop =
  //       document.documentElement.scrollTop || document.body.scrollTop;
  //     const scrollHeight =
  //       document.documentElement.scrollHeight || document.body.scrollHeight;
  //     const clientHeight = document.documentElement.clientHeight;
  //     if (scrollTop + clientHeight >= scrollHeight - 100) {
  //       console.log("change");
  //       setPageNumber((prevPageIndex) => prevPageIndex + 1);
  //     }
  //   }
  // }
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    queryClient.removeQueries(GetDoctorsQueryKey);
    doctorQuery.refetch();
  }, [filter]);
  const addFavourite = useMutation(
    () =>
      doctorServices.addFavouriteDoctor(doctorFavourite.doctorId, accessToken),
    {
      onSuccess: () => {
        setDoctorFavourite({ doctorId: "", action: "" });
        queryClient.removeQueries(GetDoctorsQueryKey);
        doctorQuery.refetch();
      },
    }
  );
  const removeFavourite = useMutation(
    () =>
      doctorServices.removeFavouriteDoctor(
        doctorFavourite.doctorId,
        accessToken
      ),
    {
      onSuccess: () => {
        setDoctorFavourite({ doctorId: "", action: "" });
        queryClient.removeQueries(GetDoctorsQueryKey);
        doctorQuery.refetch();
      },
    }
  );

  const handleCategoryClick = (category: getAllDoctorCategories) => {
    if (filter.CategoryId === category.id) {
      setFilter((state) => ({ ...state, CategoryId: null }));
    } else {
      setFilter((state) => ({ ...state, CategoryId: category.id }));
    }
  };

  const serviceTypes = ["Online", "In Person"];
  const [activeServiceType, setActiveServiceType] = useState(null);

  const handleServiceTypeClick = (serviceType: getAllServiceTypes) => {
    if (filter.ServiceTypeId === serviceType.id) {
      setFilter((state) => ({
        ...state,
        ServiceTypeId: null,
      }));
    } else {
      setFilter((state) => ({
        ...state,
        ServiceTypeId: serviceType.id,
      }));
    }
  };

  const priceRanges: priceRangeFilter[] = [
    { id: 1, MinPrice: 0, MaxPrice: 250 },
    { id: 2, MinPrice: 251, MaxPrice: 500 },
    { id: 3, MinPrice: 501, MaxPrice: 750 },
    { id: 4, MinPrice: 751, MaxPrice: 1000 },
  ];
  const [activePriceRange, setActivePriceRange] = useState(null);

  const handlePriceRangeClick = (priceRange: priceRangeFilter) => {
    if (
      filter.MaxPrice === priceRange.MaxPrice &&
      filter.MinPrice === priceRange.MinPrice
    ) {
      setFilter((state) => ({ ...state, MinPrice: null, MaxPrice: null }));
    } else {
      setFilter((state) => ({
        ...state,
        MinPrice: priceRange.MinPrice,
        MaxPrice: priceRange.MaxPrice,
      }));
    }
  };
  const handleFullNameSearch = () => {
    if (fullNameSearch) {
      setFilter((state) => ({
        ...state,
        FullName: fullNameSearch,
      }));
    } else {
      setFilter((state) => ({
        ...state,
        FullName: null,
      }));
    }
  };
  useEffect(() => {
    if (!fullNameSearch) {
      setFilter((state) => ({
        ...state,
        FullName: null,
      }));
    }
  }, [fullNameSearch]);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  useEffect(() => {
    queryClient.removeQueries(GetDoctorsQueryKey);
    doctorQuery.refetch();
  }, [pageNumber]);

  const toggleFilter = () => {
    setFilterVisible(!filterVisible);
  };
  const handleFavouriteChange = (id: string, actionName: string) => {
    if (!accessToken) {
      sessionStorage.setItem("route", "/Doctors");
      return navigate("/Auth/SignIn/Patient");
    }
    setDoctorFavourite({ doctorId: id, action: actionName });
  };
  useEffect(() => {
    if (doctorFavourite.action === "Add") {
      addFavourite.mutate();
    } else if (doctorFavourite.action === "Remove") {
      removeFavourite.mutate();
    }
  }, [doctorFavourite]);
  if (
    doctorQuery.isLoading ||
    serviceTypeQuery.isLoading ||
    doctorCategoryQuery.isLoading
  ) {
    return (
      <div className="loadingPage" style={{ width: "100%", height: "700px" }}>
        <img
          style={{ width: "150px", height: "160px", marginTop: "20vh" }}
          src={loadingIcon}
        />
      </div>
    );
  }
  return (
    <div
      className="doctors__list__container"
      style={{
        opacity: `${scheduleAppointmentIsOpen ? "0.2" : "1"}`,

        pointerEvents: `${scheduleAppointmentIsOpen ? "none" : "all"}`,
      }}
    >
      <div className="doctors__list">
        <div className="doctors__list__filter">
          <div className="filter__title">Filters</div>
          <div className="filter__categories">
            <div className="filter__categories__title">Categories</div>
            <div className="filter__categories__list">
              {doctorCategoryQuery.data?.data.map(
                (category: getAllDoctorCategories) => (
                  <div
                    key={category.id}
                    className="filter__categories__item"
                    onClick={() => handleCategoryClick(category)}
                  >
                    <img
                      src={
                        filter?.CategoryId === category.id
                          ? TickContainerChecked
                          : TickContainerHolder
                      }
                      alt={category.name}
                    />
                    <span>{category.name}</span>
                  </div>
                )
              )}
              {/* <div className="filter__categories__item">
                <img src={TickContainerChecked} alt="" />
                <span>Doctors</span>
              </div>
              <div className="filter__categories__item">
                <img src={TickContainerHolder} alt="" />
                <span>Psychologist</span>
              </div>
              <div className="filter__categories__item">
                <img src={TickContainerHolder} alt="" />
                <span>Dietologist</span>
              </div>
              <div className="filter__categories__item">
                <img src={TickContainerHolder} alt="" />
                <span>Labs</span>
              </div>
              <div className="filter__categories__item">
                <img src={TickContainerHolder} alt="" />
                <span>Centers</span>
              </div>
              <div className="filter__categories__item">
                <img src={TickContainerHolder} alt="" />
                <span>Blogs</span>
              </div> */}
            </div>
          </div>
          <hr />
          <div className="filter__entity_details">
            <div className="filter__dropdown__item">
              <div className="dropdown__item__title">Organization</div>
              <div
                className={`label__holder ${dropdownVisible ? "visible" : ""}`}
                onClick={toggleDropdown}
              >
                <a>
                  <div className="label__holder__wrapper">
                    <span>Label</span>
                  </div>
                  <img src={KeyboardDown} alt="" />
                </a>
              </div>
              <ul className="dropdown">
                <li>
                  <a href="#">Central Clinic</a>
                </li>
                <li>
                  <a href="#">Reference hospital</a>
                </li>
                <li>
                  <a href="#">Ankara Clinic</a>
                </li>
              </ul>
            </div>
            <div className="filter__dropdown__item">
              <div className="dropdown__item__title">Area</div>
              <div className="label__holder">
                <a>
                  <div className="label__holder__wrapper">
                    <span>Label</span>
                  </div>
                  <img src={KeyboardDown} alt="" />
                </a>
              </div>
              <ul className="dropdown">
                <li>
                  <a href="#">Central Clinic</a>
                </li>
                <li>
                  <a href="#">Reference hospital</a>
                </li>
                <li>
                  <a href="#">Ankara Clinic</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="filter__service__types">
            <div className="service__types__title">Service type</div>
            <div className="service__types__items">
              {serviceTypeQuery.data?.data.map(
                (serviceType: getAllServiceTypes) => (
                  <div
                    key={serviceType.id}
                    className="service__types__item"
                    onClick={() => handleServiceTypeClick(serviceType)}
                  >
                    <img
                      src={
                        filter.ServiceTypeId === serviceType.id
                          ? TickContainerChecked
                          : TickContainerHolder
                      }
                      alt={serviceType.name}
                    />
                    <span>{serviceType.name}</span>
                  </div>
                )
              )}
              {/* <div className="service__types__item">
                <img src={TickContainerChecked} alt="" />
                <span>Online</span>
              </div>
              <div className="service__types__item">
                <img src={TickContainerHolder} alt="" />
                <span>In Person</span>
              </div> */}
            </div>
          </div>
          <div className="filter__price__range">
            <div className="price__range__title">Price range</div>
            <div className="price__range__items">
              {priceRanges.map((priceRange: priceRangeFilter) => (
                <div
                  key={priceRange.MinPrice}
                  className="price__range__item"
                  onClick={() => handlePriceRangeClick(priceRange)}
                >
                  <img
                    src={
                      filter.MaxPrice === priceRange.MaxPrice &&
                      filter.MinPrice === priceRange.MinPrice
                        ? TickContainerChecked
                        : TickContainerHolder
                    }
                  />
                  <span>
                    {priceRange.MinPrice} - {priceRange.MaxPrice}
                  </span>
                </div>
              ))}
              {/* <div className="price__range__item">
                <img src={TickContainerHolder} alt="" />
                <span>0-250</span>
              </div>
              <div className="price__range__item">
                <img src={TickContainerHolder} alt="" />
                <span>250-500</span>
              </div>
              <div className="price__range__item">
                <img src={TickContainerHolder} alt="" />
                <span>500-750</span>
              </div>
              <div className="price__range__item">
                <img src={TickContainerHolder} alt="" />
                <span>750-1000</span>
              </div> */}
            </div>
          </div>
          <div className="filter__city">
            <div className="filter__city__title">City</div>
            <div className="city__label__holder">
              <a>
                <div className="label__holder__wrapper">
                  <span>Label</span>
                </div>
                <img src={KeyboardDown} alt="" />
              </a>
            </div>
            <ul className="city__dropdown">
              <li>
                <a href="#">Baku</a>
              </li>
              <li>
                <a href="#">Sumgayit</a>
              </li>
              <li>
                <a href="#">Lankaran</a>
              </li>
            </ul>
          </div>
          {/* <div className="actions__buttons">
            <button>Filter</button>
            <button>Clear</button>
          </div> */}
        </div>
        <div className="doctors__list__cards">
          <div className="cards__search__bar">
            <div className="search__bar__wrapper">
              <div className="search__img__wrapper">
                <img
                  src={SearchBar}
                  alt=""
                  className="search__img"
                  onClick={() => handleFullNameSearch()}
                />
              </div>
              <div className="input__wrapper">
                <input
                  type="text"
                  placeholder="search"
                  id="fullNameSearch"
                  value={fullNameSearch}
                  onChange={(e) => setFullNameSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleFullNameSearch();
                    }
                  }}
                />
              </div>
              <img
                src={Filter}
                alt=""
                className="filter__img"
                onClick={toggleFilter}
              />
              <div
                className={`doctors__list__filter ${
                  filterVisible ? "visible" : ""
                }`}
              >
                <div className="filter__title">
                  <div className="filter__title__text">Filters</div>
                  <div className="filter__title__closing">
                    <img
                      src={XImg}
                      alt=""
                      className="filter__title__closing__img"
                      onClick={toggleFilter}
                    />
                  </div>
                </div>
                <div className="filter__categories">
                  <div className="filter__categories__title">Categories</div>
                  <div className="filter__categories__list">
                    <div className="filter__categories__item">
                      <img src={TickContainerChecked} alt="" />
                      <span>Doctors</span>
                    </div>
                    <div className="filter__categories__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>Psychologist</span>
                    </div>
                    <div className="filter__categories__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>Dietologist</span>
                    </div>
                    <div className="filter__categories__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>Labs</span>
                    </div>
                    <div className="filter__categories__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>Centers</span>
                    </div>
                    <div className="filter__categories__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>Blogs</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="filter__entity_details">
                  <div className="filter__dropdown__item">
                    <div className="dropdown__item__title">Organization</div>
                    <div
                      className={`label__holder ${
                        dropdownVisible ? "visible" : ""
                      }`}
                      onClick={toggleDropdown}
                    >
                      <a>
                        <div className="label__holder__wrapper">
                          <span>Label</span>
                        </div>
                        <img src={KeyboardDown} alt="" />
                      </a>
                    </div>
                    <ul className="dropdown">
                      <li>
                        <a href="#">Central Clinic</a>
                      </li>
                      <li>
                        <a href="#">Reference hospital</a>
                      </li>
                      <li>
                        <a href="#">Ankara Clinic</a>
                      </li>
                    </ul>
                  </div>
                  <div className="filter__dropdown__item">
                    <div className="dropdown__item__title">Area</div>
                    <div className="label__holder">
                      <a>
                        <div className="label__holder__wrapper">
                          <span>Label</span>
                        </div>
                        <img src={KeyboardDown} alt="" />
                      </a>
                    </div>
                    <ul className="dropdown">
                      <li>
                        <a href="#">Central Clinic</a>
                      </li>
                      <li>
                        <a href="#">Reference hospital</a>
                      </li>
                      <li>
                        <a href="#">Ankara Clinic</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="filter__service__types">
                  <div className="service__types__title">Service type</div>
                  <div className="service__types__items">
                    <div className="service__types__item">
                      <img src={TickContainerChecked} alt="" />
                      <span>Online</span>
                    </div>
                    <div className="service__types__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>In Person</span>
                    </div>
                  </div>
                </div>
                <div className="filter__price__range">
                  <div className="price__range__title">Price range</div>
                  <div className="price__range__items">
                    <div className="price__range__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>0-250</span>
                    </div>
                    <div className="price__range__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>250-500</span>
                    </div>
                    <div className="price__range__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>500-750</span>
                    </div>
                    <div className="price__range__item">
                      <img src={TickContainerHolder} alt="" />
                      <span>750-1000</span>
                    </div>
                  </div>
                </div>
                <div className="filter__city">
                  <div className="filter__city__title">City</div>
                  <div className="city__label__holder">
                    <a>
                      <div className="label__holder__wrapper">
                        <span>Label</span>
                      </div>
                      <img src={KeyboardDown} alt="" />
                    </a>
                  </div>
                  <ul className="city__dropdown">
                    <li>
                      <a href="#">Baku</a>
                    </li>
                    <li>
                      <a href="#">Sumgayit</a>
                    </li>
                    <li>
                      <a href="#">Lankaran</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="search__result">
            <div className="search__result__count__title">
              Results(<span>{doctorQuery.data?.data.length}</span>)
            </div>
            <div className="search__result__tags__selected__items">
              <div className="tags__selected__item">
                {filter.CategoryId ? (
                  <div className="selected__item__wrapper">
                    <span>
                      {
                        doctorCategoryQuery.data?.data.find(
                          (doctorCategory: getAllDoctorCategories) =>
                            doctorCategory.id === filter.CategoryId
                        ).name
                      }
                    </span>
                    <img
                      src={XContainerChecked}
                      alt=""
                      onClick={() =>
                        setFilter((state) => ({
                          ...state,
                          CategoryId: null,
                        }))
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="tags__selected__item">
                {filter.ServiceTypeId ? (
                  <div className="selected__item__wrapper">
                    <span>
                      {
                        serviceTypeQuery.data?.data.find(
                          (serviceType: getAllServiceTypes) =>
                            serviceType.id == filter.ServiceTypeId
                        ).name
                      }
                    </span>
                    <img
                      src={XContainerChecked}
                      onClick={() =>
                        setFilter((state) => ({
                          ...state,
                          ServiceTypeId: null,
                        }))
                      }
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {doctorQuery.data?.data.length === 0 ? (
            <div className="doctor__list__card__items">
              <div className="no-results">
                <img src={NoResultsGif} alt="No Results" />
                {/* <img src={NoDataFoundGif} alt="No Results" /> */}
                <p>No doctors found. Please adjust your search criteria.</p>
              </div>
            </div>
          ) : (
            doctorQuery.data?.data.map((doctor: GetDoctorsDTO) => (
              <div className="doctor__list__card__items">
                <div className="doctor__list__card__item" key={doctor.id}>
                  <div className="card__item__wrapper">
                    <div className="card__item__wrapper__top">
                      <div
                        className="card__item__doctor"
                        onClick={() => navigate(`${doctor.id}/AboutDoc`)}
                      >
                        <img
                          src={`${MainURL}/${DoctorProfileImagesRoot}/${doctor.profileImageUrl}`}
                          alt=""
                        />
                        <span className="doctor__credentials">
                          <div className="doctor__about">
                            <div className="doctor__fullname">
                              {doctor.firstName} {doctor.lastName}
                            </div>
                            <div className="doctor__specialization">
                              {doctor.categoryName ?? "No category"}
                            </div>
                          </div>
                          <div className="doctor__rating">
                            <div className="doctor__rating__star">
                              {doctor.raiting && <img src={Star} alt="" />}
                              <span>{doctor.raiting}</span>
                            </div>
                            <div className="doctor__rating__reviews">
                              ({doctor.reviews} Reviews)
                            </div>
                          </div>
                        </span>
                      </div>
                      <div className="card__item__liked">
                        <div className="item__liked__wrapper">
                          <button
                            onClick={() =>
                              handleFavouriteChange(
                                doctor.id,
                                doctor.isFavourite ? "Remove" : "Add"
                              )
                            }
                          >
                            {doctor.isFavourite ? (
                              <img src={LikedHeart} alt="" />
                            ) : (
                              <img src={EmptyHeart} alt="" />
                            )}
                          </button>

                          <span>{doctor.favourite}</span>
                        </div>
                      </div>
                    </div>
                    <div className="card__item__wrapper__down">
                      <button
                        className="appointment__btn"
                        onClick={() => {
                          if (!accessToken) {
                            sessionStorage.setItem("route", "/Doctors");
                            return navigate("/Auth/SignIn/Patient");
                          }
                          setScheduleAppointmentIsOpen(true);
                          navigate("Service");
                          setDoctorInfo(() => ({
                            appointmentTypeId: `${serviceTypeData.data?.data[0].id}`,
                            doctorFirstName: `${doctor.firstName}`,
                            doctorLastName: `${doctor.lastName}`,
                            doctorCategory: `${doctor.categoryName}`,
                            doctorImage: `${
                              doctor
                                ? `${MainURL}/${DoctorProfileImagesRoot}/${doctor.profileImageUrl}`
                                : ""
                            }`,
                            categoryName: doctor.categoryName ?? "No category",
                            packetName: `${servicesQuery.data?.data[0]?.name}`,
                            typeName: `${serviceTypeData.data?.data[0]?.name}`,
                            packetPrice: `${serviceTypeData.data?.data[0]?.price}`,
                            packetDuration: `${serviceTypeData.data?.data[0]?.duration}`,
                            appoinmentTime: "",

                            doctorRating: null,
                          }));
                          setAppointment(() => ({
                            doctorId: doctor.id,
                            serviceId: `${
                              servicesQuery.data?.data[0]?.id ?? ""
                            }`,
                            chosenDate: `${dayjs(new Date()).format(
                              "YYYY-MM-DD"
                            )}`,
                            chosenHourId: "",
                            // permission: false,
                            painDescription: "",
                            // cardNumber: "",
                            // cardNameSurname: "",
                            // MMYY: "",
                            // CVV: null,
                          }));
                          if (
                            scheduleAnAppointment &&
                            scheduleAnAppointmentActions
                          ) {
                            scheduleAnAppointment.style.right = "0px";
                            scheduleAnAppointmentActions.style.right = "0px";
                          }
                        }}
                      >
                        <a>Appointment</a>
                      </button>
                      <button className="message__btn">
                        <img src={Message} alt="" />
                        <a href="">Message</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="doctors__list__banner">
          <img src={BannerImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DoctorsList;
