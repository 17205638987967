import { Box } from "@mui/system";
import styled from "./SocialLinks.module.scss";
import { TextField } from "@mui/material";
import instagram from "../../../../../assets/images/instagramImg.png";
import facebook from "../../../../../assets/images/logos_facebook.svg";
import twitter from "../../../../../assets/images/twitterImg.png";
import X from "../../../../../assets/images/twitter 2.png";
import { useState } from "react";

export const SocialLinksSettings = () => {
  const [isEditing, setIsEditing] = useState(true);

  return (
    <section className={styled.socialLinks}>
      <section className={styled.header}>
        <h1>Social Links</h1>
      </section>
      <section className={styled.main}>
        <section className={styled.heading}>
          <h1>Social Media</h1>
        </section>
        <section className={styled.inputs}>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <div className={styled.container}>
              <div className={styled.socialMedia}>
                <img src={instagram} />
                <h1>Instagram</h1>
              </div>

              <TextField
                id="outlined-basic"
                label="Link address"
                variant="outlined"
              />
            </div>
            <div className={styled.container}>
              <div className={styled.socialMedia}>
                <img src={facebook} />
                <h1>Facebook</h1>
              </div>

              <TextField
                id="outlined-basic"
                label="Link address"
                variant="outlined"
              />
            </div>
            <div className={styled.container}>
              <div className={styled.socialMedia}>
                <img src={twitter} />
                <h1>Twitter </h1>/ <img src={X} />
                <h1>X</h1>
              </div>

              <TextField
                id="outlined-basic"
                label="Link address"
                variant="outlined"
              />
            </div>
          </Box>
        </section>
        <section className={styled.action}>
          {!isEditing ? (
            <button className={styled.edit} onClick={() => setIsEditing(true)}>
              Edit
            </button>
          ) : (
            <>
              <button className={styled.save}>Save</button>
              <button
                className={styled.cancel}
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            </>
          )}
        </section>
      </section>
    </section>
  );
};
