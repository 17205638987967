import styled from "./SignInNavigation.module.scss";
import AuthPatient from "../../../../assets/images/auth-user-img.png";
import AuthDoctor from "../../../../assets/images/auth-hospital-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export const SignInNavigation = () => {
    const { pathname } = useLocation();
    const [page, setPage] = useState<string>(pathname);
    const isPatientSignIn = pathname.toLowerCase().endsWith("/auth/signin/patient");
    const isDoctorSignIn = pathname.toLowerCase().endsWith("/auth/signin/doctor");
    const navigate = useNavigate();
    const handleClick = (page: string) => {
        navigate(page);
        setPage(page);
      };
    
    return (
      <div className={styled.signin__tab__wrapper}>
        <div className={`${styled.patient__tab} ${isPatientSignIn ? styled.active : ''}`} onClick={() => {handleClick("Patient");setPage("/Auth/SignIn/Patient");}}>
          <img src={AuthPatient} alt="" />
          <div className={styled.patient__tab__content}>As a patient</div>
        </div>
        <div className={`${styled.doctor__tab} ${isDoctorSignIn ? styled.active : ''}`} onClick={() => {handleClick("Doctor");setPage("/Auth/SignIn/Doctor");}}>
          <img src={AuthDoctor} alt="" />
          <div className={styled.doctor__tab__content}>As a doctor</div>
        </div>
      </div>
    );
};