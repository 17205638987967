import { Autocomplete, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import styled from "./Modals.module.scss";
import { createNewExperience } from "../../../../../../models/ExperienceModels";
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useService } from "../../../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  GetDoctorResumeQueryKey,
  getAllCitiesQueryKey,
  getAllCountriesQueryKey,
  getAllHospitalsQueryKey,
} from "../../../../../../API/QueryKeys";
import { getAllHospitals } from "../../../../../../models/HospitalModels";
import { getCountry } from "../../../../../../models/CountryModels";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { tokenContext } from "../../../../../../Contexts/TokenContext";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: "8px",
  padding: "12px 12px 16px 24px",
  pt: 2,
  px: 4,
  pb: 3,
  background: "#E9EFEF",
  borderRadius: "28px",
};
export const AddNewExperience: React.FC<{
  doctorId: string;
  addNewExperience: boolean;
  closeModal: (name: string) => void;
}> = ({ addNewExperience, closeModal, doctorId }) => {
  const queryClient = useQueryClient();
  const { accessToken } = useContext(tokenContext);
  const { experienceServices } = useService();
  const [createBody, setCreateBody] = useState<createNewExperience>({
    title: "",
    hospitalId: "",
    location: "",
    currentlyWorking: false,
    startDate: "",
    endDate: "",
  });
  const experienceMutate = useMutation(
    () => experienceServices.createExperience(createBody, accessToken),
    {
      onSuccess: () => {
        closeModal("addNewExperience");
        setCreateBody({
          title: "",
          hospitalId: "",
          location: "",
          currentlyWorking: false,
          startDate: "",
          endDate: "",
        });
        queryClient.removeQueries([GetDoctorResumeQueryKey, doctorId]);
      },
    }
  );

  const handleCreateExperience = () => {
    experienceMutate.mutate();
  };
  const countryQuery = useQuery(
    [getAllCountriesQueryKey],
    async () => await axios.get("https://restcountries.com/v3.1/all")
  );
  const [cities, setCities] = useState<string[]>([]);
  const [currentCities, setCurrentCities] = useState<string[]>([]);
  const cityQuery = useQuery(
    [getAllCitiesQueryKey],
    async () => await axios.get("https://countriesnow.space/api/v0.1/countries")
  );
  useEffect(() => {
    if (cityQuery.isSuccess) {
      setCities(cityQuery.data?.data?.data);
    }
  }, [cityQuery.isSuccess]);
  const [currentCountry, setCurrentCountry] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  useEffect(() => {
    if (currentCountry && cities.length > 0) {
      const data: any = cities.find(
        (response: any) => response?.country === currentCountry
      );
      setCurrentCities(data?.cities);
    }
  }, [currentCountry]);
  useEffect(() => {
    setCreateBody((state) => ({
      ...state,
      location: currentCity.concat(" , ", currentCountry),
    }));
  }, [currentCity, currentCountry]);

  const { hospitalServices } = useService();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCreateBody((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(() => {}, [currentCountry]);
  const hospitalsQuery = useQuery([getAllHospitalsQueryKey], () =>
    hospitalServices.getAllHospital()
  );

  if (hospitalsQuery.isLoading || countryQuery.isLoading) {
    return <h1>Is loading</h1>;
  }

  return (
    <Modal
      open={addNewExperience}
      onClose={() => {
        closeModal("addNewExperience");
        setCreateBody({
          title: "",
          hospitalId: "",
          location: "",
          currentlyWorking: false,
          startDate: "",
          endDate: "",
        });
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 560 }}>
        <section className={styled.header}>
          <h1>Create new packet</h1>
          <button
            onClick={() => {
              closeModal("addNewExperience");
              setCreateBody({
                title: "",
                hospitalId: "",
                location: "",
                currentlyWorking: false,
                startDate: "",
                endDate: "",
              });
            }}
          >
            <CloseIcon />
          </button>
        </section>
        <section className={styled.inputs}>
          <form>
            <div>
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                name="title"
                required
                sx={{ width: "100%" }}
                onChange={handleChange}
              />

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={hospitalsQuery.data?.data || []}
                getOptionLabel={(option: getAllHospitals) => option.name}
                onChange={(
                  event: SyntheticEvent,
                  newValue: getAllHospitals | null
                ) =>
                  setCreateBody((state) => ({
                    ...state,
                    hospitalId: newValue ? newValue?.id : "",
                  }))
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField required {...params} label="Hospital" />
                )}
              />
            </div>

            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={currentCities || []}
                disabled={currentCities.length === 0}
                getOptionLabel={(option) => option}
                onChange={(event: SyntheticEvent, newValue: string | null) =>
                  setCurrentCity(newValue ?? "")
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField required {...params} label="City" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countryQuery.data?.data || []}
                getOptionLabel={(option: getCountry) => option.name.common}
                onChange={(
                  event: SyntheticEvent,
                  newValue: getCountry | null
                ) => setCurrentCountry(newValue?.name.common ?? "")}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField required {...params} label="Country" />
                )}
              />
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    label="Start Date"
                    onChange={(value: string | null) =>
                      setCreateBody((state) => ({
                        ...state,
                        startDate: value
                          ? dayjs(value).format("YYYY-MM-DD")
                          : "",
                      }))
                    }
                  />
                  <DatePicker
                    label="End Date"
                    onChange={(value: string | null) =>
                      setCreateBody((state) => ({
                        ...state,
                        endDate: value ? dayjs(value).format("YYYY-MM-DD") : "",
                      }))
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className={styled.radio}>
              <input
                type="radio"
                checked={createBody.currentlyWorking}
                onSelect={() =>
                  setCreateBody((state) => ({
                    ...state,
                    currentlyWorking: !state.currentlyWorking,
                  }))
                }
              />
              <label>I still work there</label>
            </div>
          </form>
        </section>
        <section className={styled.actions}>
          <div className={styled.container}>
            <button className={styled.close}>Close</button>
            <button
              className={styled.finish}
              onClick={() => {
                handleCreateExperience();
              }}
            >
              Finish
            </button>
          </div>
        </section>
      </Box>
    </Modal>
  );
};
