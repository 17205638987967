import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class FieldOfStudyService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }

  async getAllFieldsOfStudy(token: string | null) {
    return await this.getAll("FieldOfStudy", token);
  }
}
