import { useLocation, useNavigate } from "react-router-dom";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import HamburgerMenu from "../../../../assets/icons/doczy-hamburger-menu.svg";
import HeartEmpty from "../../../../assets/images/heart-img-empty.png";
import Message from "../../../../assets/icons/doczy-messages.svg";
import Blogs from "../../../../assets/icons/doczy-blogs.svg";
import Notification from "../../../../assets/icons/doczy-notification.svg";
import ProfileImg from "../../../../assets/icons/doczy-profile.svg";
import ArrowDown from "../../../../assets/icons/doczy-arrow-down.svg";
import XImg from "../../../../assets/icons/doczy-close.svg";
import HomeMenu from "../../../../assets/images/home-menu-img.png";
import DoctorsMenu from "../../../../assets/images/doctors-menu-img.png";
import BlogsMenu from "../../../../assets/images/blogs-menu-img.png";
import CategoriesMenu from "../../../../assets/images/categories-menu-img.png";
import AboutUsMenu from "../../../../assets/images/aboutus-menu-img.png";
import LogoutMenu from "../../../../assets/icons/doczy-logout.svg";
import { useService } from "../../../../hooks";
import { useMutation } from "react-query";
import { tokenContext } from "../../../../Contexts/TokenContext";

function HeaderAuthorizedDoctor() {
  const {
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    setExpiration,
  } = useContext(tokenContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authServices } = useService();
  const [page, setPage] = useState<string>(pathname);
  const [isAuthPage, setIsAuthPage] = useState<boolean>(
    pathname === "/Auth/SignUp"
  );
  const [HamburgerMenuElemVisible, setHamburgerMenuElemVisible] =
    useState(false); // State for HamburgerMenuElem visibility
  const [ArrowDownElemVisible, setArrowDownElemVisible] = useState(false); // State for HamburgerMenuElem visibility
  const [hasScrolledToBottom, setHasScrolledToBottom] =
    useState<boolean>(false); // State for tracking if scrolled to bottom

  const mutateLogout = useMutation(() => authServices.signOut(), {
    onSuccess: () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expiration");
      setAccessToken("");
      setRefreshToken("");
      setExpiration("");
      const route = sessionStorage.getItem("route");
      if (route) {
        sessionStorage.removeItem("route");
        navigate(route);
      } else {
        // navigate("/DoctorPage/Dashboard");
        navigate("/Auth/SignIn/Doctor");
      }
    },
  });

  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);

    document
      .querySelector(".header__menu__bar__authorized")
      ?.classList.remove("active");
  };

  useEffect(() => {
    setPage(pathname);
    setIsAuthPage(pathname === "/Auth/SignUp/Patient");
    // setIsAuthPage(pathname === "/Auth/SignUp/Doctor");
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolledDown = window.scrollY > 0;
      setHasScrolledToBottom(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleHamburgerMenuElem = () => {
    setHamburgerMenuElemVisible(!HamburgerMenuElemVisible); // Toggle filter visibility
    setArrowDownElemVisible(!ArrowDownElemVisible); // Toggle filter visibility
  };

  const toggleArrowDownElemVisible = () => {
    setHamburgerMenuElemVisible(!HamburgerMenuElemVisible); // Toggle filter visibility
    setArrowDownElemVisible(!ArrowDownElemVisible); // Toggle filter visibility
  };

  return (
    // <header className={`homePage_header ${isAuthPage ? "auth" : ""}`}>
    <header
      className={`homePage_header doctor ${isAuthPage ? "auth" : ""} ${
        hasScrolledToBottom ? "scrolledToBottom" : ""
      }`}
    >
      <div className="container">
        <div className="header__inner">
          <div className="header__logo">
            <a href="HomePage">Doczy</a>
            {/* <a onClick={() => handleClick("HomePage")}>Doczy</a> */}
          </div>
          <div className="navigation">
            <div className="header__auth__authorized">
              <div className="header__auth__actions">
                <div className="header__auth__actions__message">
                  <img src={Message} alt="" />
                </div>
                <div className="header__auth__actions__liked">
                  <img src={Blogs} alt="" />
                </div>
                <div className="header__auth__actions__notification">
                  <img src={Notification} alt="" />
                </div>
              </div>
              <div className="header__auth__profile">
                  {/* <div className="header__auth__profile__image"> */}
                  <div className="header__auth__profile__image" onClick={() => handleClick("DoctorPage/Dashboard")}>
                  <img src={ProfileImg} alt="" />
                </div>
                <div
                  className="header__auth__profile__dropdown"
                  onClick={toggleArrowDownElemVisible}
                >
                  <img src={ArrowDown} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="hamburger__menu">
            <img src={HamburgerMenu} alt="" onClick={toggleHamburgerMenuElem} />
          </div>
          <div
            className={`header__menu__bar__authorized ${
              HamburgerMenuElemVisible || ArrowDownElemVisible ? "active" : ""
            }`}
          >
            <div className="header__menu__authorized__close">
              <div className="menu__closingimg__wrapper">
                <img
                  src={XImg}
                  alt=""
                  className="closingimg"
                  onClick={toggleHamburgerMenuElem}
                />
              </div>
            </div>
            <div className="header__menu__profile">
              <div className="profile__image__wrapper">
                <img src={ProfileImg} alt="" />
              </div>
              <div className="profile__fullname">Firstname Lastname</div>
            </div>

            <nav className="menu__nav first">
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("DoctorPage/Dashboard")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={ProfileImg} alt="" />
                  <span>Profile</span>
                </div>
              </a>
            </nav>

            <nav className="menu__nav third">
              <a
                className="menu__nav__link__holder"
                onClick={() => mutateLogout.mutate()}
                // onClick={() => handleClick("HomePage")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={LogoutMenu} alt="" />
                  <span>Log out</span>
                </div>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderAuthorizedDoctor;
