import { useQuery } from "react-query";
import { useService } from "../../../../hooks";
import styled from "./AboutDoc.module.scss";
import { GetDoctorAboutQueryKey } from "../../../../API/QueryKeys";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GetDoctorAbout } from "../../../../models/DoctorModels";
import { doctorDetailsLoadingContext } from "../../../../Contexts/DoctorDetailsLoadingContext";
export const AboutDoc = () => {
  const { loading, setLoading } = useContext(doctorDetailsLoadingContext);
  const token = localStorage.getItem("accessToken");
  const { doctorServices } = useService();
  const [doctorData, setDoctorData] = useState<GetDoctorAbout | undefined>();
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useQuery(
    [GetDoctorAboutQueryKey, id],
    () => doctorServices.getDoctorAboutById(id ?? "", token ?? "")
  );
  useEffect(() => {
    if (isSuccess) {
      setDoctorData(data?.data);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  return (
    <div className={styled.AboutDoc}>
      <div className={styled.box}>
        <div className={styled.main}>
          <section className={styled.leftSide}>
            <div className={styled.header}>
              <h1>About Doctor</h1>
            </div>
            <div className={styled.info}>
              <p>{doctorData?.aboutDoctor ?? "Məlumat yoxdu"}</p>
            </div>
          </section>
          <section className={styled.rightSide}>
            <div className={styled.languages}>
              <div className={styled.header}>
                <h1>Languages</h1>
              </div>
              <div className={styled.list}>
                <ul>
                  {doctorData?.languages.length
                    ? doctorData?.languages.map((language) => {
                        return (
                          <li key={language.id}>
                            <button>{language.name}</button>
                          </li>
                        );
                      })
                    : "Məlumat yoxdu"}
                </ul>
              </div>
            </div>
            <div className={styled.speciality}>
              <div className={styled.header}>
                <h1>Speciality Areas</h1>
              </div>
              <div className={styled.list}>
                <ul>
                  {doctorData?.specialities.length
                    ? doctorData?.specialities.map((speciality) => {
                        return (
                          <li>
                            <button>{speciality.title}</button>
                          </li>
                        );
                      })
                    : "Məlumat yoxdu"}
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
