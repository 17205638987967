import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Box, height, width } from "@mui/system";

import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Clear, RemoveRedEye, Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useService } from "../../../../hooks";
import { useMutation } from "react-query";
import { SignUpPatientSchema } from "../../../../validations/FormSchemas";
import { tokenContext } from "../../../../Contexts/TokenContext";
import { signUpPatientModel, tokenResponse } from "../../../../models/AuthModels";

const AppTextField = (props: any) => {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";

  const handleClear = () => {
    helpers.setValue(''); // Clear the field value
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={meta.touched && !!meta.error}
      style={{
        width: '100%',
        marginBottom: '8px', // adjust as needed
        borderRadius: '12px', // apply borderRadius directly
      }}
      // onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {field.value && ( // Only show clear icon when field has value
              <IconButton onClick={handleClear} edge="end">
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

const AppTextFieldPassword = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={meta.touched && !!meta.error}
      style={{
        width: '100%',
      }}
      // onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility} edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  // gap: 20px;
`;

const StyledFieldButtonWrapper = styled.div`
  // width: 454px
  // flex: 1 1 calc(100%);
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  // width: 100%;
  cursor: pointer;
  width: 117px;
  height: 40px;
  border: none;
  border-radius: 100px;
  gap: 8px;
  background-color: #00696c;
  color: #fff;
  padding: 10px 32px 10px 32px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  transition: all 0.2s linear;
  &:hover {
    background-color: #cce8e8;
    color: black;
  }
`;

const SignUpPatientForm: React.FC = () => {
  const { setAccessToken, setRefreshToken, setExpiration } = useContext(tokenContext);
  const { patientServices } = useService();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);

  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);
  };

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);

  const spanStyle: React.CSSProperties = {
    textDecoration: "none",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.36px",
    color: "#00696C",
    marginLeft: "8px",
    cursor: "pointer",
  };

  // State initialization and mutation
  const [SignUpBody, setSignUpBody] = useState<signUpPatientModel>({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  // (values: { firstName: string, lastName: string, userName: string, email:string, Password: string })
  const mutate = useMutation((values: { firstName: string, lastName: string, userName: string, email:string, password: string }) => {
    const { firstName, lastName, userName, email, password } = values;
    
    // Call authServices.signIn with the required parameters
    return patientServices.patientRegister({
      firstName,
      lastName,
      userName,
      email,
      password,
    }, undefined)
  }, {
    onSuccess: (response) => {
      console.log(response);
      navigate("/Auth/VerifyEmail");
    },
  });

  return (
    <div>
      <Formik
        initialValues={SignUpBody}
        validationSchema={SignUpPatientSchema}
        // onSubmit={(values) => {
        //   console.log(values);
        // }}
        onSubmit={(values) => {
          const { confirmPassword, ...submissionData } = values; // Omit confirmPassword from submissionData
          mutate.mutate(submissionData);
          console.log(submissionData);
        }}
        // onSubmit={(values) => mutate.mutate(values)}
      >
        {({ values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched, }) => (
          <StyledForm>
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <AppTextField
                    label="Firstname"
                    name="firstName"
                    variant="outlined"
                    autoComplete="firstName"
                    value={values.firstName}
                    // onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AppTextField
                    label="Lastname"
                    name="lastName"
                    // type="password"
                    variant="outlined"
                    autoComplete="lastName"
                    value={values.lastName}
                    // onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AppTextField
                  label="Username"
                  name="userName"
                  variant="outlined"
                  autoComplete="userName"
                  value={values.userName}
                  // onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextField
                  label="Email"
                  name="email"
                  // type="password"
                  variant="outlined"
                  autoComplete="email"
                  value={values.email}
                  // onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextFieldPassword
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  autoComplete="password"
                  value={values.password}
                  // onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <AppTextFieldPassword
                  label="Password again"
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                  autoComplete="confirmPassword"
                  // value={values.confirmPassword}
                  // onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <StyledFieldButtonWrapper>
              <StyledButton
                type="submit"
                // onClick={() => mutate.mutate(values)}
                // onClick={() => handleClick("../../Auth/VerifyEmail")}
              >
                Sign Up
              </StyledButton>
            </StyledFieldButtonWrapper>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  gap: "8px",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  color: "000",
                }}
              >
                Already have an account?
                <a style={spanStyle} href="../../Auth/SignIn/Doctor">
                  Sign In.
                </a>
              </Box>
            </Box>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default SignUpPatientForm;
