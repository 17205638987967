import { useContext, useEffect } from "react";
import dayjs from "dayjs";
import styled from "./Date.module.scss";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ScheduleAnAppointmentContext } from "../../../../../Contexts/ScheduleAppointment-Context";
import { useService } from "../../../../../hooks";
import { useQuery } from "react-query";
import { getDoctorAvailabilityByDoctorIdDateQueryKey } from "../../../../../API/QueryKeys";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export const DateSection = () => {
  const { doctorAvailabilityServices } = useService();
  const { appointment, setAppointment, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );
  const doctorAvailabilityQuery = useQuery(
    [getDoctorAvailabilityByDoctorIdDateQueryKey],
    () =>
      doctorAvailabilityServices.getDoctorAvailabilityByDoctorIdDate(
        appointment.doctorId ?? undefined,
        appointment.chosenDate ?? undefined
      )
  );

  useEffect(() => {
    doctorAvailabilityQuery.remove();
    doctorAvailabilityQuery.refetch();
  }, [appointment.doctorId, appointment.chosenDate]);
  function disableWeekends(date: any) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  return (
    <>
      <section className={styled.calendar}>
        <div className={styled.header}>
          <h1>Select a date</h1>
        </div>
        <div className={styled.main}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              sx={{ background: "#E3E9E9", borderRadius: "16px" }}
              components={["DateCalendar", "DateCalendar"]}
            >
              <DemoItem>
                <DateCalendar
                  onChange={(newValue) =>
                    setAppointment((state) => ({
                      ...state,
                      chosenDate: dayjs(newValue).format("YYYY-MM-DD"),
                    }))
                  }
                  disablePast
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </section>
      <section className={styled.time}>
        <div className={styled.header}>
          <h1>Select time</h1>
        </div>
        <div className={styled.variants}>
          <ul>
            {doctorAvailabilityQuery.isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : doctorAvailabilityQuery.error ? (
              <div
                _ngcontent-yuw-c146=""
                className="info-container mt-10 ng-star-inserted"
              >
                <i _ngcontent-yuw-c146="" className="fa fa-info info-icon"></i>
                <p _ngcontent-yuw-c146="" className="info-label">
                  Bu tarix üçün hələ aktiv xidmət təyin olunmayıb.{" "}
                </p>
              </div>
            ) : (
              doctorAvailabilityQuery &&
              doctorAvailabilityQuery.data?.data.availableHours.map(
                (avaibility: any) => {
                  return (
                    <li
                      className={styled.variant}
                      style={{
                        background:
                          appointment.chosenHourId === avaibility.id
                            ? "#CCE8E8"
                            : "",
                      }}
                      key={avaibility.id}
                      onClick={() => {
                        setAppointment((state) => ({
                          ...state,
                          chosenHourId: avaibility.id,
                        }));
                        setDoctorInfo((state) => ({
                          ...state,
                          appoinmentTime: avaibility.time,
                        }));
                      }}
                    >
                      {appointment.chosenHourId === avaibility.id && (
                        <div className={styled.selectedIcon}></div>
                      )}
                      <h1>{avaibility.time.substring(0, 5)}</h1>
                    </li>
                  );
                }
              )
            )}
          </ul>
        </div>
      </section>
    </>
  );
};
