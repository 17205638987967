// import ReactCardSlider from "react-card-slider-component";
// import sliderPsychology from "../../../../assets/images/slider-psychology.png";
// import sliderDietology from "../../../../assets/images/slider-dietology.png";
// import sliderDoctors from "../../../../assets/images/slider-doctors.png";
// import sliderLabaratory from "../../../../assets/images/slider-labaratory.png";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";

// const slides = [
//   {
//     image: sliderPsychology,
//     title: "Psychology",
//     description: "By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment.",
//     onclick: Navigate("../Doctors"),
//   },
//   {
//     image: sliderDietology,
//     title: "Dietology",
//     description: "By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment.",
    
//   },
//   {
//     image: sliderDoctors,
//     title: "Radiology",
//     description: "You can find the doctor you are looking for in the relevant specialty, review the calendar and schedule the most suitable time.",
    
//   },
//   {
//     image: sliderLabaratory,
//     title: "Labaratory tests",
//     description: "You can list the institutions and services available on evital for your analysis and examinations, and receive service you selected.",
    
//   },
//   {
//     image: sliderPsychology,
//     title: "Therapy",
//     description: "By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment ",
    
//   },
//   {
//     image: sliderDoctors,
//     title: "Dermatology",
//     description: "By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment ",
    
//   },
// ];

// function ServiceCategories() {
//   return (
//     <div className="our__services">
//         <div className="CardSlider_container">
//         <h2>Our services</h2>
//         <ReactCardSlider slides={slides} />
//         </div>
//     </div>
//   );
// };


// export default ServiceCategories;




// const navigate = useNavigate();
//   const { pathname } = useLocation();
//   const [page, setPage] = useState(pathname);

//   const handleClick = (page) => {
//     navigate(page);
//     setPage(page);
//   };

//   useEffect(() => {
//     setPage(pathname);
//   }, [pathname]);
























// var OurServices = document.getElementById('our__services');
// var slider = document.getElementById('slider');
// var slides = document.getElementsByClassName('slide').length;
// var buttons = document.getElementsByClassName('btn');

// var currentPosition = 0;
// var currentMargin = 0;
// var slidesPerPage = 0;
// var slidesCount = slides - slidesPerPage;
// var OurServicesWidth = OurServices ? OurServices.offsetWidth : 0; // Null check for OurServices
// var prevKeyActive = false;
// var nextKeyActive = false;

// window.addEventListener("resize", checkWidth);

// function checkWidth() {
//     OurServicesWidth = OurServices ? OurServices.offsetWidth : 0; // Null check for OurServices
//     setParams(OurServicesWidth);
// }

// function setParams(w) {
//     if (w < 551) {
//         slidesPerPage = 1;
//     } else if (w < 901) {
//         slidesPerPage = 2;
//     } else if (w < 1101) {
//         slidesPerPage = 3
//     } else {
//         slidesPerPage = 4;
//     }
//     slidesCount = slides - slidesPerPage;
//     if (currentPosition > slidesCount) {
//         currentPosition -= slidesPerPage;
//     };
//     currentMargin = - currentPosition * (100 / slidesPerPage);
//     if (slider) { // Null check for slider
//         slider.style.marginLeft = currentMargin + '%';
//     }
//     if (currentPosition > 0) {
//         buttons[0].classList.remove('inactive');
//     }
//     if (currentPosition < slidesCount) {
//         buttons[1].classList.remove('inactive');
//     }
//     if (currentPosition >= slidesCount) {
//         buttons[1].classList.add('inactive');
//     }
// }

// setParams();

// function slideRight() {
//     if (currentPosition != 0) {
//         if (slider) { // Null check for slider
//             slider.style.marginLeft = currentMargin + (100 / slidesPerPage) + '%';
//         }
//         currentMargin += (100 / slidesPerPage);
//         currentPosition--;
//     }
//     if (currentPosition === 0) {
//         buttons[0].classList.add('inactive');
//     }
//     if (currentPosition < slidesCount) {
//         buttons[1].classList.remove('inactive');
//     }
// };

// function slideLeft() {
//     if (currentPosition != slidesCount) {
//         if (slider) { // Null check for slider
//             slider.style.marginLeft = currentMargin - (100 / slidesPerPage) + '%';
//         }
//         currentMargin -= (100 / slidesPerPage);
//         currentPosition++;
//     }
//     if (currentPosition == slidesCount) {
//         buttons[1].classList.add('inactive');
//     }
//     if (currentPosition > 0) {
//         buttons[0].classList.remove('inactive');
//     }
// };






































// import carouselPsychology from "../../../../assets/images/carousel-psychology.png";
// import carouselDietology from "../../../../assets/images/carousel-dietology.png";
// import carouselRadiology from "../../../../assets/images/carousel-radiology.png";
// import carouselLabaratory from "../../../../assets/images/carousel-labaratory.png";
// import React, { useEffect } from 'react';

// function ServiceCategories() {
//     useEffect(() => {
//         const OurServices = document.getElementById('our__services__slider');
//         const slider = document.getElementById('slider');
//         const slides = document.getElementsByClassName('slide').length;
//         const buttons = document.getElementsByClassName('spanBtn');
//         const buttonWrappers = document.getElementsByClassName('spanBtn__wrapper');

//         let currentPosition = 0;
//         let currentMargin = 0;
//         let slidesPerPage = 0;
//         let slidesCount = slides - slidesPerPage;
//         let OurServicesWidth = OurServices.offsetWidth;
//         let prevKeyActive = false;
//         let nextKeyActive = false;

//         const checkWidth = () => {
//             OurServicesWidth = OurServices.offsetWidth;
//             setParams(OurServicesWidth);
//         };

//         const setParams = (w) => {
//             if (w < 551) {
//                 slidesPerPage = 1;
//             } else if (w < 901) {
//                 slidesPerPage = 2;
//             } else if (w < 1101) {
//                 slidesPerPage = 3;
//             } else {
//                 slidesPerPage = 4;
//             }

//             slidesCount = slides - slidesPerPage;
//             if (currentPosition > slidesCount) {
//                 currentPosition -= slidesPerPage;
//             }
//             currentMargin = - currentPosition * (100 / slidesPerPage);
//             slider.style.marginLeft = currentMargin + '%';
//             if (currentPosition > 0) {
//                 buttons[0].classList.remove('inactive');
//             }
//             if (currentPosition < slidesCount) {
//                 buttons[1].classList.remove('inactive');
//             }
//             if (currentPosition >= slidesCount) {
//                 buttons[1].classList.add('inactive');
//             }
//         };

//         setParams();

//         const slideRight = () => {
//             if (currentPosition != 0) {
//                 slider.style.marginLeft = currentMargin + (100 / slidesPerPage) + '%';
//                 currentMargin += (100 / slidesPerPage);
//                 currentPosition--;
//             }
//             if (currentPosition === 0) {
//                 buttons[0].classList.add('inactive');
//             }
//             if (currentPosition < slidesCount) {
//                 buttons[1].classList.remove('inactive');
//             }
//         };

//         const slideLeft = () => {
//             if (currentPosition != slidesCount) {
//                 slider.style.marginLeft = currentMargin - (100 / slidesPerPage) + '%';
//                 currentMargin -= (100 / slidesPerPage);
//                 currentPosition++;
//             }
//             if (currentPosition == slidesCount) {
//                 buttons[1].classList.add('inactive');
//             }
//             if (currentPosition > 0) {
//                 buttons[0].classList.remove('inactive');
//             }
//         };

//         const handleResize = () => {
//             checkWidth();
//         };

//         window.addEventListener("resize", handleResize);


        
//         // Assign click handlers to buttons
//         // buttons[0].addEventListener("click", slideRight);
//         // buttons[1].addEventListener("click", slideLeft);
//         buttonWrappers[0].addEventListener("click", slideRight);
//         buttonWrappers[1].addEventListener("click", slideLeft);




//         // return () => {
//         //     window.removeEventListener("resize", handleResize);
//         //     buttons[0].removeEventListener("click", slideRight);
//         //     buttons[1].removeEventListener("click", slideLeft);
//         // };

//     }, []); // Empty dependency array to run the effect only once

//     return (
//         <div id="our__services">
//             <div className="our__services__title__wrapper">
//               <div className="our__services__title">Our services</div>
//             </div>
//             <div id="our__services__slider">
//               <div id="slider__container">
//                   <div className="spanBtn__wrapper">
//                     <span className="spanBtn"></span>
//                   </div>
//                   <div id="slider">

//                       <div className="slide">
//                         <div className="slide__image__wrapper">
//                           <img src={carouselPsychology} alt="pscychology" className="slide__image"/>
//                         </div>
//                         <div className="slide__title">
//                           Psychology
//                         </div>
//                         <div className="slide__content">
//                           By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment
//                         </div>
//                       </div>

//                       <div className="slide">
//                         <div className="slide__image__wrapper">
//                           <img src={carouselDietology} alt="pscychology" className="slide__image"/>
//                         </div>
//                         <div className="slide__title">
//                           Dietology
//                         </div>
//                         <div className="slide__content">
//                           By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment
//                         </div>
//                       </div>

//                       <div className="slide">
//                         <div className="slide__image__wrapper">
//                           <img src={carouselRadiology} alt="pscychology" className="slide__image"/>
//                         </div>
//                         <div className="slide__title">
//                           Radiology
//                         </div>
//                         <div className="slide__content">
//                           You can find the doctor you are looking for in the relevant specialty, review the calendar and schedule the most suitable time.
//                         </div>
//                       </div>

//                       <div className="slide">
//                         <div className="slide__image__wrapper">
//                           <img src={carouselLabaratory} alt="pscychology" className="slide__image"/>
//                         </div>
//                         <div className="slide__title">
//                           Labarotory tests
//                         </div>
//                         <div className="slide__content">
//                           You can list the institutions and services available on evital for your analysis and examinations, and receive service you selected.
//                         </div>
//                       </div>

//                       <div className="slide">
//                         <div className="slide__image__wrapper">
//                           <img src={carouselPsychology} alt="pscychology" className="slide__image"/>
//                         </div>
//                         <div className="slide__title">
//                           Therapy
//                         </div>
//                         <div className="slide__content">
//                           By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment
//                         </div>
//                       </div>

//                       <div className="slide">
//                         <div className="slide__image__wrapper">
//                           <img src={carouselRadiology} alt="pscychology" className="slide__image"/>
//                         </div>
//                         <div className="slide__title">
//                           Oncology
//                         </div>
//                         <div className="slide__content">
//                           By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment
//                         </div>
//                       </div>
//                   </div>
//                   <div className="spanBtn__wrapper">
//                     <span className="spanBtn"></span>
//                   </div>
//               </div>
//             </div>
//         </div>
//     );
// }

// export default ServiceCategories;

















































// import carouselPsychology from "../../../../assets/images/carousel-psychology.png";
// import carouselDietology from "../../../../assets/images/carousel-dietology.png";
// import carouselRadiology from "../../../../assets/images/carousel-radiology.png";
// import carouselLabaratory from "../../../../assets/images/carousel-labaratory.png";
// import React, { useEffect } from 'react';

// function ServiceCategories() {
//     useEffect(() => {
//         const OurServices = document.getElementById('our__services__slider');
//         const slider = document.getElementById('slider');
//         const slides = document.getElementsByClassName('slide').length;
//         const buttons = document.getElementsByClassName('spanBtn');
//         const buttonWrappers = document.getElementsByClassName('spanBtn__wrapper');

//         let currentPosition = 0;
//         let currentMargin = 0;
//         let slidesPerPage = 0;
//         let slidesCount = slides - slidesPerPage;
//         let OurServicesWidth = OurServices.offsetWidth;
//         let prevKeyActive = false;
//         let nextKeyActive = false;

//         const checkWidth = () => {
//             OurServicesWidth = OurServices.offsetWidth;
//             setParams(OurServicesWidth);
//         };

//         const setParams = (w) => {
//             if (w < 551) {
//                 slidesPerPage = 1;
//             } else if (w < 901) {
//                 slidesPerPage = 2;
//             } else if (w < 1101) {
//                 slidesPerPage = 3;
//             } else {
//                 slidesPerPage = 4;
//             }

//             slidesCount = slides - slidesPerPage;
//             if (currentPosition > slidesCount) {
//                 currentPosition -= slidesPerPage;
//             }
//             currentMargin = - currentPosition * (100 / slidesPerPage);
//             slider.style.marginLeft = currentMargin + '%';
//             if (currentPosition > 0) {
//                 buttons[0].classList.remove('inactive');
//             }
//             if (currentPosition < slidesCount) {
//                 buttons[1].classList.remove('inactive');
//             }
//             if (currentPosition >= slidesCount) {
//                 buttons[1].classList.add('inactive');
//             }
//         };

//         setParams();

//         const slideRight = () => {
//             if (currentPosition != 0) {
//                 slider.style.marginLeft = currentMargin + (100 / slidesPerPage) + '%';
//                 currentMargin += (100 / slidesPerPage);
//                 currentPosition--;
//             }
//             if (currentPosition === 0) {
//                 buttons[0].classList.add('inactive');
//             }
//             if (currentPosition < slidesCount) {
//                 buttons[1].classList.remove('inactive');
//             }
//         };

//         const slideLeft = () => {
//             if (currentPosition != slidesCount) {
//                 slider.style.marginLeft = currentMargin - (100 / slidesPerPage) + '%';
//                 currentMargin -= (100 / slidesPerPage);
//                 currentPosition++;
//             }
//             if (currentPosition == slidesCount) {
//                 buttons[1].classList.add('inactive');
//             }
//             if (currentPosition > 0) {
//                 buttons[0].classList.remove('inactive');
//             }
//         };

//         const handleResize = () => {
//             checkWidth();
//         };

//         window.addEventListener("resize", handleResize);
        
//         buttonWrappers[0].addEventListener("click", slideRight);
//         buttonWrappers[1].addEventListener("click", slideLeft);
//     }, []); 

//     return (
//         <div id="our__services">
//           <div class="wrapper">
//             <i id="left" class="fa-solid fa-angle-left"></i>
//             <ul class="carousel">
//               <li class="card">
//                 <div class="img">
//                   <img src="images/img-1.jpg" alt="img" draggable="false" />
//                 </div>
//                 <h2>Blanche Pearson</h2>
//                 <span>Sales Manager</span>
//               </li>
//               <li class="card">
//                 <div class="img">
//                   <img src="images/img-2.jpg" alt="img" draggable="false" />
//                 </div>
//                 <h2>Joenas Brauers</h2>
//                 <span>Web Developer</span>
//               </li>
//               <li class="card">
//                 <div class="img">
//                   <img src="images/img-3.jpg" alt="img" draggable="false" />
//                 </div>
//                 <h2>Lariach French</h2>
//                 <span>Online Teacher</span>
//               </li>
//               <li class="card">
//                 <div class="img">
//                   <img src="images/img-4.jpg" alt="img" draggable="false" />
//                 </div>
//                 <h2>James Khosravi</h2>
//                 <span>Freelancer</span>
//               </li>
//               <li class="card">
//                 <div class="img">
//                   <img src="images/img-5.jpg" alt="img" draggable="false" />
//                 </div>
//                 <h2>Kristina Zasiadko</h2>
//                 <span>Bank Manager</span>
//               </li>
//               <li class="card">
//                 <div class="img">
//                   <img src="images/img-6.jpg" alt="img" draggable="false" />
//                 </div>
//                 <h2>Donald Horton</h2>
//                 <span>App Designer</span>
//               </li>
//             </ul>
//             <i id="right" class="fa-solid fa-angle-right"></i>
//           </div>
//         </div>
//     );
// }

// export default ServiceCategories;
























// import ReactCardSlider from "react-card-slider-component";
// import sliderPsychology from "../../../../assets/images/slider-psychology.png";
// import sliderDietology from "../../../../assets/images/slider-dietology.png";
// import sliderDoctors from "../../../../assets/images/slider-doctors.png";
// import sliderLabaratory from "../../../../assets/images/slider-labaratory.png";
// import styled from "./ServiceCategories.module.scss";
// import React, { useState, useRef, useEffect } from 'react';

// const ServiceCategories = () => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [isAutoPlay, setIsAutoPlay] = useState(true);
//   const [startX, setStartX] = useState(0);
//   const [startScrollLeft, setStartScrollLeft] = useState(0);
//   const [timeoutId, setTimeoutId] = useState(null);

//   const wrapperRef = useRef(null);
//   const carouselRef = useRef(null);

//   useEffect(() => {
//     const carousel = carouselRef.current;
//     const arrowBtns = document.querySelectorAll(".wrapper i");
  
//     const initializeCarousel = () => {
//       const firstCard = carousel.querySelector(`${styled.card}`);
//       if (!firstCard) return; // Exit if card element is not available yet
  
//       const firstCardWidth = firstCard.offsetWidth;
//       const cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);
  
//       // Insert copies of the last few cards to beginning of carousel for infinite scrolling
//       Array.from(carousel.children).slice(-cardPerView).reverse().forEach(card => {
//         carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
//       });
  
//       // Insert copies of the first few cards to end of carousel for infinite scrolling
//       Array.from(carousel.children).slice(0, cardPerView).forEach(card => {
//         carousel.insertAdjacentHTML("beforeend", card.outerHTML);
//       });
  
//       // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
//       carousel.classList.add("no-transition");
//       carousel.scrollLeft = carousel.offsetWidth;
//       carousel.classList.remove("no-transition");
//     };
  
//     initializeCarousel();
  
//     // Add event listeners for the arrow buttons to scroll the carousel left and right
//     arrowBtns.forEach(btn => {
//       btn.addEventListener("onClick", () => {
//         const firstCardWidth = carousel.querySelector(`${styled.card}`).offsetWidth;
//         carousel.scrollLeft += btn.id === "left" ? -firstCardWidth : firstCardWidth;
//       });
//     });
  
//     // Remove event listeners when component unmounts
//     return () => {
//       arrowBtns.forEach(btn => {
//         btn.removeEventListener("click");
//       });
//     };
//   }, []);
  
//   const dragStart = (e) => {
//     setIsDragging(true);
//     carouselRef.current.classList.add("dragging");
//     setStartX(e.pageX);
//     setStartScrollLeft(carouselRef.current.scrollLeft);
//   };

//   const dragging = (e) => {
//     if (!isDragging) return;
//     carouselRef.current.scrollLeft = startScrollLeft - (e.pageX - startX);
//   };

//   const dragStop = () => {
//     setIsDragging(false);
//     carouselRef.current.classList.remove("dragging");
//   };

//   const infiniteScroll = () => {
//     const carousel = carouselRef.current;
//     if (carousel.scrollLeft === 0) {
//       carousel.classList.add("no-transition");
//       carousel.scrollLeft = carousel.scrollWidth - (2 * carousel.offsetWidth);
//       carousel.classList.remove("no-transition");
//     } else if (Math.ceil(carousel.scrollLeft) === carousel.scrollWidth - carousel.offsetWidth) {
//       carousel.classList.add("no-transition");
//       carousel.scrollLeft = carousel.offsetWidth;
//       carousel.classList.remove("no-transition");
//     }
//     // clearTimeout(timeoutId);
//     // if (!wrapperRef.current.matches(":hover")) autoPlay();
//   };


//   // useEffect(() => {
//   //   const autoPlay = () => {
//   //     const carousel = carouselRef.current;
//   //     if (window.innerWidth < 800 || !isAutoPlay) return;
//   //     setTimeoutId(setTimeout(() => carousel.scrollLeft += carousel.querySelector(`${styled.card}`).offsetWidth, 2500));
      
//   //   };
//   //   autoPlay();

//   //   return () => {
//   //     clearTimeout(timeoutId);
//   //   };
//   // }, [isAutoPlay]);



//   return (
//     <section class="header"><h1>Learn your problem</h1>
//     <div className={styled.wrapper} ref={wrapperRef}>
//       <i id="left" className="fa-solid fa-angle-left"></i>
//       <ul className={styled.carousel} ref={carouselRef} onMouseDown={dragStart} onMouseMove={dragging} onMouseUp={dragStop} onScroll={infiniteScroll}>
//         <li className={styled.card}>
//           <div className={styled.img}><img  src={sliderPsychology} alt="img" draggable="false" /></div>
//           <h2>Blanche Pearson</h2>
//           <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
//         </li>
//         <li className={styled.card}>
//           <div className={styled.img}><img  src={sliderPsychology} alt="img" draggable="false" /></div>
//           <h2>Blanche Pearson</h2>
//           <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
//         </li>
//         <li className={styled.card}>
//           <div className={styled.img}><img  src={sliderPsychology} alt="img" draggable="false" /></div>
//           <h2>Blanche Pearson</h2>
//           <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
//         </li>
//         <li className={styled.card}>
//           <div className={styled.img}><img  src={sliderPsychology} alt="img" draggable="false" /></div>
//           <h2>Blanche Pearson</h2>
//           <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
//         </li>
//         <li className={styled.card}>
//           <div className={styled.img}><img  src={sliderPsychology} alt="img" draggable="false" /></div>
//           <h2>Blanche Pearson</h2>
//           <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
//         </li>
        
       
        
//       </ul>
//       <i id="right" className="fa-solid fa-angle-right"></i>
//     </div>
//     </section>
//   );
// };


// export default ServiceCategories;











































































// import ReactCardSlider from "react-card-slider-component";
// import sliderPsychology from "../../../../assets/images/slider-psychology.png";
// import sliderDietology from "../../../../assets/images/slider-dietology.png";
// import sliderDoctors from "../../../../assets/images/slider-doctors.png";
// import sliderLabaratory from "../../../../assets/images/slider-labaratory.png";
// import styled from "./ServiceCategories.module.scss";
// import React, { useState, useRef, useEffect } from 'react';

// const ServiceCategories = () => {

// const wrapper = document.querySelector(".wrapper");
// const carousel = document.querySelector(".carousel");
// const firstCardWidth = carousel.querySelector(".card").offsetWidth;
// const arrowBtns = document.querySelectorAll(".wrapper i");
// const carouselChildrens = [...carousel.children];

// let isDragging = false, isAutoPlay = true, startX, startScrollLeft, timeoutId;

// // Get the number of cards that can fit in the carousel at once
// let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

// // Insert copies of the last few cards to beginning of carousel for infinite scrolling
// carouselChildrens.slice(-cardPerView).reverse().forEach(card => {
//     carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
// });

// // Insert copies of the first few cards to end of carousel for infinite scrolling
// carouselChildrens.slice(0, cardPerView).forEach(card => {
//     carousel.insertAdjacentHTML("beforeend", card.outerHTML);
// });

// // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
// carousel.classList.add("no-transition");
// carousel.scrollLeft = carousel.offsetWidth;
// carousel.classList.remove("no-transition");

// // Add event listeners for the arrow buttons to scroll the carousel left and right
// arrowBtns.forEach(btn => {
//     btn.addEventListener("click", () => {
//         carousel.scrollLeft += btn.id == "left" ? -firstCardWidth : firstCardWidth;
//     });
// });

// const dragStart = (e) => {
//     isDragging = true;
//     carousel.classList.add("dragging");
//     // Records the initial cursor and scroll position of the carousel
//     startX = e.pageX;
//     startScrollLeft = carousel.scrollLeft;
// }

// const dragging = (e) => {
//     if(!isDragging) return; // if isDragging is false return from here
//     // Updates the scroll position of the carousel based on the cursor movement
//     carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
// }

// const dragStop = () => {
//     isDragging = false;
//     carousel.classList.remove("dragging");
// }

// const infiniteScroll = () => {
//     // If the carousel is at the beginning, scroll to the end
//     if(carousel.scrollLeft === 0) {
//         carousel.classList.add("no-transition");
//         carousel.scrollLeft = carousel.scrollWidth - (2 * carousel.offsetWidth);
//         carousel.classList.remove("no-transition");
//     }
//     // If the carousel is at the end, scroll to the beginning
//     else if(Math.ceil(carousel.scrollLeft) === carousel.scrollWidth - carousel.offsetWidth) {
//         carousel.classList.add("no-transition");
//         carousel.scrollLeft = carousel.offsetWidth;
//         carousel.classList.remove("no-transition");
//     }

//     // Clear existing timeout & start autoplay if mouse is not hovering over carousel
//     clearTimeout(timeoutId);
//     if(!wrapper.matches(":hover")) autoPlay();
// }

// const autoPlay = () => {
//     if(window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
//     // Autoplay the carousel after every 2500 ms
//     timeoutId = setTimeout(() => carousel.scrollLeft += firstCardWidth, 2500);
// }
// autoPlay();

// carousel.addEventListener("mousedown", dragStart);
// carousel.addEventListener("mousemove", dragging);
// document.addEventListener("mouseup", dragStop);
// carousel.addEventListener("scroll", infiniteScroll);
// wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
// wrapper.addEventListener("mouseleave", autoPlay);

//   return (
//     <div class="wrapper">
//       <i id="left" class="fa-solid fa-angle-left"></i>
//       <ul class="carousel">
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Blanche Pearson</h2>
//           <span>Sales Manager</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Joenas Brauers</h2>
//           <span>Web Developer</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Lariach French</h2>
//           <span>Online Teacher</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>James Khosravi</h2>
//           <span>Freelancer</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Kristina Zasiadko</h2>
//           <span>Bank Manager</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Donald Horton</h2>
//           <span>App Designer</span>
//         </li>
//       </ul>
//       <i id="right" class="fa-solid fa-angle-right"></i>
//     </div>
//   );
// };


// export default ServiceCategories;



























// import { useRef, useEffect } from 'react';
// import sliderPsychology from "../../../../assets/images/slider-psychology.png";

// const ServiceCategories = () => {
//   const carouselRef = useRef(null);
//   const wrapperRef = useRef(null);
//   const timeoutIdRef = useRef(null);

//   useEffect(() => {
//     const carousel = carouselRef.current;
//     const wrapper = wrapperRef.current;

//     if (!carousel || !wrapper) return;

//     const firstCardWidth = carousel.querySelector(".card").offsetWidth;
//     const arrowBtns = wrapper.querySelectorAll("i");
//     const carouselChildrens = [...carousel.children];

//     let isDragging = false;
//     let isAutoPlay = true;
//     let startX, startScrollLeft;

//     // Get the number of cards that can fit in the carousel at once
//     let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

//     // Insert copies of the last few cards to beginning of carousel for infinite scrolling
//     carouselChildrens.slice(-cardPerView).reverse().forEach(card => {
//       carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
//     });

//     // Insert copies of the first few cards to end of carousel for infinite scrolling
//     carouselChildrens.slice(0, cardPerView).forEach(card => {
//       carousel.insertAdjacentHTML("beforeend", card.outerHTML);
//     });

//     // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
//     carousel.classList.add("no-transition");
//     carousel.scrollLeft = carousel.offsetWidth;
//     carousel.classList.remove("no-transition");

//     // Add event listeners for the arrow buttons to scroll the carousel left and right
//     arrowBtns.forEach(btn => {
//       btn.addEventListener("click", () => {
//         carousel.scrollLeft += btn.id === "left" ? -firstCardWidth : firstCardWidth;
//       });
//     });

//     const dragStart = (e) => {
//       isDragging = true;
//       carousel.classList.add("dragging");
//       // Records the initial cursor and scroll position of the carousel
//       startX = e.pageX;
//       startScrollLeft = carousel.scrollLeft;
//     };

//     const dragging = (e) => {
//       if (!isDragging) return; // if isDragging is false return from here
//       // Updates the scroll position of the carousel based on the cursor movement
//       carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
//     };

//     const dragStop = () => {
//       isDragging = false;
//       carousel.classList.remove("dragging");
//     };

//     const infiniteScroll = () => {
//       // If the carousel is at the beginning, scroll to the end
//       if (carousel.scrollLeft === 0) {
//         carousel.classList.add("no-transition");
//         carousel.scrollLeft = carousel.scrollWidth - (2 * carousel.offsetWidth);
//         carousel.classList.remove("no-transition");
//       }
//       // If the carousel is at the end, scroll to the beginning
//       else if (Math.ceil(carousel.scrollLeft) === carousel.scrollWidth - carousel.offsetWidth) {
//         carousel.classList.add("no-transition");
//         carousel.scrollLeft = carousel.offsetWidth;
//         carousel.classList.remove("no-transition");
//       }

//       // Clear existing timeout & start autoplay if mouse is not hovering over carousel
//       clearTimeout(timeoutIdRef.current);
//       if (!wrapper.matches(":hover")) autoPlay();
//     };

//     const autoPlay = () => {
//       if (window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
//       // Autoplay the carousel after every 2500 ms
//       timeoutIdRef.current = setTimeout(() => (carousel.scrollLeft += firstCardWidth), 2500);
//     };
//     autoPlay();

//     carousel.addEventListener("mousedown", dragStart);
//     carousel.addEventListener("mousemove", dragging);
//     document.addEventListener("mouseup", dragStop);
//     carousel.addEventListener("scroll", infiniteScroll);
//     wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutIdRef.current));
//     wrapper.addEventListener("mouseleave", autoPlay);

//     return () => {
//       // Cleanup event listeners
//       carousel.removeEventListener("mousedown", dragStart);
//       carousel.removeEventListener("mousemove", dragging);
//       document.removeEventListener("mouseup", dragStop);
//       carousel.removeEventListener("scroll", infiniteScroll);
//       wrapper.removeEventListener("mouseenter", () => clearTimeout(timeoutIdRef.current));
//       wrapper.removeEventListener("mouseleave", autoPlay);
//     };
//   }, []);

//   return (
//     <div className="wrapper" ref={wrapperRef}>
//       <i id="left" className="fa-solid fa-angle-left"></i>
//       <ul className="carousel" ref={carouselRef}>
//       <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Blanche Pearson</h2>
//           <span>Sales Manager</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Joenas Brauers</h2>
//           <span>Web Developer</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>Lariach French</h2>
//           <span>Online Teacher</span>
//         </li>
//         <li class="card">
//           <div class="img">
//             <img src={sliderPsychology} alt="img" draggable="false" />
//           </div>
//           <h2>James Khosravi</h2>
//           <span>Freelancer</span>
//         </li>
//       </ul>
//       <i id="right" className="fa-solid fa-angle-right"></i>
//     </div>
//   );
// };

// export default ServiceCategories;

import { useRef, useEffect } from 'react';
import sliderPsychology from "../../../../assets/images/carousel-psychology.png";
import sliderDietology from "../../../../assets/images/carousel-dietology.png";
import sliderRadiology from "../../../../assets/images/carousel-radiology.png";
import sliderLabaratory from "../../../../assets/images/carousel-labaratory.png";

const ServiceCategories = () => {
  const carouselRef = useRef(null);
  const wrapperRef = useRef(null);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const wrapper = wrapperRef.current;

    if (!carousel || !wrapper) return;

    const firstCardWidth = carousel.querySelector(".card").offsetWidth;
    const arrowBtns = wrapper.querySelectorAll("i");

    let isDragging = false;
    let isAutoPlay = true;
    let startX, startScrollLeft;

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach(btn => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft += btn.id === "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const infiniteScroll = () => {
      // If the carousel is at the beginning, scroll to the end
      if (carousel.scrollLeft === 0) {
        carousel.scrollLeft = carousel.scrollWidth - (2 * carousel.offsetWidth);
      }
      // If the carousel is at the end, scroll to the beginning
      else if (Math.ceil(carousel.scrollLeft) === carousel.scrollWidth - carousel.offsetWidth) {
        carousel.scrollLeft = carousel.offsetWidth;
      }

      // Clear existing timeout & start autoplay if mouse is not hovering over carousel
      clearTimeout(timeoutIdRef.current);
      // if (!wrapper.matches(":hover")) autoPlay();
    };

    // const autoPlay = () => {
    //   if (window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
    //   // Autoplay the carousel after every 2500 ms
    //   timeoutIdRef.current = setTimeout(() => (carousel.scrollLeft += firstCardWidth), 2500);
    // };
    // autoPlay();

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutIdRef.current));
    // wrapper.addEventListener("mouseleave", autoPlay);

    return () => {
      // Cleanup event listeners
      carousel.removeEventListener("mousedown", dragStart);
      carousel.removeEventListener("mousemove", dragging);
      document.removeEventListener("mouseup", dragStop);
      carousel.removeEventListener("scroll", infiniteScroll);
      wrapper.removeEventListener("mouseenter", () => clearTimeout(timeoutIdRef.current));
      // wrapper.removeEventListener("mouseleave", autoPlay);
    };
  }, []);

  return (
    <div className="our__services">
      <div className="our__services__title__wrapper">
        <div className="our__services__title">Our services</div>
      </div>
      <div className="our__services__content">
        <div className="wrapper" ref={wrapperRef}>
          <i id="left" className="fa-solid fa-angle-left"></i>
          <ul className="carousel" ref={carouselRef}>
            <li className="card">
              <div className="img">
                <img src={sliderPsychology} alt="img" draggable="false" />
              </div>
              <h2>Psychology</h2>
              <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderDietology} alt="img" draggable="false" />
              </div>
              <h2>Dietologist</h2>
              <span>By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment </span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderRadiology} alt="img" draggable="false" />
              </div>
              <h2>Radiology</h2>
              <span>You can find the doctor you are looking for in the relevant specialty, review the calendar and schedule the most suitable time.</span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderLabaratory} alt="img" draggable="false" />
              </div>
              <h2>Labarotory tests</h2>
              <span>You can list the institutions and services available on evital for your analysis and examinations, and receive service you selected.</span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderPsychology} alt="img" draggable="false" />
              </div>
              <h2>Neurology</h2>
              <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderRadiology} alt="img" draggable="false" />
              </div>
              <h2>Therapy</h2>
              <span>By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment </span>
            </li>
            {/* <li className="card">
              <div className="img">
                <img src={sliderPsychology} alt="img" draggable="false" />
              </div>
              <h2>Psychology</h2>
              <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderDietology} alt="img" draggable="false" />
              </div>
              <h2>Dietologist</h2>
              <span>By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment </span>
            </li>
            <li className="card">
              <div className="img">
                <img src={sliderRadiology} alt="img" draggable="false" />
              </div>
              <h2>Radiology</h2>
              <span>You can find the doctor you are looking for in the relevant specialty, review the calendar and schedule the most suitable time.</span>
            </li> */}
          </ul>
          <i id="right" className="fa-solid fa-angle-right"></i>
        </div>
      </div>
    </div>



    // <div className="wrapper" ref={wrapperRef}>
    //   <i id="left" className="fa-solid fa-angle-left"></i>
    //   <ul className="carousel" ref={carouselRef}>
    //     <li className="card">
    //       <div className="img">
    //         <img src={sliderPsychology} alt="img" draggable="false" />
    //       </div>
    //       <h2>Psychology</h2>
    //       <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
    //     </li>
    //     <li className="card">
    //       <div className="img">
    //         <img src={sliderDietology} alt="img" draggable="false" />
    //       </div>
    //       <h2>Dietologist</h2>
    //       <span>By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment </span>
    //     </li>
    //     <li className="card">
    //       <div className="img">
    //         <img src={sliderRadiology} alt="img" draggable="false" />
    //       </div>
    //       <h2>Radiology</h2>
    //       <span>You can find the doctor you are looking for in the relevant specialty, review the calendar and schedule the most suitable time.</span>
    //     </li>
    //     <li className="card">
    //       <div className="img">
    //         <img src={sliderLabaratory} alt="img" draggable="false" />
    //       </div>
    //       <h2>Labarotory tests</h2>
    //       <span>You can list the institutions and services available on evital for your analysis and examinations, and receive service you selected.</span>
    //     </li>
    //     <li className="card">
    //       <div className="img">
    //         <img src={sliderPsychology} alt="img" draggable="false" />
    //       </div>
    //       <h2>Neurology</h2>
    //       <span>By reviewing the calendar of the psychologist you have chosen, you can easily and quickly make an online or face-to-face appointment</span>
    //     </li>
    //     <li className="card">
    //       <div className="img">
    //         <img src={sliderRadiology} alt="img" draggable="false" />
    //       </div>
    //       <h2>Therapy</h2>
    //       <span>By reviewing the calendar of the dietitian you have chosen, you can easily and quickly make an online or face-to-face appointment </span>
    //     </li>
    //   </ul>
    //   <i id="right" className="fa-solid fa-angle-right"></i>
    // </div>
  );
};

export default ServiceCategories;

