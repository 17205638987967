import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/User/Global/Footer/Footer";
import Header from "../../components/User/Global/Header/Header";
import HeaderAuthorizedDoctor from "../../components/User/Global/Header/HeaderAuthorizedDoctor";
import { useEffect } from "react";

export const GlobalDoctorLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      navigate("/HomePage");
    }
  }, []);

  return (
    <>
      {/* <Header /> */}
      <HeaderAuthorizedDoctor />
      <Outlet />
      <Footer />
    </>
  );
};
