import { useLocation, useNavigate } from "react-router-dom";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import HamburgerMenu from "../../../../assets/icons/doczy-hamburger-menu.svg";
import XImg from "../../../../assets/icons/doczy-close.svg";
import HomeMenu from "../../../../assets/icons/doczy-home-menu.svg";
import DoctorsMenu from "../../../../assets/icons/doczy-doctor-menu.svg";
import BlogsMenu from "../../../../assets/icons/doczy-blogs.svg";
import CategoriesMenu from "../../../../assets/images/categories-menu-img.png";
import AboutUsMenu from "../../../../assets/icons/doczy-aboutus-menu.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import messagesIcon from "../../../../assets/icons/messages.svg";
import headerIcon from "../../../../assets/icons/heart.svg";
import bellIcon from "../../../../assets/icons/bell.svg";
import profileIcon from "../../../../assets/icons/profile-circle.svg";
import { tokenContext } from "../../../../Contexts/TokenContext";
interface iconDivProps {
  height: string;
  width: string;
  iconUrl: string;
}
const IconDiv = styled.div<iconDivProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background-image: url(${(props) => props.iconUrl});
  background-size: 100%;
`;

function Header() {
  const { accessToken } = useContext(tokenContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);
  const [isAuthPage, setIsAuthPage] = useState<boolean>(
    pathname === "/Auth/SignUp"
  );
  const [HamburgerMenuElemVisible, setHamburgerMenuElemVisible] =
    useState(false);

  const [hasScrolledToBottom, setHasScrolledToBottom] =
    useState<boolean>(false);

  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);

    document.querySelector(".header__menu__bar")?.classList.remove("active");
  };

  useEffect(() => {
    setPage(pathname);
    setIsAuthPage(pathname === "/Auth/SignUp/Patient");
    // setIsAuthPage(pathname === "/Auth/SignUp/Doctor");
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolledDown = window.scrollY > 0;
      setHasScrolledToBottom(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   // Once scrolled to bottom, immediately apply the box shadow
  //   if (hasScrolledToBottom) {
  //     document.querySelector("homePage_header")?.classList.add("scrolledToBottom");
  //   }
  // }, [hasScrolledToBottom]);

  const toggleHamburgerMenuElem = () => {
    setHamburgerMenuElemVisible(!HamburgerMenuElemVisible); // Toggle filter visibility
  };

  return (
    // <header className={`homePage_header ${isAuthPage ? "auth" : ""}`}>
    <header
      className={`homePage_header ${isAuthPage ? "auth" : ""} ${
        hasScrolledToBottom ? "scrolledToBottom" : ""
      }`}
    >
      <div className="container">
        <div className="header__inner">
          <div className="header__logo">
            <a href="HomePage">Doczy</a>
            {/* <a onClick={() => handleClick("HomePage")}>Doczy</a> */}
          </div>
          <div className="navigation">
            <nav className="nav">
              <div className="nav__link__holder">
                <a
                  className={`nav__link ${
                    page.includes("HomePage") ? "active" : ""
                  }`}
                  onClick={() => handleClick("HomePage")}
                >
                  Home
                </a>
              </div>
              <div className="nav__link__holder">
                <a
                  className={`nav__link  ${
                    page.includes("Doctors") ? "active" : ""
                  }`}
                  // onClick={() => handleClick("Doctors/Service")}
                  onClick={() => handleClick("Doctors")}
                >
                  Doctors
                </a>
              </div>
              <div className="nav__link__holder">
                {/* <a className="nav__link" href="#"> */}
                <a
                  className={`nav__link  ${
                    page.includes("Blogs") ? "active" : ""
                  }`}
                  // onClick={() => handleClick("Doctors/Service")}
                  onClick={() => handleClick("Blogs")}
                >
                  Blogs
                </a>
              </div>
              {/* <div className="nav__link__holder">
                <a className="nav__link" href="#">
                  Categories
                </a>
              </div> */}
              <div className="nav__link__holder">
                {/* <a className="nav__link" href="#"> */}
                <a
                  className={`nav__link  ${
                    page.includes("AboutUs") ? "active" : ""
                  }`}
                  // onClick={() => handleClick("Doctors/Service")}
                  onClick={() => handleClick("AboutUs")}
                >
                  About Us
                </a>
              </div>
            </nav>

            <div className="header__auth">
              <button
                className="login"
                onClick={() => handleClick("Auth/SignIn/Patient")}
              >
                <a>Sign In</a>
              </button>
              <button
                className="register"
                onClick={() => handleClick("Auth/SignUp/Patient")}
              >
                <a>Sign Up</a>
              </button>
            </div>
          </div>
          <div className="hamburger__menu">
            <img src={HamburgerMenu} alt="" onClick={toggleHamburgerMenuElem} />
          </div>
          <div
            className={`header__menu__bar ${
              HamburgerMenuElemVisible ? "active" : ""
            }`}
          >
            <div className="header__menu__close">
              <div className="menu__closingimg__wrapper">
                <img
                  src={XImg}
                  alt=""
                  className="closingimg"
                  onClick={toggleHamburgerMenuElem}
                />
              </div>
            </div>
            <div className="header__menu__auth">
              <button
                className="menu__auth__login"
                onClick={() => handleClick("Auth/SignIn/Patient")}
              >
                <a>Sign In</a>
              </button>
              <button
                className="menu__auth__register"
                onClick={() => handleClick("Auth/SignUp/Patient")}
              >
                <a>Sign Up</a>
              </button>
            </div>
            <nav className="menu__nav">
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("HomePage")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={HomeMenu} alt="" />
                  <span>Home</span>
                </div>
              </a>
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("Doctors")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={DoctorsMenu} alt="" />
                  <span>Doctors</span>
                </div>
              </a>
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("Blogs")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={BlogsMenu} alt="" />
                  <span>Blogs</span>
                </div>
              </a>
              {/* <a className="menu__nav__link__holder" href="#">
                <div className="menu__nav__link__holder__wrapper">
                  <img src={CategoriesMenu} alt="" />
                  <span>Categories</span>
                </div>
              </a> */}
              <a
                className="menu__nav__link__holder"
                onClick={() => handleClick("AboutUs")}
              >
                <div className="menu__nav__link__holder__wrapper">
                  <img src={AboutUsMenu} alt="" />
                  <span>About us</span>
                </div>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;