import styled from "./Service.module.scss";
import doctoeImage from "../../../../../assets/images/DoctorPage_Doctor.png";
import React, { useEffect, useState } from "react";
import {
  doctorInformation,
  scheduleAppointment,
} from "../../../../../models/AppointmentModels";
import { useOutletContext } from "react-router-dom";
import { useService } from "../../../../../hooks";
import { useQuery, useQueryClient } from "react-query";
import LoadingIcon from "../../../../../assets/images/loadingAnimation.gif";
import {
  GetServiceTypeQueryKey,
  GetServicesQueryKey,
} from "../../../../../API/QueryKeys";
import { Box, CircularProgress } from "@mui/material";
import { MainURL, ServiceTypeIconsRoot } from "../../../../../API/URLs";

export const packets = [
  {
    id: "1",
    duration: "Monthly Packet",
    time: "40 min",
    price: "121$",
  },
  {
    id: "2",
    duration: "Monthly Packet",
    time: "40 min",
    price: "122$",
  },
  {
    id: "3",
    duration: "Monthly Packet",
    time: "40 min",
    price: "123$",
  },
  {
    id: "4",
    duration: "Monthly Packet",
    time: "40 min",
    price: "124$",
  },
  {
    id: "5",
    duration: "Monthly Packet",
    time: "40 min",
    price: "125$",
  },
];
export const appointmentTypes = [
  {
    id: "1",
    className: `${styled.online}`,
    content: "Online",
  },
  {
    id: "2",
    className: `${styled.inPerson}`,
    content: "In Person",
  },
];
export const Service = () => {
  const queryClient = useQueryClient();
  const [
    appointment,
    setAppointment,
    serviceTypeData,
    servicesQuery,
    doctorInfo,
    setDoctorInfo,
  ]: [
    scheduleAppointment | null,
    React.Dispatch<React.SetStateAction<scheduleAppointment>>,
    any,
    any,
    doctorInformation,
    React.Dispatch<React.SetStateAction<doctorInformation>>
  ] = useOutletContext();

  useEffect(() => {
    setAppointment((state) => ({
      ...state,
    }));
  }, []);

  return (
    <>
      <section className={styled.doctor}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1 className={styled.header}>Doctor</h1>
          </div>
          <div className={styled.info}>
            <div className={styled.image}>
              <img src={doctorInfo.doctorImage} />
            </div>
            <div className={styled.nameCategory}>
              <h1>
                {doctorInfo?.doctorFirstName} {doctorInfo?.doctorLastName}
              </h1>
              <p>{doctorInfo?.categoryName}</p>
            </div>
          </div>
        </div>
      </section>
      <section className={styled.appointmentType}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1>Select an appointment type</h1>
          </div>
          <div className={styled.variants}>
            {serviceTypeData.data?.data.map((serviceType: any) => {
              return (
                <div
                  className={serviceType.name}
                  style={{
                    background: `${
                      doctorInfo?.appointmentTypeId == serviceType.id
                        ? "#CCE8E8"
                        : ""
                    }`,
                  }}
                  key={serviceType.id}
                  onClick={() => {
                    queryClient.removeQueries([
                      GetServicesQueryKey,
                      appointment?.doctorId,
                    ]);
                    setDoctorInfo((state) => ({
                      ...state,
                      appointmentTypeId: serviceType.id,
                    }));
                  }}
                >
                  {doctorInfo?.appointmentTypeId === serviceType.id ? (
                    <div className={styled.selectedIcon}></div>
                  ) : (
                    <div
                      className={styled.serviceTypeIcon}
                      style={{
                        backgroundImage: `url(${MainURL}/${ServiceTypeIconsRoot}/${serviceType.iconUrl})`,
                      }}
                    ></div>
                  )}

                  <h1>{serviceType.name}</h1>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className={styled.packet}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1>Select a packet</h1>
          </div>
          <div className={styled.variants}>
            <ul>
              {servicesQuery.isLoading ||
              (servicesQuery.data?.data.length !== 0 &&
                appointment?.serviceId === "") ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : servicesQuery.data?.data.length > 0 ? (
                servicesQuery.data?.data.map((packet: any) => {
                  return (
                    <li
                      className={styled.variant}
                      key={packet.id}
                      style={{
                        background: `${
                          appointment?.serviceId === packet.id ? "#CCE8E8" : ""
                        }`,
                        backgroundImage: `${
                          appointment?.serviceId === packet.id
                            ? "url(../../../../../assets/icons/Selected icon.svg)"
                            : ""
                        }`,
                      }}
                      onClick={() =>
                        setAppointment((state) => ({
                          ...state,
                          serviceId: packet.id,
                        }))
                      }
                    >
                      <div className={styled.left}>
                        {appointment?.serviceId === packet.id && (
                          <div className={styled.selectedIcon}></div>
                        )}
                        <h1>{packet.name}</h1>
                      </div>
                      <div className={styled.right}>
                        <p>{packet.duration} min</p>
                        <h1>{packet.price}$</h1>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div
                  _ngcontent-yuw-c146=""
                  className="info-container mt-10 ng-star-inserted"
                >
                  <i
                    _ngcontent-yuw-c146=""
                    className="fa fa-info info-icon"
                  ></i>
                  <p _ngcontent-yuw-c146="" className="info-label">
                    Bu servis üçün hələ aktiv xidmət təyin olunmayıb.{" "}
                  </p>
                </div>
              )}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
