import styled from "./VerifyEmail.module.scss";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styledComponent from "styled-components";
import verifyEmail from "../../../assets/images/verify-email.png";
import { Box, height, width } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const VerifyEmail = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);
  };

  useEffect(() => {
    setPage(pathname);
  }, [pathname]);
    return (
      <div className={styled.verify__email}> 
        <div className={styled.container}>

            <div className={styled.verify__email__wrapper}>

                <div className={styled.verify__email__image__wrapper}>
                  <img src={verifyEmail} alt="" className={styled.verify__email__image}/>
                </div>

                <div className={styled.verify__email__wrapper__heading}>
                    Verify your email
                </div>

                <div className={styled.verify__email__description__wrapper}>
                    <span className={styled.verify__email__description}>We sent a verification link to [user email]. Please click on link and continue sign up process. </span>
                </div>

                <div className={styled.verify__email__doczysupport__wrapper}>
                    <span className={styled.verify__email__doczysupport}>Check your spam folder if you dont see any email from us or contact us at Doczysupport@xxxx.com.</span>
                </div>

                <div className={styled.verify__email__resendlink__wrapper}>
                    <a className={styled.verify__email__resendlink}>Resend the link in XX seconds.</a>
                </div>
                
            </div>
        </div>
      </div>
    );
};