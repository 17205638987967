import { changePassword, signInModel } from "../../models/AuthModels";
import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class AuthService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async signIn(body: object, token: undefined) {
    return await this.post("Authentications/login", body, token);
  }
  async forgotPassword(body: object) {
    return await this.post("Authentications/forgot-password", body, undefined);
  }
  async signOut() {
    return await this.postLogout("Authentications/logout");
  }
  async generateNewAccessToken(refreshToken: string) {
    return await this.post(
      "Users/refresh-token-login",
      refreshToken,
      undefined
    );
  }
  async changePassword(
    changePassword: changePassword,
    token: string | undefined
  ) {
    return await this.post(
      "Authentications/change-password",
      changePassword,
      token
    );
  }
}
