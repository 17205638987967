import * as yup from "yup";

export const SignInDoctorSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(100, "Email cannot be more than 100 characters"),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot be more than 20 characters long"),
});

export const SignInPatientSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(100, "Email cannot be more than 100 characters"),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot be more than 20 characters long"),
});

export const SignUpPatientSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  userName: yup.string().required("Required"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(100, "Email cannot be more than 100 characters"),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot be more than 20 characters long"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const SignUpDoctorSchema = yup.object().shape({
  FirstName: yup.string().required("Required"),
  LastName: yup.string().required("Required"),
  // userName: yup.string().required("Required"),
  // userName: yup.string().required("Required").min(7, "Fincode must be 7 characters long"),
  Email: yup
    .string()
    .email()
    .required("Email is required")
    .max(100, "Email cannot be more than 100 characters"),

  Password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot be more than 20 characters long"),
  // idCardImageUrl: yup
  //     .string()
  //     .required("Password is required"),
  // diplomaImageUrl: yup
  //     .string()
  //     .required("Password is required"),
  // doctorCategoryId: yup.string().required("Required"),
  ConfirmPassword: yup
    .string()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const ForgotPasswordSchema = yup.object().shape({
  emailOrPhoneNumber: yup
    .string()
    .email()
    .required("Email or phone number is required")
    .max(100, "Email or phone number cannot be more than 100 characters"),
});

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot be more than 20 characters long"),
  confirmPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password cannot be more than 20 characters long"),
});

// export const SignUpDoctorSchema = yup.object().shape({
//     name: yup.string().required("Required"),
//     surname: yup.string().required("Required"),
//     gender: yup.string().required("Required"),
//     fincode: yup.number().required("Required"),
//     email: yup.string().email("Invalid email").required("Required"),
//     password: yup.string().required("Required").min(8, "Password is too short"),
//     confirmPassword: yup.string()
//     .oneOf([yup.ref("password")], "Passwords must match")
//     .required("Required"),
// });

export const VerifyIdentitySchema = yup.object().shape({
  UserName: yup
    .string()
    .required("Required")
    .min(7, "Fincode must be 7 characters long"),
  DoctorCategoryId: yup.string().required("Required"),
  IdCardImageUrl: yup.string().required("Required"),
  DiplomaImageUrl: yup.string().required("Required"),
});
