import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class UniversityService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getUniversities(token: string | null) {
    return await this.getAll("Universities", token);
  }
}
