import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class ServicesTypeService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getServicesType() {
    return await this.getAll("ServiceTypes", null);
  }
}
