import { useLocation, useNavigate } from "react-router-dom";
import styled from "./Sidebar.module.scss";
import { useContext, useEffect, useState } from "react";
import { SidebarRoute } from "./NavigationComponents/NavigationComponents";
import { tokenContext } from "../../../../Contexts/TokenContext";
export const Sidebar = () => {
  const {
    setAccessToken,
    setRefreshToken,

    setExpiration,
  } = useContext(tokenContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    setAccessToken("");
    setExpiration("");
    setRefreshToken("");
    navigate("/Auth/SignIn/Doctor");
  };

  return (
    <section className={styled.sidebar}>
      <div className={styled.container}>
        <section className={styled.navigation}>
          <SidebarRoute
            location={pathname}
            route="Dashboard"
            name="Dashboard"
            disable={false}
          />
          <SidebarRoute
            location={pathname}
            route="Appointments"
            name="Appointments"
            disable={false}
          />
          <SidebarRoute
            location={pathname}
            route="Patients"
            disable={false}
            name="Patients"
          />
          <SidebarRoute
            location={pathname}
            route="Reports"
            disable={false}
            name="Reports"
          />
          <SidebarRoute
            location={pathname}
            route="Schedule"
            disable={true}
            name="Schedule"
          />
          <SidebarRoute
            disable={false}
            location={pathname}
            route="Settings/BasicInformation"
            name="Settings"
          />
        </section>
        <section className={styled.logOut} onClick={() => handleLogout()}>
          <div className={styled.main}>
            <div className={styled.icon}></div>
            <h1>Log out</h1>
          </div>
        </section>
      </div>
    </section>
  );
};
