import DoczyMobileImage from "../../../../assets/images/DoczyMobileImage.png";
import DoczyMobileAppStore from "../../../../assets/images/DoczyMobileAppStore.png";
import DoczyMobileGooglePlay from "../../../../assets/images/DoczyMobileGooglePlay.png";
function DoczyMobile() {
  return (
    <section className="doczymobile">
      <div className="container">
        <section className="header">
          <h1>Doczy on mobile</h1>
        </section>
        <section className="content">
          <div className="content-left">
            <div className="image">
              <img src={DoczyMobileImage}></img>
            </div>
          </div>
          <div className="content-right">
            <div className="heading">
              <h1>Download our mobile app from Play Store or App Store</h1>
            </div>
            <div className="info">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="images">
              <img src={DoczyMobileAppStore}></img>
              <img src={DoczyMobileGooglePlay}></img>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default DoczyMobile;
