import axios from "axios";

export class HTTPClient {
  BaseUrl: string;
  constructor(BaseUrl: string) {
    this.BaseUrl = BaseUrl;
  }
  async getAll(endPoint: string, token: string | null) {
    return await axios.get(`${this.BaseUrl}/${endPoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async get(endPoint: string, token: null | string) {
    return await axios.get(`${this.BaseUrl}/${endPoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async getPaginate(
    endPoint: string,
    pageSize: number,
    pageNumber: number,
    token: null | string
  ) {
    return await axios.get(
      `${this.BaseUrl}/${endPoint}/${pageSize}/${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  async getFilter<T>(endPoint: string, body: T, token: null | string) {
    let url = endPoint;
    if (body) {
      url += "?";
      for (const [key, value] of Object.entries(body)) {
        if (value) {
          url += `${key}=${value}&`;
        }
      }
    }

    return await axios.get(`${this.BaseUrl}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async getById(
    endPoint: string,
    id: string | undefined,
    token: string | null
  ) {
    return await axios.get(`${this.BaseUrl}/${endPoint}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async getByIdWithExtraRoute(
    endPoint: string,
    id: string | undefined,
    extra: string | undefined
  ) {
    return await axios.get(`${this.BaseUrl}/${endPoint}/${id}/${extra}`);
  }
  async post<T>(endPoint: string, body: T, token: string | undefined) {
    return await axios.post(`${this.BaseUrl}/${endPoint}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  // async postAuth(endPoint: string, body: object, token: string | undefined) {
  //   return await axios.post(`${this.BaseUrl}/${endPoint}`, body, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }
  async postLogout(endPoint: string) {
    return await axios.post(`${this.BaseUrl}/${endPoint}`);
  }
  async put<T>(
    endPoint: string,
    body: T,
    id: string,
    token: string | undefined
  ) {
    return await axios.put(`${this.BaseUrl}/${endPoint}/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async patch<T>(
    endPoint: string,
    body: T,
    id: undefined,
    token: string | undefined
  ) {
    return await axios.patch(`${this.BaseUrl}/${endPoint}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async delete(endPoint: string, id: string, token: string | undefined) {
    return await axios.delete(`${this.BaseUrl}/${endPoint}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
