import styled from "./Contact.module.scss";
export const Contact = () => {
  return (
    <div className={styled.Contact}>
      <div className={styled.box}>
        <div className={styled.main}>
          <section className={styled.header}>
            <div className={styled.info}>
              <h1>Contact</h1>
            </div>
          </section>
          <section className={styled.information}>
            <ul>
              <li>
                <div className={styled.phoneIcon}></div>
                <p>Phone: +994 99 999 99 99</p>
              </li>
              <li>
                <div className={styled.emailIcon}></div>

                <p>Email: random@random.com</p>
              </li>
              <li>
                <div className={styled.hospitalIcon}></div>
                <p>Hospital Location: Random</p>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};
