import { createContext, useState } from "react";
import {
  signUpDoctorContextModel,
  signUpDoctorModel,
  signUpDoctorModelAll,
} from "../models/AuthModels";
interface ContainerProps {
  children: React.ReactNode;
}
const initialValue: signUpDoctorContextModel = {
  signUpDoctorInfo: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    genderId: "",
    confirmPassword: "",
    userName: "",
    idCardImageUrl: null,
    diplomaImageUrl: null,
    doctorCategoryId: "",
  },
  setSignUpDoctorInfo: () => "",
};

export const SignUpDoctorContext =
  createContext<signUpDoctorContextModel>(initialValue);

export const SignUpDoctorContextProvider = (props: ContainerProps) => {
  const [signUpDoctorInfo, setSignUpDoctorInfo] =
    useState<signUpDoctorModelAll>({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      genderId: "",
      confirmPassword: "",
      userName: "",
      idCardImageUrl: null,
      diplomaImageUrl: null,
      doctorCategoryId: "",
    });

  return (
    <SignUpDoctorContext.Provider
      value={{ signUpDoctorInfo, setSignUpDoctorInfo }}
    >
      {props.children}
    </SignUpDoctorContext.Provider>
  );
};
