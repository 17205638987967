import { HTTPClient } from "../HTTPClient";
import { MainURLApi } from "../URLs";

export class genderService extends HTTPClient {
  constructor() {
    super(MainURLApi);
  }
  async getAllGenders() {
    return await this.getAll("Genders", null);
  }
}