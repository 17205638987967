// import WelcomeSectionDoctor from "../../../../assets/images/Slider.png";

// import WelcomeSectionImg1 from "../../../../assets/images/welcome-slider1.jpg";
// import WelcomeSectionImg2 from "../../../../assets/images/welcome-slider2.jpg";
// import WelcomeSectionImg3 from "../../../../assets/images/welcome-slider3.jpg";

// import "../../../../App.css";
// import { useEffect, useState } from "react";
// const sliderContent = [
//   {
//     heading: "1L1orem ipsum dolor sit amet consectetur, adipisicing elit.",
//     image: WelcomeSectionImg3,
//     info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//     eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
//   },
//   {
//     image: WelcomeSectionImg2,
//   },
//   {
//     image: WelcomeSectionImg1,
//   },
//   // {
//   //   image: WelcomeSectionDoctor,
//   // },
// ];
// function Welcome() {
//   const [imageWidth, setImageWidth] = useState(460);
//   const [sliderOrder, setSliderOrder] = useState(0);
//   const [next, setNext] = useState(true);
//   useEffect(() => {
//     function setInternalToSlider() {
//       return setInterval(() => {
//         if (sliderOrder === sliderContent.length - 1) {
//           setNext(false);
//           setSliderOrder((state) => state - 1);
//         } else if (sliderOrder === 0 && !next) {
//           setNext(true);
//           setSliderOrder((state) => state + 1);
//         } else {
//           if (next) {
//             setSliderOrder((state) => state + 1);
//           } else {
//             setSliderOrder((state) => state - 1);
//           }
//         }
//       }, 5000);
//     }

//     const intervalId = setInternalToSlider();

//     return () => clearInterval(intervalId);
//   }, [sliderOrder]);
//   useEffect(() => {
//     if (window.innerWidth < 805) {
//       setImageWidth(700);
//     }
//   }, []);
//   return (
//     <section className="welcome">
//       {/* {sliderContent.map((slider, index) => {
//         return (
//           <div
//             className="container"
//             style={{
//               transform: `translate(${(index - sliderOrder) * 1200}px)`,
//             }}
//           >
//             <div className="container-left">
//               <section className="heading">
//                 <h1>{slider.heading}</h1>
//               </section>
//               <section className="image">
//                 <div className="box">
//                   <img src={slider.image}></img>
//                 </div>
//               </section>
//               <section className="info">
//                 <p>{slider.info}</p>
//               </section>
//               <section className="button">
//                 <button>Book a consultaion</button>
//               </section>
//             </div>
//             <div className="container-right">
//               <section className="image">
//                 <div className="box">
//                   <img src={slider.image}></img>
//                 </div>
//               </section>
//             </div>
//           </div>
//         );
//       })} */}

//       <div className="container">
//         <div className="container-left">
//           <section className="heading">
//             <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h1>
//           </section>
//           {/* {sliderContent.map((slider, index) => {
//             return (
//               <section
//                 className="image"
//                 style={{
//                   transform: `translateX(${
//                     (index - sliderOrder) * imageWidth
//                   }px)`,
//                 }}
//               >
//                 <div className="box">
//                   <img src={slider.image}></img>
//                 </div>
//               </section>
//             );
//           })} */}
//           <section className="info">
//             <p>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua.
//             </p>
//           </section>
//           <section className="button">
//             <button>Book a consultaion</button>
//           </section>
//         </div>
//         <div className="container-right">
//           {sliderContent.map((slider, index) => {
//             return (
//               <section
//                 className="image"
//                 style={{
//                   transform: `translateX(${(index - sliderOrder) * 460}px)`,
//                 }}
//               >
//                 <div className="box">
//                   <img src={slider.image}></img>
//                 </div>
//               </section>
//             );
//           })}
//           <div className="pagination__elements__wrapper">
//             <div className="pagination__element active"></div>
//             <div className="pagination__element"></div>
//             <div className="pagination__element"></div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Welcome;

import WelcomeSectionDoctor from "../../../../assets/images/Slider.png";

import WelcomeSectionImg1 from "../../../../assets/images/welcome-slider1.jpg";
import WelcomeSectionImg2 from "../../../../assets/images/welcome-slider2.jpg";
import WelcomeSectionImg3 from "../../../../assets/images/welcome-slider3.jpg";

import "../../../../App.css";
import { useEffect, useState } from "react";
const sliderContent = [
  {
    heading: "1L1orem ipsum dolor sit amet consectetur, adipisicing elit.",
    image: WelcomeSectionImg3,
    info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    image: WelcomeSectionImg2,
  },
  {
    image: WelcomeSectionImg1,
  },
];
function Welcome() {
  const [imageWidth, setImageWidth] = useState(536);
  const [sliderOrder, setSliderOrder] = useState(0);
  const [next, setNext] = useState(true);
  useEffect(() => {
    function setInternalToSlider() {
      return setInterval(() => {
        if (sliderOrder === sliderContent.length - 1) {
          setNext(false);
          setSliderOrder((state) => state - 1);
        } else if (sliderOrder === 0 && !next) {
          setNext(true);
          setSliderOrder((state) => state + 1);
        } else {
          if (next) {
            setSliderOrder((state) => state + 1);
          } else {
            setSliderOrder((state) => state - 1);
          }
        }
      }, 5000);
    }

    const intervalId = setInternalToSlider();

    return () => clearInterval(intervalId);
  }, [sliderOrder]);
  useEffect(() => {
    if (window.innerWidth < 805) {
      setImageWidth(700);
    }
  }, []);
  return (
    <section className="welcome">
      <div className="container">
        <div className="container-left">
          <section className="heading">
            <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h1>
          </section>
          <section className="info">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </section>
          <section className="button">
            <button>Book a consultaion</button>
          </section>
        </div>
        <div className="container-right">
          {sliderContent.map((slider, index) => {
            return (
              <section
                className="image"
                style={{
                  // transform: `translateX(${(index - sliderOrder) * 460}px)`,
                  transform: `translateX(${(index - sliderOrder) * 536}px)`,
                }}
              >
                <div className="box">
                  <img src={slider.image}></img>
                </div>
              </section>
            );
          })}
          <div className="pagination__elements__wrapper">
            {sliderContent.map((slider, index) => {
              return (
                <div
                  onClick={() => setSliderOrder(index)}
                  className={`pagination__element ${
                    index === sliderOrder ? "active" : ""
                  }`}
                ></div>
              );
            })}
            {/* <div className="pagination__element"></div>
            <div className="pagination__element"></div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
