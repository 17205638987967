import { useQuery } from "react-query";
import { useService } from "../../../../hooks";
import { EarningStatisticsReportsPage } from "./EarningStatistics/EarningStatistics";
import { FeedbackReportsPage } from "./Feedback/Feedback";
import { PatientStatisticsReportsPage } from "./PatientStatistics/PatientStatistics";
import styled from "./Reports.module.scss";
import { getDoctorDashboardReportsQueryKey } from "../../../../API/QueryKeys";
import { useContext, useState } from "react";
import { tokenContext } from "../../../../Contexts/TokenContext";
import { getDoctorReportsDTO } from "../../../../models/DoctorModels";
export const Reports = () => {
  const { accessToken } = useContext(tokenContext);
  const { doctorServices } = useService();
  const [reportsData, setReportsData] = useState<getDoctorReportsDTO | null>(
    null
  );
  const reportsQuery = useQuery(
    [getDoctorDashboardReportsQueryKey],
    () => doctorServices.getDoctorDashboardReports(accessToken),
    { onSuccess: (response) => setReportsData(response.data) }
  );

  if (reportsQuery.isLoading || !reportsData) {
    return <h1>Is loading</h1>;
  }
  console.log(reportsData);

  return (
    <section className={styled.reports}>
      <div className={styled.firstLine}>
        <PatientStatisticsReportsPage reportsDTO={reportsData} />
        <FeedbackReportsPage reporstDTO={reportsData} />
      </div>
      <div>
        <EarningStatisticsReportsPage />
      </div>
    </section>
  );
};
