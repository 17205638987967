import styled from "./SignUp.module.scss";
import { SignUpNavigation } from "../../../components/Auth/SignUp/SignUpNavigation/SignUpNavigation";
import SignUpPatientForm from "../../../components/Auth/SignUp/SignUpForms/SignUpPatientForm";
import { Outlet } from "react-router-dom";

export const SignUp = () => {
  return (
    <div className={styled.sign__up}>
      <div className={styled.container}>
        <div className={styled.signup__wrapper}>
          <div className={styled.signup__wrapper__heading}>
            Sign Up to{" "}
            <span className={styled.wrapper__heading__span}>Doczy</span>
          </div>
          <SignUpNavigation />
          <Outlet />
          {/* <SignUpPatientForm /> */}
        </div>
      </div>
    </div>
  );
};
