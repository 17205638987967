import { useQuery } from "react-query";
import { useService } from "../../../../hooks";
import styled from "./Dashboard.module.scss";
import { FirstColumnDoctorPage } from "./FirstColumn/FirstColumn";

import { SecondColumnDoctorPage } from "./SecondColumn/SecondColumn";
import {
  getDoctorAppointment,
  getDoctorDashboardReportsQueryKey,
  getUserInfoQueryKey,
} from "../../../../API/QueryKeys";
import { useContext, useEffect, useState } from "react";
import { tokenContext } from "../../../../Contexts/TokenContext";
import { getDoctorReportsDTO } from "../../../../models/DoctorModels";

export const Dashboard = () => {
  const { accessToken } = useContext(tokenContext);
  const { appointmentServices, userServices, doctorServices } = useService();
  const [reportsData, setReportsData] = useState<getDoctorReportsDTO | null>(
    null
  );
  const appointmentQuery = useQuery([getDoctorAppointment], () =>
    appointmentServices.getDoctorAppointments(accessToken ?? null)
  );
  const reportsQuery = useQuery([getDoctorDashboardReportsQueryKey], () =>
    doctorServices.getDoctorDashboardReports(accessToken ?? null)
  );
  const userQuery = useQuery([getUserInfoQueryKey, accessToken], () =>
    userServices.getUserInfo(accessToken ?? null)
  );
  useEffect(() => {
    if (reportsQuery.isSuccess) {
      setReportsData(reportsQuery.data?.data);
    }
  }, [reportsQuery.isSuccess]);
  if (appointmentQuery.isLoading || reportsQuery.isLoading) {
    return <h1>Loading</h1>;
  }
  return (
    <section className={styled.dashboard}>
      <div className={styled.container}>
        <FirstColumnDoctorPage
          UserInfoData={userQuery.data?.data}
          reportsData={reportsData}
        />
        <SecondColumnDoctorPage appoinmentData={appointmentQuery.data?.data} />
      </div>
    </section>
  );
};
