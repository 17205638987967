import styled from "./SignUpNavigation.module.scss";
import AuthPatient from "../../../../assets/images/auth-user-img.png";
import AuthDoctor from "../../../../assets/images/auth-hospital-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export const SignUpNavigation = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState<string>(pathname);
  const isPatientSignUp = pathname.toLowerCase().endsWith("/auth/signup/patient");
  const isDoctorSignUp = pathname.toLowerCase().endsWith("/auth/signup/doctor");
  const navigate = useNavigate();
  const handleClick = (page: string) => {
    navigate(page);
    setPage(page);
  };
  return (
    <div className={styled.signup__tab__wrapper}>
      <div
        className={`${styled.patient__tab} ${
          isPatientSignUp ? styled.active : ""
        }`}
        onClick={() => {
          handleClick("Patient");
          setPage("/Auth/SignUp/Patient");
        }}
      >
        <img src={AuthPatient} alt="" />
        <div className={styled.patient__tab__content}>As a patient</div>
      </div>
      <div
        className={`${styled.doctor__tab} ${
          isDoctorSignUp ? styled.active : ""
        }`}
        onClick={() => {
          handleClick("Doctor");
          setPage("/Auth/SignUp/Doctor");
        }}
      >
        <img src={AuthDoctor} alt="" />
        <div className={styled.doctor__tab__content}>As a doctor</div>
      </div>
    </div>
  );
};