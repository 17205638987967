export const GetDoctorsQueryKey = "GetDoctors";
export const GetDoctorAboutQueryKey = "GetDoctorAbout";
export const GetDoctorResumeQueryKey = "GetDoctorResume";
export const GetDoctorDetailQueryKey = "GetDoctorDetail";
export const GetDoctorFavouriteQueryKey = "GetDoctorFavouriteQueryKey";
export const GetDoctorServiceQueryKey = "GetDoctorServiceQueryKey";
export const GetServicesQueryKey = "GetServicesDoctor";
export const GetServiceTypeQueryKey = "GetServiceTypeDoctor";
export const getDoctorAvailabilityByDoctorIdDateQueryKey =
  "getDoctorAvailabilityByDoctorIdDateQueryKey";
export const getUserInfoQueryKey = "GetUserInfoQueryKey";
export const getDoctorAppointment = "getDoctorAppointment";
export const getPatientAppointment = "getPatientAppointment";
export const getDoctorAvailabilityForDoctorPageQueryKey =
  "getDoctorAvailabilityForDoctorPage";
export const getAllLanguages = "getAllLanguages";
export const getAllDoctorCategoriesQueryKey = "getAllDoctorCategories";
export const getAllHospitalsQueryKey = "GetAllHospitalsQueryKey";
export const getAllCountriesQueryKey = "getAllCountriesQueryKey";
export const getAllCitiesQueryKey = "getAllCitiesQueryKey";
export const getGendersQueryKey = "getGendersQueryKey";
export const getDoctorDashboardReportsQueryKey =
  "getDoctorDashboardReportsQueryKey";
export const getUniversitiesQueryKey = "getUniversitiesQueryKey";
export const getUniversityDegreeQueryKey = "getUniversityDegreeQueryKey";
export const getAllFieldsOfStudyQueryKey = "getAllFieldsOfStudyQueryKey";
