import styled from "./ServiceDoctorDetails.module.scss";
import doctoeImage from "../../../../../assets/images/DoctorPage_Doctor.png";
import React, { useContext } from "react";
import { scheduleAppointment } from "../../../../../models/AppointmentModels";
import { useOutletContext } from "react-router-dom";
import { ScheduleAnAppointmentContext } from "../../../../../Contexts/ScheduleAppointment-Context";
import { DoctorProfileImagesRoot, MainURL } from "../../../../../API/URLs";

export const packets = [
  {
    id: "1",
    duration: "Monthly Packet",
    time: "40 min",
    price: "121$",
  },
  {
    id: "2",
    duration: "Monthly Packet",
    time: "40 min",
    price: "122$",
  },
  {
    id: "3",
    duration: "Monthly Packet",
    time: "40 min",
    price: "123$",
  },
  {
    id: "4",
    duration: "Monthly Packet",
    time: "40 min",
    price: "124$",
  },
  {
    id: "5",
    duration: "Monthly Packet",
    time: "40 min",
    price: "125$",
  },
];
export const appointmentTypes = [
  {
    id: "1",
    className: `${styled.online}`,
    content: "Online",
  },
  {
    id: "2",
    className: `${styled.inPerson}`,
    content: "In Person",
  },
];
export const ServiceDoctorDetails = () => {
  const { appointment, setAppointment, doctorInfo, setDoctorInfo } = useContext(
    ScheduleAnAppointmentContext
  );

  return (
    <>
      <section className={styled.doctor}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1 className={styled.header}>Doctor</h1>
          </div>
          <div className={styled.info}>
            <div className={styled.image}>
              <img
                src={`${MainURL}/${DoctorProfileImagesRoot}/${doctorInfo.doctorImage}`}
              />
            </div>
            <div className={styled.nameCategory}>
              <h1>
                {doctorInfo?.doctorFirstName} {doctorInfo?.doctorLastName}
              </h1>
              <p>{doctorInfo.categoryName}</p>
            </div>
          </div>
        </div>
      </section>
      <section className={styled.appointmentType}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1>Select an appointment type</h1>
          </div>
          <div className={styled.variants}>
            {appointmentTypes.map((appointmentType) => {
              return (
                <div
                  className={appointmentType.className}
                  style={{
                    background: `${
                      doctorInfo?.appointmentTypeId == appointmentType.id
                        ? "#CCE8E8"
                        : ""
                    }`,
                  }}
                  key={appointmentType.id}
                  onClick={() =>
                    setAppointment((state) => ({
                      ...state,
                      appointmentTypeId: appointmentType.id,
                    }))
                  }
                >
                  <h1>{appointmentType.content}</h1>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className={styled.packet}>
        <div className={styled.content}>
          <div className={styled.header}>
            <h1>Select a packet</h1>
          </div>
          <div className={styled.variants}>
            <ul>
              {packets.map((packet) => {
                return (
                  <li
                    className={styled.variant}
                    key={packet.id}
                    style={{
                      background: `${
                        appointment?.serviceId == packet.id ? "#CCE8E8" : ""
                      }`,
                    }}
                    onClick={() =>
                      setAppointment((state) => ({
                        ...state,
                        packetId: packet.id,
                      }))
                    }
                  >
                    <div className={styled.left}>
                      <h1>{packet.duration}</h1>
                    </div>
                    <div className={styled.right}>
                      <p>{packet.time}</p>
                      <h1>{packet.price}</h1>
                    </div>
                  </li>
                );
              })}

              {/* <li className={styled.variant}>
                <div className={styled.left}>
                  <h1>Monthly Packet</h1>
                </div>
                <div className={styled.right}>
                  <p>40 min</p>
                  <h1>$125</h1>
                </div>
              </li>
              <li className={styled.variant}>
                <div className={styled.left}>
                  <h1>Monthly Packet</h1>
                </div>
                <div className={styled.right}>
                  <p>40 min</p>
                  <h1>$125</h1>
                </div>
              </li>
              <li className={styled.variant}>
                <div className={styled.left}>
                  <h1>Monthly Packet</h1>
                </div>
                <div className={styled.right}>
                  <p>40 min</p>
                  <h1>$125</h1>
                </div>
              </li>
              <li className={styled.variant}>
                <div className={styled.left}>
                  <h1>Monthly Packet</h1>
                </div>
                <div className={styled.right}>
                  <p>40 min</p>
                  <h1>$125</h1>
                </div>
              </li>
              <li className={styled.variant}>
                <div className={styled.left}>
                  <h1>Monthly Packet</h1>
                </div>
                <div className={styled.right}>
                  <p>40 min</p>
                  <h1>$125</h1>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
